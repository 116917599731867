<div mat-dialog-content>
    <div class="modal-header">
        <h5 class="mb-0 font-weight-bold"><i class="fa fa-times-circle"></i> {{ 'title.notification' | translate }} </h5>
    </div>

    <div class="position-relative text-center">
        <div class="row">
            <div class="col-12">
                <p>
                    {{ 'header.user_disconnection.text1' | translate }}<br>
                    {{ 'header.user_disconnection.text2' | translate }}
                </p>
                <p>
                    {{ 'header.user_disconnection.text3' | translate }}<br>
                    {{ 'header.user_disconnection.text4' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Animator logout -->
