import {Component, Input} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ChatService} from '../../../../services/chat/chat.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../../models/user/user';
import {Room} from '../../../../models/room/room';
import {ActionComponent} from '../../animation/action/action.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-animation-list',
    templateUrl: './animation-list.component.html',
    styleUrls: ['./animation-list.component.css']
})
export class AnimationListComponent {

    static canLoadMoreRooms = true;

    @Input() subscribed: boolean;

    private chatServer;

    private hasInfiniteScroll = true;

    private loadingUsers = false;

    private loadingMoreRooms = false;

    private getMoreUsersLoading = false;

    private moreUsersTime = null;

    private newUserLoaded = null;

    private dayFormat = null;

    hoveringAbove: number;

    constructor(
        private datePipe: DatePipe,
        private chatService: ChatService,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {
        this.chatServer = chatService;
        this.dayFormat = new Intl.DateTimeFormat('en-US', {weekday: 'long'});
        if (!this.getUsers() || !this.getUsers().length) {
            this.chatService.incrementSearchDay();
        }
    }

    get additionalSearchDay(): number {
        return this.chatService.additionalSearchDay + 1;
    }

    openModal(zmUserProfile: User, display: string) {
        this.dialog.open(ActionComponent, {
            data: {zmUserProfile, display},
            minWidth: '800px'
        });
    }

    getUsers(): User[] {
        return this.subscribed ? this.chatService.getSubscribedUsers() : this.chatService.getFreemiumUsers();
    }

    getFakeRooms(): Room[] {
        return this.chatService.getFakeRooms();
    }

    isSelectedUser(user: User): boolean {
        return this.chatService.getCurrentAnimationUser() &&
            user.getId() === this.chatService.getCurrentAnimationUser().getId();
    }

    isSelectedRoom(room: Room): boolean {
        return this.chatService.getCurrentRoom() &&
            this.chatService.getCurrentRoom().getId() === room.getId();
    }

    selectUser(user: User) {
        if (this.chatService.getCurrentAnimationUser() && this.chatService.getCurrentAnimationUser().getId() === user.getId()) {
            return;
        }
        AnimationListComponent.canLoadMoreRooms = true;

        this.chatService.setCurrentAnimationUser(user);
    }

    selectFakeRoom(room: Room) {
        this.chatService.selectRoom(room);
    }

    getMoreRooms() {
        if (!this.loadingMoreRooms && this.chatService.getCurrentAnimationUser() && this.chatService.canReceiveNotifications()) {
            this.loadingMoreRooms = true;
            const userId = this.chatService.getCurrentAnimationUser().getId();

            this.chatService.loadMoreFakeRooms((canLoadMoreRooms: boolean) => {
                this.loadingMoreRooms = false;
                if (!this.chatService.getCurrentAnimationUser() || userId !== this.chatService.getCurrentAnimationUser().getId()) {
                    AnimationListComponent.canLoadMoreRooms = true;
                } else {
                    AnimationListComponent.canLoadMoreRooms = canLoadMoreRooms;
                }
            });
        }
    }

    canLoadMoreRooms(): boolean {
        return this.canReceiveNotifications() && this.chatService.getCurrentAnimationUser() && AnimationListComponent.canLoadMoreRooms;
    }

    canInfiniteScroll() {
        return this.hasInfiniteScroll;
    }

    isLoadingUsers() {
        return this.loadingUsers;
    }

    showNewUserLoaded() {
        return this.newUserLoaded !== null;
    }

    writeNewRoomNb() {
        if (this.newUserLoaded === 0) {
            return this.translateService.instant('chat.room.no_new_user');
        }

        return this.translateService.instant('chat.room.loaded_user', {
            number: this.newUserLoaded,
            count: this.newUserLoaded
        });
    }

    onScrollUsers() {
        const container = document.querySelector('#sidebar-left .connectes');
        if (!container) {
            return;
        }
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const containerScrollHeight = container.scrollHeight;

        if (scrollTop + containerHeight >= containerScrollHeight) {
            this.getMoreUsers();
        }
    }

    /**
     * check if operator can receive notifications
     */
    canReceiveNotifications() {
        return this.chatService.canReceiveNotifications();
    }

    hasMaxUsers() {
        return (this.getUsers().length === this.chatService.animationConfig.max);
    }

    getMoreUsers() {
        if (!this.canLoadMoreUsers() || !this.chatService.canReceiveNotifications()) {
            return;
        }

        this.loadingUsers = true;

        this.getMoreUsersLoading = true;

        this.moreUsersTime = this.chatService.getTime();

        setTimeout(() => {
            this.chatService.loadMoreAnimation(this.subscribed, (response) => {
                this.hasInfiniteScroll = response.nbUsers > 0;

                this.loadingUsers = false;

                this.newUserLoaded = response.nbUsers;
                setTimeout(() => {
                    this.newUserLoaded = null;
                }, 3500);

                this.getMoreUsersLoading = false;

                if (!this.hasInfiniteScroll) {
                    this.chatService.incrementSearchDay();
                }
            });
        }, 4000);
    }

    getDate(user: User|Room): string {
        if (user.lastMessageTime === null) {
            return '';
        }

        const d = new Date();
        d.setHours(0, 0, 0, 0);

        if (user.lastMessageTime >= d) {
            return this.datePipe.transform(user.lastMessageTime, 'HH:mm');
        }

        d.setDate(d.getDate() - 1);

        if (user.lastMessageTime >= d) {
            return this.translateService.instant('elapsed_time.yesterday');
        }

        d.setDate(d.getDate() - 1);

        if (user.lastMessageTime >= d) {
            return this.translateService.instant('elapsed_time.before_yesterday');
        }

        d.setDate(d.getDate() - 4);

        if (user.lastMessageTime >= d) {
            return this.dayFormat.format(user.lastMessageTime);
        }

        return this.datePipe.transform(user.lastMessageTime, 'dd-MM-yy');
    }

    /**
     * Cut message
     * @param message
     * @param length
     */
    getLastMessageShortcut(message: string, length: number = 30): string {
        let messageShortcut = message;

        if (messageShortcut.length > length) {
            const chars = Array.from(messageShortcut);
            messageShortcut = chars.slice(0, length).join('') + '…';
        }

        messageShortcut = this.chatService.checkEmojiSupport(messageShortcut);

        return messageShortcut;
    }

    isFrustrated(user: User): boolean {
        const frustration = user.getFrustrationRule();

        if (!frustration || frustration.isCoin()) {
            return false;
        }

        return !(
            frustration.getDeferred() &&
            frustration.getDeferred().getTimeout() &&
            frustration.getDeferred().getTimeout() > 0 &&
            frustration.getDeferred().getMessageReceivedLeft() &&
            frustration.getDeferred().getMessageSentLeft()
        );
    }

    private canLoadMoreUsers() {
        // Before anything else we're looking if we don't have too much rooms
        if (this.hasReachedMaxUsers()) {
            return false;
        }

        const now = this.chatService.getTime();

        if (this.moreUsersTime !== null && typeof this.moreUsersTime !== 'undefined') {
            // Don't load more if the loading is already ongoing.
            if (this.getMoreUsersLoading) {
                return false;
            }
            // More than 1 second must have elapsed before loading more.
            if ((now - this.moreUsersTime) <= 1) {
                return false;
            }
        }

        return true;
    }

    /**
     * Read the function name carefully
     * Return false if we haven't reach the max room
     * Return true  if we already have enough room
     * >> Will also calculate the quantity of room we can request with moreConversations
     */
    private hasReachedMaxUsers(): boolean {
        this.chatService.animationUserNeeded = this.chatService.animationConfig.max - this.getUsers().length;

        if (this.chatService.animationUserNeeded > 50) {
            this.chatService.animationUserNeeded = 50;
        }

        return this.chatService.animationUserNeeded === 0;
    }
}
