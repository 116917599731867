import {Deserializable} from '../deserialize/deserializable.model';
import {Segment} from '../segment/segment';

export class Product implements Deserializable {
    id: number;
    name: string;
    logoMembers: string;
    membersUrl: string;
    segment: Segment = null;

    deserialize(input: any): this {

        Object.assign(this, input);

        this.segment = new Segment().deserialize(input.segment);

        return this;
    }

    getProductId() {
        return this.id;
    }

    getProductName() {
        return this.name;
    }

    getLogoMembers() {
        return this.logoMembers;
    }

    getMembersUrl() {
        return this.membersUrl;
    }

    getSegment(): Segment {
        return this.segment;
    }
}
