import {AbstractModeration} from './abstract-moderation';

/**
 * NicknameModeration
 */
export class NicknameModeration extends AbstractModeration {
    /**
     * Nickname
     * @private
     */
    private nickname: string = null;

    /**
     * Moderated nickname
     * @private
     */
    private moderatedNickname: string = null;

    constructor() {
        super(AbstractModeration.MODERATION_NICKNAME);
    }

    setNickname(nickname: string) {
        this.nickname = nickname;
    }

    getNickname(): string {
        return this.nickname;
    }

    setModeratedNickname(moderatedNickname: string) {
        this.moderatedNickname = moderatedNickname;
    }

    getModeratedNickname(): string {
        return this.moderatedNickname;
    }

    getData(): any {
        if (this.moderatedNickname) {
            return {
                nickname: this.moderatedNickname
            };
        }

        return null;
    }

    getReasons(): string[] {
        return [
            'vulgarity',
            'violence',
            'personal_informations',
            'flood',
            'soliciting'
        ];
    }
}
