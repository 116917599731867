/**
 * Photo
 */
export class Photo {
    private id: number = null;

    private albumId: number = null;

    private createdAt: Date = null;

    private rating: number = null;

    private url: string = null;

    private base64: string = null;

    deserialize(data: any): this {
        Object.assign(this, data);

        if (data && data.createdAt) {
            this.createdAt = new Date(data.createdAt);
        }

        return this;
    }

    getId(): number {
        return this.id;
    }

    getUrl(): string {
        return this.url;
    }

    setBase64(base64: string) {
        this.base64 = base64;
    }

    getBase64(): string {
        return this.base64;
    }

    setRating(rating: number) {
        this.rating = rating;
    }

    getRating(): number {
        return this.rating;
    }
}
