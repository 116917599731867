<div class="modal-container">
    <h1 mat-dialog-title class="title-center">
        {{ 'title.title_crop_photo' | translate }}
        <span class="fa fa-times-circle modal-close" mat-dialog-close></span>
    </h1>
    <div mat-dialog-content>
        <image-cropper #imageCropperComponent
                       [imageBase64]="getBase64()"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="aspectRatio"
                       format="png"
                       (imageCropped)="imageCropped($event)"
        ></image-cropper>
    </div>
    <div class="rotate-buttons">
        <button class="btn btn-primary" (click)="rotateCounterClockwise()">
            <i class="fas fa-undo"></i>
        </button>
        <button class="btn btn-primary" (click)="rotateClockwise()">
            <i class="fas fa-redo"></i>
        </button>
    </div>
    <mat-dialog-actions class="action-button">
        <button class="btn btn-success" (click)="crop()" cdkFocusRegionstart>
            <i class="fas fa-check"></i>
            {{ 'button.crop' | translate }}
        </button>
        <button class="btn btn-danger" mat-dialog-close>
            <i class="fas fa-times"></i>
            {{ 'modal.cancel' | translate }}
        </button>
    </mat-dialog-actions>
</div>
