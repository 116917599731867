import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DropdownSelectOptionInterface} from './dropdown-select-option-interface';

@Component({
    selector: 'app-dropdown-select',
    templateUrl: './dropdown-select.component.html',
    styleUrls: ['./dropdown-select.component.css']
})
export class DropdownSelectComponent implements OnInit {

    private static uniqueId = 1;

    /**
     * Options
     */
    @Input() options: DropdownSelectOptionInterface[] = [];

    @Output() selectedValuesChange = new EventEmitter<any[]>();

    @ViewChild('container', {static: false}) container: ElementRef;

    /**
     * Unique Id
     */
    uniqueId;

    /**
     * Open ?
     */
    opened = false;

    constructor() {
        this.uniqueId = DropdownSelectComponent.uniqueId++;
    }

    ngOnInit() {
    }

    check() {
        const selectedValues = this.options
            .filter((option: DropdownSelectOptionInterface) => option.checked)
            .map((option: DropdownSelectOptionInterface) => option.value);

        this.selectedValuesChange.emit(selectedValues);
    }

    /**
     * Close the select when click is outside
     * @param event
     */
    @HostListener('body:click', ['$event'])
    onBodyClick(event) {
        let element = event.target;
        while (element) {
            if (element === this.container.nativeElement) {
                return;
            }

            element = element.parentElement;
        }

        this.opened = false;
    }
}
