import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Operator} from '../../../../../../models/operator/operator.model';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ProductGroupService} from '../../../../../../services/product-group/product-group.service';
import {ProductGroup} from '../../../../../../models/product/product-group';
import {ModalService} from '../../../../../../services/modal/modal.service';

@Component({
    selector: 'app-operator-product',
    templateUrl: './operator-product.component.html',
    styleUrls: ['./operator-product.component.css']
})
export class OperatorProductComponent implements OnInit, OnChanges {

    /**
     * Operator
     */
    @Input() operator: Operator = null;

    /**
     * Operator changed
     */
    @Output() operatorChange = new EventEmitter<Operator>();

    /**
     * Product groups
     */
    productGroups: ProductGroup[] = [];

    /**
     * Loading
     */
    loading = false;

    form: FormGroup = null;

    constructor(
        private fb: FormBuilder,
        private productGroupService: ProductGroupService,
        private modalService: ModalService
    ) {
        this.form = this.fb.group({
            productGroups: this.fb.array([])
        });
    }

    ngOnInit() {
        this.loading = true;
        this.productGroupService.getProductGroups().subscribe((productGroups: ProductGroup[]) => {
            this.productGroups = productGroups;

            for (const productGroup of this.productGroups) {
                const formProductGroups = this.form.get('productGroups') as FormArray;
                formProductGroups.push(this.fb.control(''));
            }

            this.reloadForm();

            this.loading = false;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.operator) {
            this.reloadForm();
        }
    }

    reloadForm() {
        const values: any = {};

        if (this.operator) {
            // Product group
            values.productGroups = [];
            for (const i in this.productGroups) {
                if (this.productGroups[i]) {
                    const enabled: boolean = this.operator.getProductGroups().includes(this.productGroups[i].getId());
                    values.productGroups.push(enabled);
                }
            }
        }
        this.form.patchValue(values);
    }

    submit() {
        if (this.form.invalid) {
            this.modalService.openOk('invalid_form_title', 'invalid_form_message', ModalService.ICON_ERROR).subscribe();
            return;
        }

        const values = this.form.getRawValue();

        const newProductGroups = [];

        for (const i in values.productGroups) {
            if (values.productGroups.hasOwnProperty(i) && values.productGroups[i]) {
                newProductGroups.push(this.productGroups[i].getId());
            }
        }

        this.operator.setProductGroups(newProductGroups);

        this.operatorChange.emit(this.operator);
    }
}
