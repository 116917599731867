import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, Type, ViewChild} from '@angular/core';
import {ModerationService} from '../../../../services/moderation/moderation.service';
import {ModerationDirective} from '../../../../helpers/directive/moderation.directive';
import {DynamicModerationTypeComponent} from './dynamic-moderation-type.component';
import {AbstractModeration} from '../../../../models/moderation/abstract-moderation';
import {PresentationComponent} from './presentation/presentation.component';
import {NicknameComponent} from './nickname/nickname.component';
import {AlbumPhotoComponent} from './album-photo/album-photo.component';
import {IdealPresentationComponent} from './ideal-presentation/ideal-presentation.component';
import {ReportComponent} from './report/report.component';
import {ProfilePhotoComponent} from './profile-photo/profile-photo.component';
import {CoverPhotoComponent} from './cover-photo/cover-photo.component';

@Component({
    selector: 'app-moderation-type',
    templateUrl: './moderation-type.component.html',
    styleUrls: ['./moderation-type.component.css']
})
export class ModerationTypeComponent implements OnInit {

    private moderationTypeComponent: DynamicModerationTypeComponent = null;

    @Input() moderation: AbstractModeration;
    @Output() loadingChanged = new EventEmitter<boolean>();
    @ViewChild(ModerationDirective, {static: true}) appModerationType: ModerationDirective;

    constructor(
        private moderationService: ModerationService,
        private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        this.loadComponent(this.moderation);
    }

    /**
     * Dynamic load target component
     * @param moderation
     */
    loadComponent(moderation: AbstractModeration) {
        const moderationTypeComponent: Type<DynamicModerationTypeComponent> = this.getModerationComponentByType(moderation.getType());

        if (moderationTypeComponent) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(moderationTypeComponent);

            const viewContainerRef = this.appModerationType.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent<DynamicModerationTypeComponent>(componentFactory);
            this.moderationTypeComponent = componentRef.instance;
            this.moderationTypeComponent.setModeration(moderation);
            this.moderationTypeComponent.setLoadingChanged(this.loadingChanged);
        }
    }

    /**
     * Display the right component following the moderation type
     * @param type of the moderation (string)
     */
    getModerationComponentByType(type: string): Type<DynamicModerationTypeComponent> {
        switch (type) {
            case AbstractModeration.MODERATION_PRESENTATION:
                return PresentationComponent;
            case AbstractModeration.MODERATION_IDEAL_PRESENTATION:
                return IdealPresentationComponent;
            case AbstractModeration.MODERATION_NICKNAME:
                return NicknameComponent;
            case AbstractModeration.MODERATION_PROFILE_PHOTO:
                return ProfilePhotoComponent;
            case AbstractModeration.MODERATION_ALBUM_PHOTO:
                return AlbumPhotoComponent;
            case AbstractModeration.MODERATION_COVER_PHOTO:
                return CoverPhotoComponent;
            case AbstractModeration.MODERATION_REPORT:
                return ReportComponent;
        }

        return null;
    }

    accept(): string[] {
        return this.moderationTypeComponent.accept();
    }

    reject(action: string, reasons: string[]): string[] {
        return this.moderationTypeComponent.reject(action, reasons);
    }

    getRejectButtons(): object[] {
        return this.moderationTypeComponent.getRejectButtons();
    }
}
