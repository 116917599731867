import { Observable } from 'rxjs';
import { Operator } from '../../models/operator/operator.model';
import { SocketService } from '../socket/socket.service';
import { Socket } from 'ngx-socket-io';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io/src/socket-io.service";
import * as i2 from "../socket/socket.service";
export class OperatorService {
    /**
     * @param socketService
     */
    constructor(socket, socketService) {
        this.socket = socket;
        this.socketService = socketService;
        this.connectedOperators = [];
        this.initSocket();
    }
    /**
     * Init all socket event
     */
    initSocket() {
        this.socket.on('operators', (operators) => this.onOperators(operators));
    }
    /**
     * Receive the online operator list
     * @param operators
     * @private
     */
    onOperators(operators) {
        this.connectedOperators = operators.map(o => new Operator().deserialize(o));
    }
    /**
     * Get connected operator list
     */
    getConnectedOperators() {
        return this.connectedOperators;
    }
    /**
     * Is operator online ?
     * @param operator
     */
    isOnline(operator) {
        return this.connectedOperators.findIndex((o) => o.getId() === operator.getId()) !== -1;
    }
    /**
     * Get operator list
     */
    getOperators(filter) {
        return new Observable((observer) => {
            this.socketService.sendApiRequest('get_operators', filter).subscribe((response) => {
                if (response.success) {
                    observer.next(response.data.operators.map((operator) => new Operator().deserialize(operator)));
                }
                else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
    /**
     * Get operator by id
     * @param id
     */
    getOperatorById(id) {
        return new Observable((observer) => {
            this.getOperators({ id }).subscribe((operators) => {
                observer.next(operators.length ? operators[0] : null);
            }, (error) => observer.error(error));
        });
    }
    /**
     * Update operator
     * @param operator
     */
    updateOperator(operator) {
        return new Observable((observer) => {
            this.socketService.sendApiRequest('update_operator', { operator }).subscribe((response) => {
                if (response.success) {
                    observer.next(response.data.id);
                }
                else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
    /**
     * Delete operator
     * @param id
     */
    deleteOperator(id) {
        return new Observable((observer) => {
            this.socketService.sendApiRequest('delete_operator', { id }).subscribe((response) => {
                if (response.success) {
                    observer.next();
                }
                else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
    /**
     * Supported languages
     */
    getLanguages() {
        return ['fr', 'es', 'it', 'pt', 'pt-br', 'de', 'en', 'en-us'];
    }
    /**
     * Check if username is available
     * @param operatorId
     * @param username
     */
    isUsernameAvailable(operatorId, username) {
        return new Observable((observer) => {
            this.socketService.sendApiRequest('check_operator_username', {
                id: operatorId,
                username
            }).subscribe((response) => {
                if (response.success) {
                    observer.next(response.data.available);
                }
                else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
}
OperatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperatorService_Factory() { return new OperatorService(i0.ɵɵinject(i1.WrappedSocket), i0.ɵɵinject(i2.SocketService)); }, token: OperatorService, providedIn: "root" });
