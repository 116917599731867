import {Deserializable} from '../deserialize/deserializable.model';
import {User} from '../user/user';
import {Room} from '../room/room';

export class Message implements Deserializable {
    id: number;
    me: boolean;
    date: Date;
    message: string;
    room: Room;
    user: User;
    recipient: User;
    ghosted = false;
    operatorUsername: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    setId(id) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    getRoom() {
        return this.room;
    }

    setRoom(room: Room) {
        this.room = room;
    }

    getMessageId() {
        return this.id;
    }

    isMe() {
        return this.me;
    }

    getDate() {
        return this.date;
    }

    setDate(timeToObject) {
        this.date = new Date(timeToObject);
    }

    getMessage() {
        return this.message;
    }

    getUser(): User {
        return this.user;
    }

    setUser(user: User) {
        this.user = user;
    }

    getRecipient(): User {
        return this.recipient;
    }

    setRecipient(user: User) {
        this.recipient = user;
    }

    setMessage(message) {
        this.message = message;
    }

    setMe(me) {
        this.me = me;
    }

    isGhosted(): boolean {
        return this.ghosted;
    }

    getOperatorUsername(): string {
        return this.operatorUsername;
    }
}
