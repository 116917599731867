/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crop-photo.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i5 from "ngx-image-cropper";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./crop-photo.component";
var styles_CropPhotoComponent = [i0.styles];
var RenderType_CropPhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropPhotoComponent, data: {} });
export { RenderType_CropPhotoComponent as RenderType_CropPhotoComponent };
export function View_CropPhotoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageCropperComponent: 0 }), i1.ɵqud(671088640, 2, { buttonCrop: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "h1", [["class", "title-center mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(4, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "fa fa-times-circle modal-close"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "image-cropper", [["format", "png"]], [[4, "text-align", null]], [[null, "imageCropped"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 12).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i4.View_ImageCropperComponent_0, i4.RenderType_ImageCropperComponent)), i1.ɵdid(12, 573440, [[1, 4], ["imageCropperComponent", 4]], 0, i5.ImageCropperComponent, [i6.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageBase64: [0, "imageBase64"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"] }, { imageCropped: "imageCropped" }), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "rotate-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateCounterClockwise() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-undo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateClockwise() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-redo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 10, "mat-dialog-actions", [["class", "action-button mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(19, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["cdkFocusRegionstart", ""], ["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.crop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, null, 4, "button", [["class", "btn btn-danger"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).dialogRef.close(i1.ɵnov(_v, 25).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(27, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_4 = ""; _ck(_v, 8, 0, currVal_4); var currVal_6 = _co.getBase64(); var currVal_7 = "png"; var currVal_8 = true; var currVal_9 = _co.aspectRatio; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_13 = ""; _ck(_v, 25, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).id; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("title.title_crop_photo")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 8).type; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 12).alignImage; _ck(_v, 11, 0, currVal_5); var currVal_10 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("button.crop")); _ck(_v, 22, 0, currVal_10); var currVal_11 = (i1.ɵnov(_v, 25).ariaLabel || null); var currVal_12 = i1.ɵnov(_v, 25).type; _ck(_v, 24, 0, currVal_11, currVal_12); var currVal_14 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("modal.cancel")); _ck(_v, 27, 0, currVal_14); }); }
export function View_CropPhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-crop-photo", [], null, null, null, View_CropPhotoComponent_0, RenderType_CropPhotoComponent)), i1.ɵdid(1, 114688, null, 0, i7.CropPhotoComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CropPhotoComponentNgFactory = i1.ɵccf("app-crop-photo", i7.CropPhotoComponent, View_CropPhotoComponent_Host_0, {}, {}, []);
export { CropPhotoComponentNgFactory as CropPhotoComponentNgFactory };
