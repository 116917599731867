import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class ActionService {
    constructor() {
    }
    alertUser(targetUser) {
        this.UserObservable = targetUser;
    }
    getCurrentAlertUser() {
        return new Observable(observer => {
            observer.next(this.UserObservable);
        });
    }
}
ActionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActionService_Factory() { return new ActionService(); }, token: ActionService, providedIn: "root" });
