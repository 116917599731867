/**
 * Deferred frustration rules.
 */
export class Deferred {

    connectionTimeLeft: number;
    messageReceivedLeft = 0;
    messageSentLeft = 0;

    deserialize(data: any): this {
        Object.assign(this, data);

        return this;
    }

    getTimeout(): number {
        return this.connectionTimeLeft - new Date().getTime();
    }

    getMessageReceivedLeft(): number {
        return this.messageReceivedLeft;
    }

    getMessageSentLeft(): number {
        return this.messageSentLeft;
    }
}
