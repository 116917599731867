import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Operator} from '../../../../../../models/operator/operator.model';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {ModalService} from '../../../../../../services/modal/modal.service';
import {OperatorService} from '../../../../../../services/operator/operator.service';
import {Observable, of} from 'rxjs';
import {delay, map, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UserManagementService} from '../../../../../../services/user/user-management.service';

@Component({
    selector: 'app-operator-account',
    templateUrl: './operator-account.component.html',
    styleUrls: ['./operator-account.component.css']
})
export class OperatorAccountComponent implements OnInit, OnChanges {

    /**
     * Operator
     */
    @Input() operator: Operator = null;

    /**
     * Managers
     */
    @Input() managers: Operator[] = [];

    /**
     * Operator changed
     */
    @Output() operatorChange = new EventEmitter<Operator>();

    submitted = false;

    form: FormGroup = null;

    constructor(
        private modalService: ModalService,
        private operatorService: OperatorService,
        private fb: FormBuilder,
        private translationService: TranslateService,
        private userManagementService: UserManagementService
    ) {
        this.form = this.fb.group({
            lastname: this.fb.control('', [Validators.required]),
            firstname: this.fb.control('', [Validators.required]),
            gender: this.fb.control('', [Validators.required]),
            birthdate: this.fb.control('', [Validators.required]),
            username: this.fb.control('', [Validators.required], [this.usernameValidator()]),
            password: this.fb.control('', []),
            email: this.fb.control('', [Validators.required, Validators.email]),
            skype: this.fb.control('', []),
            telegram: this.fb.control('', []),
            phone: this.fb.control('', []),
            status: this.fb.control('', [Validators.required]),
            rights: this.fb.group({
                animator: this.fb.control('', []),
                moderator: this.fb.control('', []),
                manager: this.fb.control('', []),
                admin: this.fb.control('', [])
            }),
            languages: this.fb.array([]),
            managerId: this.fb.control('', [])
        });

        const langArray = this.form.get('languages') as FormArray;
        for (const language of this.operatorService.getLanguages()) {
            langArray.push(this.fb.control(''));
        }
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.operator) {
            let values: any = {};

            if (this.operator) {
                if (!this.operator.getId()) {
                    // New operator : password is required
                    this.form.controls.password.setValidators([Validators.required]);
                }

                values = Object.assign({}, this.operator);

                // Languages
                values.languages = [];
                const languages = this.operatorService.getLanguages();
                for (const i in languages) {
                    if (languages[i]) {
                        const enabled: boolean = this.operator.getLanguages().includes(languages[i]);
                        values.languages.push(enabled);
                    }
                }
            }

            this.form.patchValue(values);
        }
    }

    submit() {
        this.submitted = true;

        if (this.form.invalid) {
            this.modalService.openOk(
                this.translationService.instant('modal.invalid_form_title'),
                this.translationService.instant('modal.invalid_form_message'),
                ModalService.ICON_ERROR
            ).subscribe();
            return;
        }

        const values = this.form.getRawValue();
        const languages = this.operatorService.getLanguages();

        const newLanguages = [];
        for (const i in values.languages) {
            if (values.languages.hasOwnProperty(i) && values.languages[i]) {
                newLanguages.push(languages[i]);
            }
        }

        /**
         * Force manager id
         */
        if (this.isManager() && !this.isAdmin()) {
            values.managerId = this.userManagementService.getCurrentUser().getId();

            values.rights.admin = false;
            values.rights.manager = false;
        }

        this.operator.setLastname(values.lastname);
        this.operator.setFirstname(values.firstname);
        this.operator.setGender(values.gender);
        this.operator.setBirthdate(values.birthdate);
        this.operator.setUsername(values.username);
        this.operator.setPassword(values.password);
        this.operator.setEmail(values.email);
        this.operator.setSkype(values.skype);
        this.operator.setTelegram(values.telegram);
        this.operator.setPhone(values.phone);
        this.operator.setStatus(values.status);
        this.operator.setRights(values.rights);
        this.operator.setLanguages(newLanguages);
        this.operator.setManagerId(values.managerId);

        this.operatorChange.emit(this.operator);
    }

    getManagers(): Operator[] {
        return this.managers.filter((operator: Operator) => !this.operator || this.operator.getId() !== operator.getId());
    }

    getLanguages(): string[] {
        return this.operatorService.getLanguages();
    }

    usernameValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return of(control.value).pipe(delay(500), switchMap((username) => {
                return new Observable<ValidationErrors | null>((observer) => {
                    /**
                     * Check if username exist
                     */
                    return this.operatorService.isUsernameAvailable(
                        this.operator.getId(),
                        control.value
                    ).subscribe(available => {
                        if (available) {
                            observer.next(null);
                        } else {
                            observer.next({usernameExists: true});
                        }
                        observer.complete();
                    });
                });
            }));
        };
    }

    isManager(): boolean {
        return this.userManagementService.isManager();
    }

    isAdmin(): boolean {
        return this.userManagementService.isAdmin();
    }
}
