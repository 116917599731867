import { MatDialog } from '@angular/material';
import { ModalComponent } from '../../components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
/**
 * Class to manage all modals
 */
export class ModalService {
    constructor(matDialog, translationService) {
        this.matDialog = matDialog;
        this.translationService = translationService;
    }
    message(message) {
        return confirm(message);
    }
    /**
     * Open Modalbox :
     * @param title
     * @param message
     * @param options
     * @param parameters
     */
    open(title, message, options, parameters) {
        return new Observable((observer) => {
            const data = {
                title,
                message,
                parameters,
                buttons: [],
                icon: null
            };
            if (options.buttons) {
                data.buttons = options.buttons;
            }
            if (options.icon) {
                data.icon = options.icon;
            }
            const config = {
                disableClose: true,
                data
            };
            if (options.height) {
                config.height = options.height + 'px';
            }
            if (options.width) {
                config.width = options.width + 'px';
            }
            config.panelClass = 'modal-dialog';
            config.minWidth = '400px';
            const modalDialog = this.matDialog.open(ModalComponent, config);
            modalDialog.afterClosed().subscribe(result => {
                observer.next(result);
            });
        });
    }
    openOk(title, message, icon = null) {
        return this.open(title, message, {
            buttons: [
                this.getTranslatedButton(ModalService.BUTTON_OK)
            ],
            icon
        }, null);
    }
    openOkCancel(title, message, icon = null) {
        return this.open(title, message, {
            buttons: [
                this.getTranslatedButton(ModalService.BUTTON_OK),
                this.getTranslatedButton(ModalService.BUTTON_CANCEL)
            ],
            icon
        }, null);
    }
    openError(error) {
        const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
        return this.open(this.translationService.instant('modal.error.title'), this.translationService.instant('modal.error.message') + '\n\n' + errorMessage, {
            buttons: [
                this.getTranslatedButton(ModalService.BUTTON_OK)
            ],
            icon: ModalService.ICON_ERROR
        }, null);
    }
    getTranslatedButton(button) {
        return {
            id: button.id,
            title: this.translationService.instant('modal.' + button.title),
            className: button.className,
            icon: button.icon
        };
    }
}
ModalService.BUTTON_OK = {
    id: 'ok',
    title: 'ok',
    className: 'btn btn-success',
    icon: 'fa-check'
};
ModalService.BUTTON_CANCEL = {
    id: 'cancel',
    title: 'cancel',
    className: 'btn btn-danger',
    icon: 'fa-times'
};
ModalService.ICON_ERROR = {
    class: 'fa-times-circle icon-red'
};
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.TranslateService)); }, token: ModalService, providedIn: "root" });
