import {AbstractModeration} from './abstract-moderation';
import {User} from '../user/user';

/**
 * ReportModeration
 */
export class ReportModeration extends AbstractModeration {

    /**
     * From user
     * @private
     */
    private fromUser: User = null;

    /**
     * Message
     * @private
     */
    private message: string = null;

    /**
     * Reason
     * @private
     */
    private reasonId: number = null;

    /**
     * Block reason type
     * @private
     */
    private blockReasonType: number = null;

    constructor() {
        super(AbstractModeration.MODERATION_REPORT);
    }

    setFromUser(fromUser: User) {
        this.fromUser = fromUser;
    }

    getFromUser(): User {
        return this.fromUser;
    }

    setMessage(message: string) {
        this.message = message;
    }

    getMessage(): string {
        return this.message;
    }

    setReasonId(reasonId: number) {
        this.reasonId = reasonId;
    }

    getReasonId(): number {
        return this.reasonId;
    }

    setBlockReasonType(blockReasonType: number) {
        this.blockReasonType = blockReasonType;
    }

    getBlockReasonType(): number {
        return this.blockReasonType;
    }

    getData(): any {
        if (this.blockReasonType) {
            return {
                blockReasonType: this.blockReasonType
            };
        }

        return null;
    }

    getReasons(): string[] {
        return [
            'underage',
            'insult',
            'racist',
            'homophobic',
            'antisemite',
            'inappropriate',
            'grazer',
            'other'
        ];
    }
}
