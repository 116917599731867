import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    UserObservable: any;

    constructor() {
    }

    alertUser(targetUser) {
        this.UserObservable = targetUser;
    }

    getCurrentAlertUser() {
        return new Observable(observer => {
            observer.next(this.UserObservable);
        });
    }
}
