<!-- Header -->
<nav class="navbar navbar-expand-md navbar-dark p-0 pl-1 w-100">
    <a class="navbar-brand" routerLink="/"><img src="assets/images/logo.png" class="logo" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">

        <ul class="navbar-nav">
            <li class="nav-item admin-toggle" *ngIf="isAdmin()">
                <mat-slide-toggle [checked]="isAnimator()"
                                  (change)="operatorAnimatorStatusChange($event.checked)">{{ 'header.animation' | translate }}</mat-slide-toggle>
                <mat-slide-toggle [checked]="isModerator()"
                                  (change)="operatorModeratorStatusChange($event.checked)">{{ 'header.moderation' | translate }}</mat-slide-toggle>
            </li>
            <!--            <li *ngIf="supportPanelIsEnabled" class="nav-item">-->
            <!--                <a routerLink="/" class="nav-link btn btn-light" title="{{ 'header.home' | translate }}" data-toggle="tooltip"-->
            <!--                   data-placement="top" data-original-title="Accueil"><i class="fa fa-home"></i></a>-->
            <!--            </li>-->
            <li *ngIf="isAnimator()" class="nav-item">
                <a routerLink="/animation" class="nav-link btn btn-light" title="{{ 'header.animation' | translate }}"
                   data-toggle="tooltip"
                   data-placement="top" data-original-title="Animation"><i class="fa fa-comment"></i><span
                        *ngIf="counter.animation > 0"
                        class="badge badge-danger wow infinite flash blink_e">{{counter.animation}}</span></a>
            </li>
            <li *ngIf="isModerator()" class="nav-item">
                <a routerLink="/moderation" class="nav-link btn btn-light" title="{{ 'header.moderation' | translate }}"
                   data-toggle="tooltip" data-placement="top" data-original-title="Modération générale"><i
                        class="fa fa-eye"></i>
                    <span
                            *ngIf="getModerationCount()"
                            class="badge badge-danger wow infinite flash blink_e">{{getModerationCount()}}</span>
                </a>
            </li>
            <li *ngIf="isAdmin() || isManager()" class="nav-item">
                <a routerLink="/operator" class="nav-link btn btn-light" title="Gestion des opérateurs"
                   data-toggle="tooltip" data-placement="top" data-original-title="Gestion des opérateurs"><i
                        class="fa fa-user"></i></a>
            </li>
            <li *ngIf="isAdmin() || isManager()" class="nav-item">
                <a routerLink="/dispatch-board" class="nav-link btn btn-light" title="Vision d'ensemble de la répartition des discussions"
                   data-toggle="tooltip" data-placement="top" data-original-title="Vision d'ensemble de la répartition des discussions"><i
                    class="fa fa-chart-pie"></i></a>
            </li>
        </ul>

        <ul class="navbar-nav form-inline ml-auto">
            <li class="nav-item">
                <span class="font-weight-bold">{{ getUsername() | titlecase }}</span>
            </li>

            <!--
            <li class="nav-item">
                <div class="avatar-animateur"><img src="assets/asupprimer/homme.jpg" alt="User name"></div>
            </li>
            -->

            <li class="nav-item">
                <app-online-switch></app-online-switch>
            </li>

            <li class="nav-item lang dropdown">
                <span class="form-inline">
                    <select class="form-control" #selectedLang (change)="useLanguage(selectedLang.value)">
                        <option *ngFor="let language of getTranslateService().getLangs()" [value]="language"
                                [selected]="language === getTranslateService().currentLang">
                            {{ language }}
                        </option>
                    </select>
                </span>

                <!--                <button (click)="useLanguage('fr')">fr</button>-->
                <!--                <button (click)="useLanguage('es')">es</button>-->

                <!--                <a href="#" class="nav-link flag-lang dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>-->
                <!--                <div class="countries dropdown-menu">-->
                <!--                    <a href="#" (click)="useLanguage('fr')" class="dropdown-item">-->
                <!--                        <span class="flag-icon flag-icon-br">FR</span>-->
                <!--                    </a>-->
                <!--                    <a href="#" (click)="useLanguage('es')" class="dropdown-item">-->
                <!--                        <span class="flag-icon flag-icon-br">ES</span>-->
                <!--                    </a>-->
                <!--                </div>-->
            </li>

            <li class="nav-item">
                <a class="nav-link btn btn-light" [matTooltip]="getOperatorUsernameList()"
                   matTooltipClass="text prewrap">
                    <i class="fa fa-users"></i>
                    <span class="badge badge-success">{{ countOperators() }}</span>
                </a>
            </li>

            <!--            <li class="nav-item" data-original-title="Alertes">-->
            <!--                <button (click)="displayAlert()" class="nav-link btn btn-light"><i-->
            <!--                        class="fa fa-bell"></i>-->
            <!--                    <span *ngIf="counter.alert > 0"-->
            <!--                          class="badge badge-danger blink_e">{{counter.alert}}</span>-->
            <!--                </button>-->
            <!--            </li>-->

            <!-- <li class="nav-item">
                <a href="#" class="nav-link btn btn-light" title="Statistiques" data-toggle="tooltip"
                   data-placement="top" data-original-title="Statistiques"><i class="fa fa-pie-chart"></i></a>
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="top" data-original-title="Options">
                <a href="#" data-toggle="modal" data-target="#options" class="nav-link btn btn-light"><i
                        class="fa fa-cog"></i></a>
            </li> -->
            <!--            <li class="nav-item">-->
            <!--                <div class="nav-link btn btn-light"><i class="fa fa-clock-o"></i>&nbsp;-->
            <!--                    <app-timer></app-timer>-->
            <!--                </div>-->
            <!--            </li>-->

            <li class="nav-item" data-toggle="tooltip" data-placement="top" data-original-title="Déconnexion">
                <a href="#" (click)="logout()" data-toggle="modal" data-target="#decoanim"
                   class="nav-link btn btn-light"><i class="fa fa-power-off"></i></a>
            </li>

        </ul>

    </div>
</nav>
<!-- Header -->
