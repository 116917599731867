import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../api/response/api-response';

@Injectable({
    providedIn: 'root'
})
export class StatisticService {

    constructor(private socket: Socket) {
    }

    getRooms(): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('operator_room_statistic', {}, (result: any) => {
                observer.next(new ApiResponse().deserialize(result));
            });
        });
    }
}
