import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {AbstractModeration} from '../../../../models/moderation/abstract-moderation';
import {User} from '../../../../models/user/user';
import {ModerationService} from '../../../../services/moderation/moderation.service';
import {Segment} from '../../../../models/segment/segment';
import {ApiResponse} from '../../../../api/response/api-response';
import {ModalService} from '../../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {MatDialog} from '@angular/material';
import {GhostComponent} from '../modal/ghost/ghost.component';
import {CropPhotoComponent} from '../modal/crop-photo/crop-photo.component';
import {Photo} from '../../../../models/photo/photo';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-moderation-item',
    templateUrl: './moderation-item.component.html',
    styleUrls: ['./moderation-item.component.css'],
    animations: [
        trigger('fade', [
            state('fadeOut', style({
                opacity: 0,
                height: 0,
                transformOrigin: '0 0',
                transform: 'scaleY(0)',
                overflow: 'hidden',
                display: 'none'
            })),
            transition('* => fadeOut', [
                animate('.5s')
            ]),
            transition('void => active', [
                style({
                    opacity: 0,
                    transformOrigin: '0 0',
                    transform: 'scaleY(0)'
                }),
                animate('.5s', style({
                    opacity: 1,
                    transform: 'scaleY(1)'
                }))
            ]),
        ])
    ]
})
export class ModerationItemComponent implements OnInit, OnChanges {

    private static readonly TIMEOUT_ALERT = 30;
    private static nextId = 1;

    @Input() moderation: AbstractModeration = null;
    @Output() deleted = new EventEmitter();
    @ViewChild('reasonParent', {static: false}) reasonParent: ElementRef;
    @ViewChild('moderationTypeComponent', {static: true}) moderationTypeComponent;

    id = null;
    reasonDropdownOpened = false;
    rejectReasons: string[] = [];
    loading = false;
    itemDeleted = false;
    elapsedTime = '';
    isTimeElapsed = false;
    gender: string = null;
    searchedGender: string = null;
    moderating = false;

    constructor(
        private moderationService: ModerationService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private toastr: ToastrService
    ) {
        this.id = ModerationItemComponent.nextId++;
    }

    ngOnInit() {
        this.updateElapsedTime();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.moderation) {
            this.gender = this.getGender().toString();
            this.searchedGender = this.getSearchedGender().toString();
        }
    }

    switchGender(type: string) {
        // todo: handle gay segment when they'll be defined.
        if (this.getSegment().getId() > 6) {
            return;
        }
        if (this.gender !== this.searchedGender) {
            return;
        }
        const opposite = type === 'gender' ? 'searchedGender' : 'gender';
        this[opposite] = this[type] === '1' ? '2' : '1';
    }

    getUser(): User {
        return this.moderation.getUser();
    }

    getSegmentClassName(): string {
        return 'segment-' + this.getSegmentName().toLowerCase();
    }

    getSegmentName(): string {
        return this.getSegment().getName().toUpperCase();
    }

    getLogoUrl(): string {
        return this.getUser().getProduct().getLogoMembers();
    }

    getSegment(): Segment {
        return this.getUser().getProduct().getSegment();
    }

    getRestrictions() {
        return this.moderationService.getRestrictionsBySegment(this.getSegment().getId());
    }

    getGender(): number {
        return this.getUser().getGender();
    }

    getSearchedGender(): number {
        return this.getUser().getSearchedGender();
    }

    getReasons(): string[] {
        return this.moderation.getReasons();
    }

    setReason(reason: string, checked: boolean) {
        const i = this.rejectReasons.indexOf(reason);

        if (checked) {
            if (i < 0) {
                this.rejectReasons.push(reason);
            }
        } else {
            this.rejectReasons.splice(i, 1);
        }
    }

    accept() {
        if (this.moderating) {
            return;
        }

        this.moderating = true;

        const errors = this.moderationTypeComponent.accept();
        if (errors.length) {
            this.modalService.openOk(
                this.translateService.instant('moderation.modal.accept_error.title'),
                this.translateService.instant('moderation.modal.accept_error.' + errors[0]),
                ModalService.ICON_ERROR
            ).subscribe();
            this.moderating = false;
            return;
        }

        this.loading = true;
        this.moderationService.accept(this.moderation).subscribe((response: ApiResponse) => {
            this.loading = false;

            if (!response.success) {
                this.handleError(response.error);
                this.moderating = false;
                return;
            }

            this.delete();
        });
    }

    reject(action: string) {
        if (this.moderating) {
            return;
        }

        this.moderating = true;

        const errors = this.moderationTypeComponent.reject(action, this.rejectReasons);
        if (errors.length) {
            this.modalService.openOk(
                this.translateService.instant('moderation.modal.reject_error.title'),
                this.translateService.instant('moderation.modal.reject_error.' + errors[0]),
                ModalService.ICON_ERROR
            ).subscribe();
            this.moderating = false;
            return;
        }

        if (this.rejectReasons.length === 0) {
            this.modalService.openOk(
                this.translateService.instant('moderation.modal.reject_error.title'),
                this.translateService.instant('moderation.modal.reject_error.reason_mandatory'),
                ModalService.ICON_ERROR
            ).subscribe();
            this.moderating = false;
            return;
        }

        this.loading = true;
        this.moderationService.reject(this.moderation, action, this.rejectReasons).subscribe((response: ApiResponse) => {
            this.loading = false;

            if (!response.success) {
                this.modalService.openError(response.error).subscribe();
                this.moderating = false;
                return;
            }

            this.delete();
        });
    }

    delete() {
        this.itemDeleted = true;
        setTimeout(() => {
            this.deleted.emit();
        }, 500);
    }

    /**
     * Close the select when click is outside
     * @param event
     */
    @HostListener('body:click', ['$event'])
    onBodyClick(event) {
        if (!this.reasonParent) {
            return;
        }

        let element = event.target;
        while (element) {
            if (element === this.reasonParent.nativeElement) {
                return;
            }

            element = element.parentElement;
        }

        this.reasonDropdownOpened = false;
    }

    getReasonsText(): string {
        if (this.rejectReasons.length === 0) {
            return this.translateService.instant('moderation.choose');
        }

        return this.rejectReasons.map((reason: string) => this.translateService.instant('moderation.reason.' + reason)).join(', ');
    }

    updateElapsedTime() {
        const now = Math.floor((new Date()).getTime() / 1000);
        const timeout = Math.floor(this.moderation.getCreatedAt().getTime() / 1000) + this.moderation.getTimeout();
        const elapsedTime = timeout - now;

        this.isTimeElapsed = elapsedTime < ModerationItemComponent.TIMEOUT_ALERT;

        const absElapsedTime = Math.abs(elapsedTime);

        const seconds = (absElapsedTime % 60).toString().padStart(2, '0');
        const minutes = (Math.floor(absElapsedTime / 60) % 60).toString().padStart(2, '0');
        const hours = (Math.floor(absElapsedTime / 3600) % 60).toString().padStart(2, '0');

        this.elapsedTime = (this.isTimeElapsed ? '-' : '') + hours + ':' + minutes + ':' + seconds;
    }

    updateGender() {
        this.loading = true;
        this.moderationService.updateProfileGender(this.moderation, +this.gender, +this.searchedGender)
            .subscribe((response: ApiResponse) => {
                this.loading = false;

                if (!response.success) {
                    this.modalService.openError(response.error).subscribe();
                    return;
                }
            });
    }

    updatePhotoRating(ratingEvent: any) {
        this.loading = true;
        this.moderationService.updatePhotoRating(this.moderation, ratingEvent.rating)
            .subscribe((response: ApiResponse) => {
                this.loading = false;

                if (!response.success) {
                    this.modalService.openError(response.error).subscribe();
                    return;
                }
            });
    }

    impersonate() {
        this.loading = true;
        this.moderationService.getAutologinUrl(this.moderation)
            .subscribe((response: ApiResponse) => {
                this.loading = false;

                if (!response.success) {
                    this.modalService.openError(response.error).subscribe();
                    return;
                }

                window.open(response.data.url, '_blank');
            });
    }

    ghost() {
        if (this.getUser().isGhosted()) {
            /**
             * Unghost
             */
            this.modalService.openOkCancel(
                this.translateService.instant('button.unghost'),
                this.translateService.instant('chat.confirm_unghost')
            ).subscribe((result) => {
                if (result === 'ok') {
                    this.moderationService.unghost(this.moderation);
                }
            });
        } else {
            /**
             * Ghost
             */
            const ghostDialog = this.matDialog.open(GhostComponent, {
                width: '500px',
                panelClass: 'modal-dialog'
            });

            ghostDialog.afterClosed().subscribe((result: any) => {
                if (result && result.reason && result.message) {
                    this.moderationService.ghost(this.moderation, result.reason, result.message);
                }
            });
        }
    }

    cropPhoto() {
        /**
         * Load current photo
         */
        this.loading = true;
        this.moderationService.loadPhoto(this.moderation.getUser().getPhotoId()).subscribe((photo: Photo) => {
            this.loading = false;
            /**
             * Open crop dialog
             */
            const cropPhotoDialog = this.matDialog.open(CropPhotoComponent, {
                width: '500px',
                panelClass: 'modal-dialog',
                data: {
                    photo
                }
            });

            cropPhotoDialog.afterClosed().subscribe((result: any) => {
                if (result && result.photo) {
                    this.loading = true;

                    /**
                     * Save new cropped photo
                     */
                    const newPhoto: Photo = result.photo as Photo;
                    this.moderationService.savePhoto(newPhoto.getId(), newPhoto.getBase64()).subscribe((response: ApiResponse) => {
                        this.loading = false;

                        if (!response.success) {
                            this.modalService.openError(response.error).subscribe();
                            return;
                        }
                    });
                }
            });
        });
    }

    getRejectButtons(): object[] {
        return this.moderationTypeComponent ? this.moderationTypeComponent.getRejectButtons() : [];
    }

    onLoadingChanged(loading: boolean) {
        this.loading = loading;
    }

    hasProfilePhoto(): boolean {
        return !!this.getUser().getPhoto();
    }

    protected handleError(error) {
        if (error && error.data && error.data.message) {
            switch (error.data.message) {
                case 'Invalid data for nickname.':
                    this.toastr.error(this.translateService.instant('modal.error.invalid_nickname'));
                    return;
                case 'This nickname is not available.':
                    this.toastr.error(this.translateService.instant('modal.error.nickname_not_available'));
                    return;
            }
        }
        this.modalService.openError(error).subscribe();
    }
}
