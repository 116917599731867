import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserManagementService {
    /**
     *
     * @param http HttpClient
     */
    constructor(http) {
        this.http = http;
        this.operator = null;
    }
    setModerator(moderator) {
        if (this.operator) {
            this.operator.setModerator(moderator);
        }
    }
    setAnimator(animator) {
        if (this.operator) {
            this.operator.setAnimator(animator);
        }
    }
    /**
     * Check if the current operator have the role moderator
     */
    isModerator() {
        return this.operator && this.operator.isModerator();
    }
    /**
     * Check if the current operator have the role animator
     */
    isAnimator() {
        return this.operator && this.operator.isAnimator();
    }
    /**
     * Manager
     */
    isManager() {
        return this.operator && this.operator.isManager();
    }
    /**
     * Is admin
     */
    isAdmin() {
        return this.operator && this.operator.isAdmin();
    }
    getCurrentUser() {
        return this.operator;
    }
    setCurrentUser(user) {
        this.operator = user;
    }
    /**
     * Generate a new password for this user
     *
     * @param username string
     * @param email string
     */
    forgottenPassword(username, email) {
    }
    /**
     * Logout action from the panel
     */
    logout() {
        this.operator = null;
    }
}
UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
