import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {Room} from '../../models/room/room';
import {User} from '../../models/user/user';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {

    notificationCounter: any = {
        animation: 0,
        alert: 0
    };

    /**
     * get Notification alert
     */
    public getNotification(): any {
        return new Observable(observer => {
            setTimeout(() => {
                observer.next(this.notificationCounter);
            }, 1000);
        });
    }

    /**
     * Add notification
     * @param count Count
     * @param type Type
     */
    add(count, type) {
        switch (type) {
            case 'animation':
                this.notificationCounter.animation += count;
                break;
            case 'alert':
                this.notificationCounter.alert += count;
                break;
        }

        return this.notificationCounter;
    }

    /**
     * Remove one to the right counter
     * @param type of notification
     */
    removeOne(type) {
        switch (type) {
            case 'animation':
                this.notificationCounter.animation--;
                break;
            case 'alert':
                this.notificationCounter.alert--;
                break;
        }
    }

    /**
     * Function to count unread messages for all real rooms and animation users.
     */
    countUnreadMessages(realRooms: Room[], animationUsers: User[]) {
        let unread = 0;

        realRooms.forEach(room => {
            if (room.unreadMessage) {
                unread += room.unreadMessage;
            }
        });

        animationUsers.forEach(user => {
            if (user.getUnreadMessages()) {
                unread += user.getUnreadMessages();
            }
        });

        if (this.notificationCounter.animation !== unread) {
            this.notificationCounter.animation = unread;
        }
    }

    ngOnDestroy() {
        this.notificationCounter.animation = 0;
        this.notificationCounter.alert = 0;
    }
}
