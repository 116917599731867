<div *ngIf="room && displayPanel" class="row" style="padding:0 5px;">
    <div class="col-12 product-header pr-1 d-flex align-items-center justify-content-between">
        <div *ngIf="room.getReal().getProduct()" class="d-inline-block">
            <img [src]="room.getReal().getProduct().getLogoMembers()"/>
            <div [ngClass]="room.getReal().getProduct().getSegment().getName().toLowerCase()" class="d-inline-block segment-name">
                <span class="text-uppercase">{{ room.getReal().getProduct().getSegment().getName() }}</span>
            </div>
        </div>

        <div *ngIf="displayAnimation && room.getOther()" class="mode mx-auto">
            <i class="fas fa-lock"></i> MODE {{ (room.getOther().getFrustrationRule() && room.getOther().getFrustrationRule().isCoin()) ? 'LOVE COIN' : 'SUBSCRIPTION' }}
        </div>

        <div *ngIf="displayAnimation && room.getOther()" class="float-right">
            <div class="input-group-btn">
                <small class="mr-4">
                    <ng-container *ngIf="!room.getOther().getSubscription() || !room.getOther().getSubscription().isSubscribed()">
                        <ng-container *ngIf="room.getOther().getFrustrationRule() && room.getOther().getFrustrationRule().isSubscription() && room.getOther().getFrustrationRule().getDeferred() && !isFrustrated()">
                            <span [matTooltip]="'Temps restant avant blocage'">
                                <i class="far fa-clock"></i> {{ room.getOther().getFrustrationRule().getDeferred().getTimeout() | timer: false }}
                            </span>
                            <span [matTooltip]="'Nombre de messages à envoyer autorisé'" class="position-relative">
                                <i class="fas fa-arrow-up small"></i><i class="fas fa-envelope ml-2"></i> {{ room.getOther().getFrustrationRule().getDeferred().getMessageSentLeft() }}
                            </span>
                            <span [matTooltip]="'Nombre de messages à recevoir autorisé'" class="position-relative">
                                <i class="fas fa-arrow-down small"></i><i class="fas fa-envelope ml-2"></i> {{ room.getOther().getFrustrationRule().getDeferred().getMessageReceivedLeft() }}
                            </span>
                        </ng-container>

                        <span *ngIf="isFrustrated()" class="text-danger"><i class="fas fa-user-lock"></i> Frustrated</span>
                    </ng-container>

                    <span [matTooltip]="'Coins disponibles sur le compte'">
                        <i class="fas fa-coins ml-2"></i> {{ room.getOther().getCoins() }}
                    </span>
                </small>

                <!-- @todo
                <button class="btn btn-dark mr-1" type="button" title="Plus de photos"><i class="fas fa-images"></i></button>
                -->

                <button (click)="actionUser(room.getOther(),'profil', true)"
                        [title]="'button.display' | translate" class="btn btn-dark mr-1">
                    <i class="fas fa-user"></i>
                </button>

                <ng-container *ngIf="displayAnimation && profileDetails && profileDetails.relationship">
                    <button [title]="getRelationshipTitle('reverseRelationship', 'favorite') | translate: {nickname: room.getOther().username}"
                            class="btn btn-dark mr-1 position-relative">
                        <i [class.text-warning]="profileDetails?.reverseRelationship?.favorite" class="fa fa-star"></i>
                        <i [class.text-warning]="profileDetails?.reverseRelationship?.favorite" class="fas fa-arrow-down"></i>
                    </button>

                    <button (click)="socialAction('favorite')"
                            [title]="getRelationshipTitle('relationship', 'favorite') | translate: {nickname: room.getOther().username}"
                            class="btn btn-dark mr-1 position-relative">
                        <i [class.text-warning]="profileDetails?.relationship?.favorite" class="fa fa-star"></i>
                        <i [class.text-warning]="profileDetails?.relationship?.favorite" class="fas fa-arrow-up"></i>
                    </button>

                    <button [title]="getRelationshipTitle('reverseRelationship', 'kissed') | translate: {nickname: room.getOther().username}"
                            class="btn btn-dark mr-1 position-relative">
                        <i [class.text-success]="profileDetails?.reverseRelationship?.kissed" class="fa fa-kiss-wink-heart"></i>
                        <i [class.text-success]="profileDetails?.reverseRelationship?.kissed" class="fa fa-arrow-down"></i>
                    </button>

                    <button (click)="socialAction('kissed')"
                            [title]="getRelationshipTitle('relationship', 'kissed') | translate: {nickname: room.getOther().username}"
                            class="btn btn-dark mr-1 position-relative">
                        <i [class.text-success]="profileDetails?.relationship?.kissed" class="fa fa-kiss-wink-heart"></i>
                        <i [class.text-success]="profileDetails?.relationship?.kissed" class="fa fa-arrow-up"></i>
                    </button>

                    <button (click)="socialAction('friend')"
                            [title]="getRelationshipTitle('relationship', 'friend') | translate: {nickname: room.getOther().username}"
                            class="btn btn-dark">
                        <i [class.text-info]="profileDetails?.relationship?.friend"
                           [class.fa-user-check]="profileDetails?.relationship?.friend"
                           [class.fa-user-plus]="profileDetails?.relationship?.friend === null"
                           [class.fa-user-clock]="profileDetails?.relationship?.friend === 0" class="fa"></i>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="col-12 px-0">
        <div class="row users-block no-gutters">
            <div class="col-6 col-left">
                <div class="row no-gutters">
                    <div class="col-12 title px-0"><span class="font-weight-bold">{{ 'chat.room.you_are' | translate }}</span></div>
                    <div class="col-12 p-2">
                        <app-display-real-user-profile *ngIf="displayModeration" [currentUser]="room.getUser1()" [viewWithUser]="room.getUser2()">
                        </app-display-real-user-profile>

                        <div *ngIf="displayAnimation" class="utilisateur row no-gutters fake">
                            <div class="col-12">
                                <div (click)="actionUser(room.getMe(),'profil')"
                                     [title]="'button.display' | translate"
                                     class="users-img rollover-container">
                                    <img src="{{ room.getMe() | userImage: '78x78' }}"/>
                                    <div class="sex"><i [class]="room.getMe().getGenderClass()"></i>
                                    </div>
                                    <span class="text-white ico-profil"><i
                                        class="fa fa-user"></i></span>
                                </div>

                                <div class="users-detail text-truncate text-uppercase">
                                    <span class="pseudo">{{ room.getMe() | profile: ['username'] }}</span> ({{ room.getMe() | profile: ['gender', 'age'] }})
                                    <br>

                                    <strong>{{ 'localization' | translate }} : </strong>
                                    <span>{{ room.getMe() | profile: ['city', 'country'] }}</span>
                                    <br>

                                    <span *ngIf="room.getMe().birthdate">
                                        <strong>{{ 'born_the' | translate }}
                                            :</strong> {{ room.getMe() | profile: ['birthdate'] }}
                                    </span>
                                    <br>

                                    <strong>{{ 'real_firstname' | translate }} : </strong>
                                    <span *ngIf="room.getMe().getFirstname()">{{ room.getMe() | profile: ['firstname']  }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-right">
                <div class="row no-gutters">
                    <div class="col-12 title px-0"><span class="font-weight-bold">{{ 'chat.room.you_speak' | translate }}</span></div>
                    <div class="col-12 p-2">
                        <app-display-real-user-profile *ngIf="displayModeration" [currentUser]="room.getUser2()" [viewWithUser]="room.getUser1()">
                        </app-display-real-user-profile>

                        <app-display-real-user-profile *ngIf="displayAnimation" [currentUser]="room.getOther()" [viewWithUser]="room.getMe()">
                        </app-display-real-user-profile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center my-5" *ngIf="isLoading()">
    <i class="fa fa-4x fa-spin fa-spinner"></i>
</div>

<div *ngIf="!isLoading()">
    <div *ngIf="!hasMessages()" class="row" style="padding:0 5px;">
        <div class="col-md-12 dialogue">
            <div *ngIf="!displayPanel" class="post-divider mt-2">
                <hr>
                <span>{{ 'chat.room.none' | translate }}</span>
            </div>
            <div *ngIf="displayPanel" class="post-divider mt-2">
                <hr>
                <span>{{ 'chat.room.empty' | translate }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="hasMessages()" class="row" style="padding:0 5px;">

        <!-- Dialogue général -->
        <div [ngClass]="(displayPanel && displayModeration) ? 'col-md-12 dialogue moderation-dialogue' : 'col-md-12 dialogue'"
             (scroll)="onScrollMessages()" #messageList>

            <div class="text-center" *ngIf="isScroll() && canInfiniteScroll()">
                <i class="fa fa-2x fa-spin fa-spinner"></i>
            </div>
            <div *ngIf="!isScroll() && canInfiniteScroll()" class="messages_infinite_scroll">
                <p class="text-center" (click)="moreMessages()"
                   style="cursor: pointer;">{{ 'chat.room.more_messages' | translate }}</p>
            </div>
            <div *ngFor="let message of getMessages() | slice:0:200; let i = index">
                <div *ngIf="displayMessageDate(message)" class="post-divider mt-2">
                    <hr>
                    <span>{{ message.date | date:'le dd/MM/yyyy' }}</span>
                </div>
                <div style="clear:both;"></div>
                <div *ngIf="message.getUser()"
                     [ngClass]="message.isMe() || (!room.getUser1().isFake() && !room.getUser2().isFake() && message.getUser().getId() === room.getUser1().getId()) ? 'left' : 'right'"
                     class="answer">

                    <div *ngIf="message.user"
                         (mouseenter)="displayModerationButtons = message.id"
                         (mouseleave)="displayModerationButtons = 0"
                         class="avatar rollover-container">
                        <img src="{{ message.user | userImage: '64x64' }}"
                             alt="{{message.user.username | titlecase}}">
                        <div *ngIf="displayModerationButtons === message.id"
                             [ngClass]="message.isMe() ? 'deleteOnly' : ''" class="menu">
                            <div *ngIf="!message.isMe()" class="float-left">
                                <button (click)="actionUser(message.user,'alert')"
                                        class="btn btn-primary mr-2 text-white action"
                                        title="{{ 'button.warning' | translate }}">
                                    <i class="fa fa-flag"></i>
                                </button>
                                <span *ngIf="!message.user.isSuspended()">
                                    <button (click)="actionUser(message.user,'suspendre')"
                                            class="btn btn-primary mr-2 text-white action"
                                            title="{{ 'button.stop' | translate }}">
                                        <i class="fa fa-clock"></i>
                                    </button>
                                </span>
                                <span *ngIf="message.user.isSuspended()">
                                    <button (click)="unsuspend(message.user)"
                                            class="btn btn-primary mr-2 text-white action"
                                            title="{{ 'button.unstop' | translate }}">
                                        <i class="fa fa-clock text-danger"></i>
                                    </button>
                                </span>
                                <span *ngIf="!message.user.isBanned()">
                                    <button (click)="actionUser(message.user,'bannir')"
                                            class="btn btn-primary mr-2 text-white action"
                                            title="{{ 'button.bannish' | translate }}">
                                        <i class="fa fa-ban"></i>
                                    </button>
                                </span>
                                <span *ngIf="message.user.isBanned()">
                                    <button (click)="unban(message.user)"
                                            class="btn btn-primary mr-2 text-white action"
                                            title="{{ 'button.unbannish' | translate }}">
                                        <i class="fa fa-ban text-danger"></i>
                                    </button>
                                </span>
                                <button (click)="actionUser(message.user,'profil', true)"
                                        class="btn btn-primary mr-2 text-white action"
                                        title="{{ 'button.display' | translate }}">
                                    <i class="fa fa-user"></i>
                                </button>
                                <span *ngIf="!message.user.isGhosted()">
                                    <button (click)="actionUser(message.user,'ghost')"
                                            class="btn btn-primary mr-2 text-white action"
                                            title="{{ 'button.ghost_user' | translate }}">
                                        <i class="fab fa-snapchat-ghost"></i>
                                    </button>
                                </span>
                                <span *ngIf="message.user.isGhosted()">
                                    <button (click)="unghost(message.user)"
                                            class="btn btn-primary mr-2 action"
                                            title="{{ 'button.unghost' | translate }}">
                                        <i class="fab fa-snapchat-ghost text-danger"></i>
                                    </button>
                                </span>
                            </div>
                            <button (click)="deleteMessage(i)"
                                    class="btn btn-primary text-white action"
                                    title="{{ 'button.delete_message' | translate }}"
                            ><i class="fas fa-trash-alt"></i></button>
                        </div>

                        <div
                                [ngClass]="message.user.isConnected()?'status fa-stack bg-success':'status fa-stack bg-secondary'"></div>

                    </div>
                    <div class="name">
                        <span>{{message.user.username | titlecase}} <i
                                [ngClass]="message.user ? message.user.getGenderClass() : ''"></i></span>
                        <span class="pl-4"></span>
                    </div>
                    <div class="text" [innerHTML]="checkEmojiSupport(message.message)"
                         [ngClass]="{'ghosted':message.ghosted}">
                        <!-- <button (click)="actionUser(message,'censurer')"
                                class="btn btn-primary ml-4 text-white action"
                                title="Censurer le message">
                            <i
                                    class="fa fa-cut"></i></button> -->
                    </div>
                    <app-animation-elapsed-time class="time"
                                                [messageDate]="message.date"></app-animation-elapsed-time>
                    <span class="author"
                          *ngIf="message.isMe() && message.getOperatorUsername()">{{ 'chat.room.written_by' | translate }}
                        <strong>{{message.getOperatorUsername()}}</strong></span>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>


    <div *ngIf="room && displayPanel && isTypingByUser()" class="row" style="padding:0 5px;">
        <div class="col-md-12 typing dialogue">
            <div>
                {{room.getOther().username}} est en train d'écrire<span>.</span><span>.</span><span>.</span>
            </div>
        </div>
    </div>

    <div *ngIf="room && displayPanel && displayAnimation" class="row" style="padding:0 5px;">
        <div class="col-md-12 barre-dialogue">
            <div style="background: #2a2a2e;">
                <div class="row p-2">
                    <div class="col-12">
                        <form [formGroup]="messageForm" (submit)="sendMessage(room.getMe(), room.getOther(), $event)" class="navbar-form">
                            <div class="input-group position-relative">

                                <fa-icon class="fleche-dial" [icon]="['fas', 'caret-right']"></fa-icon>

                                <div #messageInput formControlName="message"
                                     class="form-control form-control-lg mr-1 message-field"
                                     placeholder="Saisir un message"
                                     name="dialogue" autocomplete="off"
                                     contenteditable="true"
                                     (keydown)="shortcut($event)"
                                     (keyup)="onTypingMessage($event, room.getMe())"
                                     (keyup)="textToEmoji($event)"
                                ></div>
                                <div class="input-group-btn">
                                    <button id="roomSmiley" class="smileyPanelTrigger btn btn-light p-3 mr-1"
                                            type="button"
                                            title="Emoticons"
                                            (click)="switchEmojisShow()"
                                    >
                                        <i class="fa fa-smile"></i>
                                    </button>
                                    <button id="submit-message" class="btn btn-primary p-3"
                                            type="submit"
                                            title="{{ 'button.send_message' | translate }}"
                                            (click)="submitMessage($event)"
                                    >
                                        <i class="fa fa-paper-plane text-white" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <div class="smile"
                                     style="position:absolute;z-index:1000;right:10px; bottom:63px;max-width:500px;"
                                     [hidden]="!getEmojiShow()"
                                     (clickOutside)="onClickedOutside($event)"
                                     exclude=".smileyPanelTrigger"
                                >
                                    <div class="card card-body">
                                        <app-chat-emoji-list [messageForm]="messageForm"></app-chat-emoji-list>
                                    </div>
                                </div>


                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Chat room -->
