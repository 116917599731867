import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timer'
})
export class TimerPipe implements PipeTransform {

    transform(timestamp: number, showHours = true): any {

        if (!timestamp || timestamp < 0) {
            return showHours ? '00:00:00' : '00:00';
        }

        const timestampInSecs = Math.trunc(timestamp / 1000);
        let hours: any = Math.trunc(timestampInSecs / 3600);
        if (hours < 10) {
            hours = '0' + hours;
        } else if (hours > 99) {
            hours = '+99';
        }
        let minutes: any = Math.trunc((timestampInSecs % 3600) / 60);
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let seconds: any = timestampInSecs - hours * 3600 - minutes * 60;
        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        if (showHours) {
            return `${hours}:${minutes}:${seconds}`;
        }

        return `${minutes}:${seconds}`;
    }
}
