import {Component} from '@angular/core';
import {UserManagementService} from '../../../../services/user/user-management.service';
import {ChatService} from '../../../../services/chat/chat.service';
import {ActionComponent} from '../../animation/action/action.component';
import {ActionService} from '../../../../services/action/action.service';
import {MatDialog} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {ModalService} from '../../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../../models/user/user';

@Component({
    selector: 'app-chat-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent {

    /**
     * Default select filterStatus
     */
    filterStatus = '';
    filterText = '';

    /**
     * Display the buttons for a given user
     */
    displayModerationButtons = 0;

    private awaitingChatResponse = false;

    /**
     *
     * @param userManagementService UserManagementService
     * @param chatService ChatService
     * @param actionService ActionService
     * @param dialog MatDialog
     * @param toastrService ToastrService
     * @param modalService ModalService
     * @param translateService
     */
    constructor(private userManagementService: UserManagementService,
                private chatService: ChatService,
                private actionService: ActionService,
                public dialog: MatDialog,
                private toastrService: ToastrService,
                private modalService: ModalService,
                private translateService: TranslateService) {
    }

    /**
     * Manage all button actions
     * @param zmUserProfile any
     * @param display any
     */
    actionUser(zmUserProfile, display) {
        this.dialog.open(ActionComponent, {

            data: {
                zmUserProfile,
                display
            },
            minWidth: '800px'
        });

        this.actionService.alertUser(zmUserProfile);
    }

    /**
     * Remove the user from ghost list
     * @param zmUser object
     */
    unghost(zmUser) {
        if (this.modalService.message(this.translateService.instant('chat.confirm_unghost', {user: zmUser.username}))) {
            this.chatService.reactiveUser(zmUser, 'ghosted').subscribe();
        }
    }

    /**
     * Remove the user from banned user list
     * @param zmUser object
     */
    unban(zmUser) {
        if (this.modalService.message(this.translateService.instant('chat.confirm_unbannish', {user: zmUser.username}))) {
            this.chatService.reactiveUser(zmUser, 'banned').subscribe();
        }
    }

    /**
     * Remove the user from the suspended user list
     * @param zmUser object
     */
    unsuspend(zmUser) {
        if (this.modalService.message(this.translateService.instant('chat.confirm_unstop', {user: zmUser.username}))) {
            this.chatService.reactiveUser(zmUser, 'suspended').subscribe();
        }
    }

    getConnectedUsers() {
        return this.chatService.getConnectedUsers();
    }

    getFakeUsers() {
        return this.chatService.getFakeUsers();
    }

    sendMessageToUser(user: User) {
        if (this.awaitingChatResponse) {
            return;
        }

        if (this.chatService.hasAnimationUser(user.getId())) {
            this.actionUser(user, 'message');
        } else {
            this.awaitingChatResponse = true;
            this.chatService.canSendNewMessageToUser(user.getId()).then(response => {
                if (response) {
                    this.actionUser(user, 'message');
                } else {
                    alert('This user is already assigned to a different operator.');
                }
                this.awaitingChatResponse = false;
            });
        }
    }
}
