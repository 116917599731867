import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserDisconnectionComponent} from '../header/user-disconnection/user-disconnection.component';
import {ChatService} from '../../../services/chat/chat.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-online-switch',
    templateUrl: './online-switch.component.html',
    styleUrls: ['./online-switch.component.css']
})
export class OnlineSwitchComponent implements OnInit {

    status = true;

    constructor(
        public dialog: MatDialog,
        private chatService: ChatService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
    }

    getStatusText() {
        return this.status ? this.translateService.instant('header.active') : this.translateService.instant('header.inactive');
    }

    changeStatus() {
        if (!this.status) {
            this.dialog.open(UserDisconnectionComponent, {
                data: {},
                minWidth: '800px',
                minHeight: '500px'
            });
        }

        // Change status
        this.chatService.setNotificationStatus(this.status);
    }
}
