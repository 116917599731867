<!-- Container -->
<div class="container-fluid main-section">
    <div class="btn-toolbar float-right">
        <div class="btn-group">
            <a [routerLink]="['/operator/add']" class="btn btn-primary text-white"><i class="fas fa-user-plus"></i>
                {{ 'form.add_user' | translate }}</a>
        </div>
    </div>
    <h1 class="mt-4 mb-1">{{ 'form.user_list' | translate }}</h1>

    <app-loader [loading]="loading"></app-loader>

    <!-- Tableau utilisateurs -->
    <div class="row">
        <div class="col-12 mb-1 border-top pt-2 mt-2 filtres">
            <div class="form-check form-check-inline position-relative">
                {{ 'form.filter' | translate }} :
            </div>
            <div class="form-check form-check-inline position-relative">
                <select class="form-control custom-select" [(ngModel)]="filterStatus">
                    <option [ngValue]="'active'">{{ 'form.filter_state_activated' | translate }}</option>
                    <option [ngValue]="'inactive'">{{ 'form.filter_state_deactivated' | translate }}</option>
                    <option [ngValue]="null">{{ 'form.filter_all' | translate }}</option>
                </select>
            </div>
            <app-dropdown-select [options]="languageOptions" (selectedValuesChange)="languageOptionsChanged($event)">
                {{ 'form.filter_language' | translate }}
            </app-dropdown-select>
            <app-dropdown-select *ngIf="managerOptions.length" [options]="managerOptions" (selectedValuesChange)="managerOptionsChanged($event)">
                {{ 'form.filter_manager' | translate }}
            </app-dropdown-select>
            <app-dropdown-select [options]="productGroupOptions"
                                 (selectedValuesChange)="productGroupOptionsChanged($event)">{{ 'form.filter_product' | translate }}
            </app-dropdown-select>
        </div>
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-dark table-hover mt-2 table-striped operator-list">
                    <thead>
                    <tr>
                        <!--                        <th scope="col">Photo</th>-->
                        <th scope="col">{{ 'form.firstname' | translate }}</th>
                        <th scope="col">{{ 'form.lastname' | translate }}</th>
                        <th scope="col">{{ 'form.email' | translate }}</th>
                        <th scope="col">{{ 'form.manager' | translate }}</th>
                        <th scope="col">{{ 'form.state' | translate }}</th>
                        <th scope="col">{{ 'form.last_connection' | translate }}</th>
                        <th scope="col" class="text-right">{{ 'form.action' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let operator of getFilteredOperators()">
                        <!--                        <td>-->
                        <!--                            <div class="operator-image">-->
                        <!--                                <img src="http://beweb.projets.payet-consulting.com/panel/images/homme01.jpg" alt="">-->
                        <!--                            </div>-->
                        <!--                        </td>-->
                        <td>{{ operator.getFirstname() }}</td>
                        <td>{{ operator.getLastname() }}</td>
                        <td>{{ operator.getEmail() }}</td>
                        <td>{{ getOperatorNameById(operator.getManagerId()) }}</td>
                        <td>
                            <span *ngIf="operator.isActive()" class="text-success">{{ 'form.state_activated' | translate }}</span>
                            <span *ngIf="operator.isInactive()" class="text-danger">{{ 'form.state_deactivated' | translate }}</span>
                        </td>
                        <td>
                            <span *ngIf="isOnline(operator)" class="text-success">ONLINE</span>
                            <span *ngIf="!isOnline(operator)">{{ operator.getLastConnection() | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                        </td>

                        <td class="text-right">
                            <a [routerLink]="['/operator', operator.getId()]"
                               class="btn btn-primary action text-white mr-2"
                               title="{{ 'action_edit' | translate }}">
                                <i class="fa fa-edit"></i>
                            </a>
                            <a (click)="delete(operator)" class="btn btn-primary action text-white"
                               title="{{ 'action_delete' | translate }}"><i class="fa fa-times-circle"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- Tableau utilisateurs -->

</div>
<!-- Container -->

