import {Component} from '@angular/core';
import {ChatService} from '../../../services/chat/chat.service';
import {UserManagementService} from '../../../services/user/user-management.service';
import {Room} from '../../../models/room/room';
import {User} from '../../../models/user/user';

@Component({
    selector: 'app-animation',
    templateUrl: './animation.component.html',
    styleUrls: ['./animation.component.css']
})
export class AnimationComponent {

    leftTab = this.NOT_SUBSCRIBED;
    rightTab = this.MODERATION;

    constructor(private userManagementService: UserManagementService, private chatService: ChatService) {}

    isSubscriptionTab(): boolean {
        return this.leftTab === this.SUBSCRIBED;
    }

    isModerationTab(): boolean {
        return this.rightTab === this.MODERATION;
    }

    hasAnimationUsers(): boolean {
        const rooms = this.getAnimationUsers();
        return rooms && rooms.length > 0;
    }

    getAnimationUsers(tab: string = null): User[] {
        if (!tab) {
            tab = this.leftTab;
        }

        return tab === this.SUBSCRIBED ? this.chatService.getSubscribedUsers() : this.chatService.getFreemiumUsers();
    }

    hasRealRooms(): boolean {
        const rooms = this.getRealRooms();
        return rooms && this.getRealRooms().length > 0;
    }

    getRealRooms(): Room[] {
        return this.chatService.getRealRooms();
    }

    getCurrentRoom(): Room {
        return this.chatService.getCurrentRoom();
    }

    get SUBSCRIBED(): string {
        return 'subscribed';
    }

    get NOT_SUBSCRIBED(): string {
        return 'not_subscribed';
    }

    get MODERATION(): string {
        return 'moderation';
    }

    get USERS(): string {
        return 'users';
    }
}
