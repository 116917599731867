import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserManagementService} from '../../../services/user/user-management.service';
import {User} from '../../../models/user/user';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.css']
})
export class ForgottenPasswordComponent implements OnInit {

    forgottenPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    user = User;
    response;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userManagementService: UserManagementService
    ) {
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.forgottenPasswordForm.controls;
    }

    ngOnInit() {
        if (this.userManagementService.getCurrentUser()) {
            this.router.navigate(['/']);
            return;
        }

        this.forgottenPasswordForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', Validators.required]
        });
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.forgottenPasswordForm.invalid) {
            return;
        }

        this.loading = true;

        this.userManagementService.forgottenPassword(this.f.username.value, this.f.email.value);
    }
}
