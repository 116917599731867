<!-- Container -->
<div class="container-fluid main-section" style="padding:0 5px;">
    <!-- Moteur de recherche -->
    <div class="row" style="display: none;">
        <div class="col-12">
            <div class="card search">
                <div class="card-body">
                    <form>
                        <label class="h5">{{ 'home.search_member' | translate }}</label>
                        <div class="input-group">
                            <input type="text" class="form-control mr-1" placeholder="Rech{{ 'home.search' | translate }}erche" aria-label="Recherche"
                                   aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <mat-form-field [floatLabel]="'never'">
                                    <mat-select disableOptionCentering placeholder="{{ 'home.site' | translate }}" [multiple]="true">
                                        <mat-option *ngFor="let site of sitesItems" [value]="site.id">{{
                                            site.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="'never'">
                                    <mat-select disableOptionCentering placeholder="{{ 'home.niche' | translate }}" [multiple]="true">
                                        <mat-option *ngFor="let niche of nichesItems" [value]="niche.id">{{
                                            niche.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="'never'">
                                    <mat-select disableOptionCentering placeholder="{{ 'home.language' | translate }}" [multiple]="true">
                                        <mat-option *ngFor="let language of languagesItem" [value]="language.id">{{
                                            language.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="'never'">
                                    <mat-select disableOptionCentering placeholder="{{ 'status' | translate }}" [multiple]="true">
                                        <mat-option *ngFor="let statut of statutsItems" [value]="statut.id">{{
                                            statut.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button class="btn btn-primary text-white" type="button"><i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" id="Check1">
                            <label class="form-check-label" for="Check1">{{ 'login' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" id="Check2">
                            <label class="form-check-label" for="Check2">{{ 'email' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" id="Check3">
                            <label class="form-check-label" for="Check3">{{ 'home.id' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" id="Check4">
                            <label class="form-check-label" for="Check4">{{ 'home.phone' | translate }}</label>
                        </div>
                    </form>


                </div>
            </div>
        </div>

        <!-- Moteur de recherche -->
    </div>
    <!-- Blocs -->
    <div class="row mt-3" style="display: none;">
        <!-- Niche 1 -->
        <div *ngFor="let niche of nichesItems" class="col col-niche mb-3">
            <div class="card">
                <div [className]="'card-header niche-'+niche.name | lowercase">
                    <h1 class="text-center">
                        <fa-icon [icon]="['fas', 'heart']"></fa-icon>
                        {{niche.name}}
                    </h1>
                </div>
                <div class="card-body p-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-user"></i> {{ 'home.group.profile' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-tag"></i> {{ 'login' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-image"></i> {{ 'home.group.avatar' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-file-alt"></i> {{ 'home.group.presentation' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-images"></i> {{ 'home.group.photo' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                        <li class="list-group-item p-0"><h5 class="card-title mb-0 text-white"><i
                                class="fa fa-exclamation-triangle"></i> {{ 'home.group.alert' | translate }}</h5></li>
                        <li class="list-group-item">
                            <span class="badge"><div class="counter">1</div><div class="counter">1</div><div
                                    class="counter">2</div></span> {{ 'home.group.moderate' | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Niche 1 -->
    </div>
    <!-- Blocs -->
</div>
<!-- Container -->
