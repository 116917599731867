<div class="cartouche card mb-2" [ngClass]="getSegmentClassName()" [@fade]="itemDeleted ? 'fadeOut' : 'active'">
    <div class="ico-niche"><i class="fas fa-heart"></i></div>
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col-lg-3 col-xl-4">
                <img [src]="getLogoUrl()" class="produit">
                <div class="seg"><span class="segment">{{ getSegmentName() }}</span></div>
            </div>
            <div class="col-lg-6 col-xl-6 restriction">
                <span *ngFor="let restriction of getRestrictions() | keyvalue">
                    <i class="fas fa-times-circle text-danger ml-2"
                       [ngClass]="{
                       'fa-times-circle':!restriction.value,
                       'text-danger':!restriction.value,
                       'fa-check-circle':restriction.value,
                       'text-success':restriction.value
                       }"></i>&nbsp;{{ 'moderation.restrictions.' + restriction.key | translate }}
                </span>
            </div>
            <div class="col-lg-3 col-xl-2 text-right time">
                {{ moderation.getCreatedAt() | date:'dd/MM/yyyy HH:mm' }}
                <div class="counter" [ngClass]="{'time-elapsed':isTimeElapsed}">
                    {{ elapsedTime }}
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5 col-xl-4">
                <div class="utilisateur">
                    <div class="icones">
                        <span data-toggle="tooltip" data-placement="top"
                              data-original-title="Se connecter en tant que ...">
                            <button class="btn btn-primary text-white action" (click)="impersonate()">
                                <i class="fas fa-sign-in-alt"></i>
                            </button>
                        </span>
                        <br>
                        <span data-toggle="tooltip" data-placement="top"
                              data-original-title="Ghoster/Déghoster"><button
                                class="btn btn-primary action text-white" (click)="ghost()"
                                [ngClass]="{'btn-red':getUser().isGhosted()}"><i
                                class="fab fa-snapchat-ghost"></i></button></span>
                    </div>
                    <div class="users-img">
                        <img src="{{ getUser() | userImage: '130x130' }}" alt="">
                        <span class="fa-stack fa-lg user-blocked" *ngIf="getUser().isGhosted()">
                            <i class="fas fa-circle fa-stack-1x bloq-background user-blocked-1"></i>
                            <i class="fas fa-circle fa-stack-1x text-white user-blocked-2"></i>
                            <i class="fab fa-snapchat-ghost fa-stack-1x bloq-background user-blocked-3"></i>
                        </span>
                        <a *ngIf="hasProfilePhoto()" class="btn btn-primary modif-photo"
                           (click)="cropPhoto()">{{ 'moderation.crop' | translate }}</a>
                    </div>
                    <div class="moderation-users-detail text-truncate">
                        <p>{{ 'moderation.nickname' | translate }} : {{ getUser().getUsername() }}<br>
                            <small>{{ 'born_the' | translate }} : {{ getUser() | profile: ['birthdate'] }}<br>
                                {{ 'ui.age' | translate }} : {{ getUser() | profile: ['age'] }}<br>
                                {{ 'ui.register_at' | translate }} : {{ getUser() | profile: ['registerdate'] }}<br>
                                {{ 'ui.status_member' | translate }} : {{ getUser() | profile: ['subscriptionName'] }}
                                <br>
                                IP : {{ getUser().getIp() }} ({{ getUser().getCountry() }})<br>
                                Profile : {{ getUser().getUid() }}<br>
                                #{{ moderation.getId() }}<br>
                                <span class="rating-photo"
                                      *ngIf="hasProfilePhoto()">{{ 'moderation.photo_rating' | translate }} :
                                    <app-star-rating [rating]="getUser().getPhotoRating()"
                                                     [count]="5"
                                                     (ratingChanged)="updatePhotoRating($event)"></app-star-rating>
                                </span>
                            </small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7 col-xl-8 attention">
                <app-moderation-type [moderation]="moderation" #moderationTypeComponent
                                     (loadingChanged)="onLoadingChanged($event)"></app-moderation-type>
            </div>
        </div>
    </div>

    <div class="card-footer">
        <div class="row">
            <div class="col-xl-5">
                <div class="form-group row no-gutters mb-0">
                    <label class="col-form-label mr-2">{{ 'moderation.i_am' | translate }} :</label>
                    <div>
                        <select class="custom-select" (change)="switchGender('gender')" [(ngModel)]="gender">
                            <option value="1">{{ 'moderation.a_man' | translate }}</option>
                            <option value="2">{{ 'moderation.a_woman' | translate }}</option>
                        </select>
                    </div>
                    <label class="col-form-label ml-4 mr-2">{{ 'moderation.i_search' | translate }} :</label>
                    <div>
                        <select class="custom-select" (change)="switchGender('searchedGender')"
                                [(ngModel)]="searchedGender">
                            <option value="1">{{ 'moderation.a_man' | translate }}</option>
                            <option value="2">{{ 'moderation.a_woman' | translate }}</option>
                        </select>
                    </div>
                    <div class="ml-2">
                        <button class="btn btn-primary text-white modifier"
                                (click)="updateGender()">{{ 'operators.update' | translate }}</button>
                    </div>
                </div>
            </div>

            <div class="col-xl-7 text-right mt-2 mt-xl-0">
                <div class="form-group mb-0">
                    <div class="input-group choix-refus ml-auto">
                        <div class="input-group-prepend" #reasonParent>
                            <button class="btn btn-select" type="button" id="btn-select"
                                    (click)="reasonDropdownOpened=!reasonDropdownOpened">
                                {{ getReasonsText() }}
                            </button>
                            <ul class="multiselect-container dropdown-menu collapse"
                                [ngClass]="{show:reasonDropdownOpened}">
                                <li *ngFor="let reason of getReasons()">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="reason + id"
                                               #reasonCheckbox (change)="setReason(reason, reasonCheckbox.checked)">
                                        <label class="custom-control-label"
                                               [attr.for]="reason + id">{{ 'moderation.reason.' + reason | translate }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <button *ngFor="let button of getRejectButtons()" class="btn btn-danger text-white ko mr-2"
                                (click)="reject(button.action)" [disabled]="moderating">
                            <i [ngClass]="button.icon"></i>
                            {{ 'moderation.' + button.value | translate }}
                        </button>
                        <button class="btn btn-success text-white ok"
                                (click)="accept()" [disabled]="moderating || rejectReasons.length > 0"><i
                                class="fas fa-check"></i>
                            {{ 'moderation.ok' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="loading">
        <i class="fas fa-spin fa-spinner fa-4x"></i>
    </div>
</div>
