/**
 * AbstractModeration
 */
import {User} from '../user/user';

/**
 * AbstractModeration
 */
export abstract class AbstractModeration {
    static readonly MODERATION_PRESENTATION = 'presentation';
    static readonly MODERATION_IDEAL_PRESENTATION = 'idealPresentation';
    static readonly MODERATION_PROFILE_PHOTO = 'profilePhoto';
    static readonly MODERATION_ALBUM_PHOTO = 'albumPhoto';
    static readonly MODERATION_COVER_PHOTO = 'coverPhoto';
    static readonly MODERATION_REPORT = 'report';
    static readonly MODERATION_NICKNAME = 'nickname';

    /**
     * Id
     * @protected
     */
    protected id: number = null;

    /**
     * Moderation type
     * @protected
     */
    protected type: string = null;

    /**
     * User user
     * @protected
     */
    protected user: User = null;

    /**
     * Date
     * @protected
     */
    protected createdAt: Date = null;

    protected constructor(type: string) {
        this.type = type;
    }

    setId(id: number) {
        this.id = id;
    }

    getId(): number {
        return this.id;
    }

    getType(): string {
        return this.type;
    }

    setUser(user: User) {
        this.user = user;
    }

    getUser(): User {
        return this.user;
    }

    setCreatedAt(createdAt: Date) {
        this.createdAt = createdAt;
    }

    getCreatedAt(): Date {
        return this.createdAt;
    }

    getReasons(): string[] {
        return [];
    }

    getData(): any {
        return null;
    }

    getTimeout(): number {
        return 60;
    }
}
