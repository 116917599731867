import {Component, OnInit} from '@angular/core';
import {UserManagementService} from '../../../services/user/user-management.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    sitesItems = [
        {id: 1, name: 'Site 1'},
        {id: 2, name: 'Site 2'}
    ];

    nichesItems = [];

    languagesItem = [
        {id: 1, name: 'Français'},
        {id: 2, name: 'Anglais'},
        {id: 3, name: 'Allemand'},
        {id: 4, name: 'Espagnol'},
        {id: 5, name: 'Italien'}
    ];

    statutsItems = [
        {id: 1, name: 'Statut 1'},
        {id: 2, name: 'Statut 2'},
        {id: 3, name: 'Statut 3'}
    ];

    constructor() {
    }

    ngOnInit() {
    }
}
