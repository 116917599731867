import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../api/response/api-response';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    constructor(
        private socket: Socket
    ) {
    }

    /**
     * Send API Request and return an API Response
     * @param event
     * @param body
     */
    sendApiRequest(event: string, body?: any): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit(event, body, (data: any) => {
                observer.next(new ApiResponse().deserialize(data));
            });
        });
    }
}
