import {Component, OnInit} from '@angular/core';
import {OperatorService} from '../../../../services/operator/operator.service';
import {Operator} from '../../../../models/operator/operator.model';
import {ModalService} from '../../../../services/modal/modal.service';
import {DropdownSelectOptionInterface} from '../../../ui/dropdown-select/dropdown-select-option-interface';
import {ProductGroupService} from '../../../../services/product-group/product-group.service';
import {ProductGroup} from '../../../../models/product/product-group';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-operator-list',
    templateUrl: './operator-list.component.html',
    styleUrls: ['./operator-list.component.css']
})
export class OperatorListComponent implements OnInit {

    languageOptions: DropdownSelectOptionInterface[] = [];
    managerOptions: DropdownSelectOptionInterface[] = [];
    productGroupOptions: DropdownSelectOptionInterface[] = [];

    operators: Operator[] = [];
    productGroups: ProductGroup[] = [];

    loading = false;

    filterStatus = null;
    filterLanguages = null;
    filterManagers = null;
    filterProductGroups = null;

    constructor(
        private operatorService: OperatorService,
        private modalService: ModalService,
        private productGroupService: ProductGroupService,
        private translationService: TranslateService
    ) {
    }

    ngOnInit() {
        this.languageOptions = this.operatorService.getLanguages().map((language: string) => {
            return {
                label: language,
                value: language
            };
        });

        this.loading = true;
        this.productGroupService.getProductGroups().subscribe((productGroups: ProductGroup[]) => {
            this.productGroups = productGroups;
            this.productGroupOptions = this.productGroups
                .sort((a: ProductGroup, b: ProductGroup) => a.getName().localeCompare(b.getName()))
                .map((g: ProductGroup) => {
                    return {
                        label: g.getName(),
                        value: g.getId()
                    };
                });

            this.loadOperators();
        });
    }

    getFilteredOperators(): Operator[] {
        return this.operators.filter((operator: Operator) => {
            /**
             * Status
             */
            if (this.filterStatus) {
                if (operator.getStatus() !== this.filterStatus) {
                    return false;
                }
            }

            /**
             * Languages
             */
            if (this.filterLanguages && this.filterLanguages.length) {
                let matched = false;
                for (const language of this.filterLanguages) {
                    if (operator.getLanguages().includes(language)) {
                        matched = true;
                        break;
                    }
                }

                if (!matched) {
                    return false;
                }
            }

            /**
             * Managers
             */
            if (this.filterManagers && this.filterManagers.length) {
                if (!this.filterManagers.includes(operator.getManagerId())) {
                    return false;
                }
            }

            /**
             * Product groups
             */
            if (this.filterProductGroups && this.filterProductGroups.length) {
                let matched = false;
                for (const filterProductGroup of this.filterProductGroups) {
                    if (operator.getProductGroups().includes(filterProductGroup)) {
                        matched = true;
                        break;
                    }
                }

                if (!matched) {
                    return false;
                }
            }

            return true;
        });
    }

    loadOperators() {
        this.loading = true;
        this.operatorService.getOperators().subscribe((operators: Operator[]) => {
            this.loading = false;

            this.operators = operators;

            this.managerOptions = this.operators.filter((o: Operator) => o.isManager()).map((o: Operator) => {
                return {
                    label: o.getName(),
                    value: o.getId()
                };
            });
        }, (error) => {
            this.modalService.openError(error).subscribe();
        });
    }

    getOperatorNameById(id: number): string {
        const operator = this.operators.find((o: Operator) => o.getId() === id);
        return operator ? operator.getName() : '';
    }

    delete(operator: Operator) {
        this.modalService.openOkCancel(
            this.translationService.instant('operator.delete_title'),
            this.translationService.instant('operator.delete_message')
        ).subscribe((result) => {
            if (result === 'ok') {
                this.loading = true;
                this.operatorService.deleteOperator(operator.getId()).subscribe(() => {
                    this.loadOperators();
                }, (error) => {
                    this.loading = false;
                    this.modalService.openError(error);
                });
            }
        });
    }

    languageOptionsChanged(languages: string[]) {
        this.filterLanguages = languages;
    }

    managerOptionsChanged(managers: number[]) {
        this.filterManagers = managers;
    }

    productGroupOptionsChanged(productGroups: number[]) {
        this.filterProductGroups = productGroups;
    }

    isOnline(operator: Operator) {
        return this.operatorService.isOnline(operator);
    }
}
