/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./online-switch.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/slide-toggle/typings/index.ngfactory";
import * as i3 from "@angular/material/slide-toggle";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/forms";
import * as i8 from "./online-switch.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../services/chat/chat.service";
import * as i11 from "@ngx-translate/core";
var styles_OnlineSwitchComponent = [i0.styles];
var RenderType_OnlineSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnlineSwitchComponent, data: {} });
export { RenderType_OnlineSwitchComponent as RenderType_OnlineSwitchComponent };
export function View_OnlineSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-slide-toggle", [["class", "mat-slide-toggle"]], [[8, "id", 0], [1, "tabindex", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.status = $event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.changeStatus() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatSlideToggle_0, i2.RenderType_MatSlideToggle)), i1.ɵdid(1, 1228800, null, 0, i3.MatSlideToggle, [i1.ElementRef, i4.FocusMonitor, i1.ChangeDetectorRef, [8, null], i1.NgZone, i3.MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, [2, i5.ANIMATION_MODULE_TYPE], [2, i6.Directionality]], null, { change: "change" }), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatSlideToggle]), i1.ɵdid(3, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(5, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_15 = _co.status; _ck(_v, 3, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; var currVal_1 = (i1.ɵnov(_v, 1).disabled ? null : (0 - 1)); var currVal_2 = null; var currVal_3 = null; var currVal_4 = i1.ɵnov(_v, 1).checked; var currVal_5 = i1.ɵnov(_v, 1).disabled; var currVal_6 = (i1.ɵnov(_v, 1).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_8 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 5).ngClassValid; var currVal_13 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_16 = _co.getStatusText(); _ck(_v, 6, 0, currVal_16); }); }
export function View_OnlineSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-online-switch", [], null, null, null, View_OnlineSwitchComponent_0, RenderType_OnlineSwitchComponent)), i1.ɵdid(1, 114688, null, 0, i8.OnlineSwitchComponent, [i9.MatDialog, i10.ChatService, i11.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OnlineSwitchComponentNgFactory = i1.ɵccf("app-online-switch", i8.OnlineSwitchComponent, View_OnlineSwitchComponent_Host_0, {}, {}, []);
export { OnlineSwitchComponentNgFactory as OnlineSwitchComponentNgFactory };
