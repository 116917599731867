/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./truncate-picture-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i5 from "ngx-image-cropper";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./truncate-picture-modal.component";
import * as i8 from "@angular/forms";
import * as i9 from "ngx-toastr";
var styles_TruncatePictureModalComponent = [i0.styles];
var RenderType_TruncatePictureModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TruncatePictureModalComponent, data: {} });
export { RenderType_TruncatePictureModalComponent as RenderType_TruncatePictureModalComponent };
export function View_TruncatePictureModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { imageCropper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "h5", [["class", "mb-0 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-flag"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " ", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "image-cropper", [], [[4, "text-align", null]], [[null, "imageLoaded"], [null, "imageCropped"], [null, "cropperReady"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageLoaded" === en)) {
        var pd_5 = (_co.imageLoaded() !== false);
        ad = (pd_5 && ad);
    } if (("imageCropped" === en)) {
        var pd_6 = (_co.imageCropped($event) !== false);
        ad = (pd_6 && ad);
    } if (("cropperReady" === en)) {
        var pd_7 = (_co.cropperReady() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i4.View_ImageCropperComponent_0, i4.RenderType_ImageCropperComponent)), i1.ɵdid(10, 573440, [[1, 4]], 0, i5.ImageCropperComponent, [i6.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], imageBase64: [1, "imageBase64"], resizeToWidth: [2, "resizeToWidth"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "row boutons-recadrage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-light btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-undo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-light btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotateLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "fa fa-undo fa-flip-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg btn-block next mx-auto p-3 text-white mb-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCrop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, null, 0, "div", [["style", "clear:both;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.imageChangedEvent; var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.croppedImageBase64, ""); var currVal_5 = 128; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("crop_avatar")); var currVal_1 = _co.username; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).alignImage; _ck(_v, 9, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("button.validate")); _ck(_v, 22, 0, currVal_6); }); }
export function View_TruncatePictureModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-picture-truncate-modal", [], null, null, null, View_TruncatePictureModalComponent_0, RenderType_TruncatePictureModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.TruncatePictureModalComponent, [i2.MAT_DIALOG_DATA, i8.FormBuilder, i2.MatDialogRef, i9.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TruncatePictureModalComponentNgFactory = i1.ɵccf("app-picture-truncate-modal", i7.TruncatePictureModalComponent, View_TruncatePictureModalComponent_Host_0, {}, {}, []);
export { TruncatePictureModalComponentNgFactory as TruncatePictureModalComponentNgFactory };
