<div class="utilisateur">
    <div class="users-img">
        <img src="{{ currentUser | userImage: '64x64' }}"
             alt="">
        <div class="sex">
            <i [ngClass]="currentUser.getGenderClass()"></i>
        </div>
    </div>
    <div class="users-detail text-truncate user-detail-mobile">
        <p>{{currentUser.username | titlecase }}&nbsp;
            <i *ngIf="(currentUser.product?.name)"
               [className]="currentUser.product?.segment?.getCssClass() + ' ' + currentUser.product?.segment?.name">&nbsp;
                <span class="product-name">{{
                    currentUser.product?.name }} {{ currentUser.product?.segment?.name }}</span></i>
        </p>
        <div class="row no-gutters">
            <div class="col-6 mt-1">
                <p>
                    <small>
                        <span>{{ currentUser | profile: ['firstname'] }}</span><br>
                        <span>{{ currentUser | profile: ['gender', 'age'] }}</span><br>
                        <span>{{ currentUser | profile: ['birthdate'] }}</span><br>
                        <span>{{ currentUser | profile: ['city', 'country', 'ip'] }}</span><br>
                    </small>
                </p>
            </div>
            <div class="col-6 mt-1">
                <p>
                    <small class="no-whitespace">{{ 'ui.register_at' | translate }} : {{
                        currentUser.date | date:'dd/MM/yyyy' }}<br>
                        {{ 'ui.last_connect' | translate }} : {{
                        currentUser.lastConnection | date:'dd/MM/yyyy' }}<br>
                        {{ 'ui.status_member' | translate }} : {{ currentUser?.subscription?.name || 'NC' | translate }}
                    </small>
                </p>
            </div>
        </div>
    </div>
</div>
