import {Component, OnInit} from '@angular/core';
import {User} from '../../../models/user/user';
import {UserManagementService} from '../../../services/user/user-management.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification/notification.service';
import {ChatService} from '../../../services/chat/chat.service';
import {UserAlertsComponent} from './user-alerts/user-alerts.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage/storage.service';
import {ModerationService} from '../../../services/moderation/moderation.service';
import {ApiResponse} from '../../../api/response/api-response';
import {LoaderService} from 'angular-intl';
import {OperatorService} from '../../../services/operator/operator.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    // /**
    //  * CurrentUser operator
    //  */
    // currentUser: User;

    /**
     * Alert counter for animation / alert and moderation
     */
    counter: any = {
        animation: 0,
        alert: 0,
        moderation: 0
    };

    /**
     *
     * @param userManagementService UserService Provider
     * @param router router Provider
     * @param notificationService Notification service provider
     * @param chatService Chat service
     * @param dialog MatDialog
     * @param translateService TranslateService
     * @param storageService
     * @param moderationService
     */
    constructor(
        private userManagementService: UserManagementService,
        private router: Router,
        private notificationService: NotificationService,
        private chatService: ChatService,
        private dialog: MatDialog,
        public translateService: TranslateService,
        private storageService: StorageService,
        private moderationService: ModerationService,
        private operatorService: OperatorService
    ) {
    }

    ngOnInit() {
        // Create an observer for notification event
        const counterObservable = this.notificationService.getNotification();
        counterObservable.subscribe((data: any) => {
            this.counter = data;
        });
    }

    /**
     * User Logout
     */
    logout() {
        // remove automatic login
        this.storageService.delete('connected');

        // loggout from the socket
        this.chatService.logout();

        // loggout from the app
        this.userManagementService.logout();
        this.router.navigate(['/login']);
    }

    // displayAlert() {
    //     const dialogRef = this.dialog.open(UserAlertsComponent, {
    //         data: {operator: this.currentUser},
    //         minWidth: '800px',
    //         minHeight: '500px'
    //     });
    //     this.notificationService.reset('alert');
    // }

    getUsername() {
        const user = this.userManagementService.getCurrentUser();

        if (user) {
            return user.getUsername();
        }
    }

    countOperators() {
        return this.operatorService.getConnectedOperators().length;
    }

    getOperatorUsernameList() {
        const operatorList = [];

        for (const operator of this.operatorService.getConnectedOperators()) {
            operatorList.push('   •  ' + operator.username.charAt(0).toUpperCase() + operator.username.slice(1));
        }

        return this.translateService.instant('operators_list') + '\r\n' + operatorList.join('\r\n');
    }

    useLanguage(language: string) {
        this.translateService.use(language);
        this.storageService.set('language', language);
    }

    getTranslateService(): TranslateService {
        return this.translateService;
    }

    isModerator(): boolean {
        return this.userManagementService.isModerator();
    }

    isAnimator(): boolean {
        return this.userManagementService.isAnimator();
    }

    isAdmin(): boolean {
        return this.userManagementService.isAdmin();
    }

    isManager(): boolean {
        return this.userManagementService.isManager();
    }

    getModerationCount(): number {
        return this.moderationService.getModerations().length;
    }

    operatorAnimatorStatusChange(enabled: boolean) {
        if (this.isAnimator() !== enabled) {
            this.setOperatorStatus(this.isModerator(), enabled);
        }
    }

    operatorModeratorStatusChange(enabled: boolean) {
        if (this.isModerator() !== enabled) {
            this.setOperatorStatus(enabled, this.isAnimator());
        }
    }

    setOperatorStatus(moderationEnabled: boolean, animationEnabled: boolean) {
        this.chatService.setOperatorStatus(moderationEnabled, animationEnabled)
            .subscribe((response: ApiResponse) => {
                if (response.success) {
                    this.userManagementService.setModerator(response.data.moderator);
                    this.userManagementService.setAnimator(response.data.animator);

                    if (!this.isAnimator()) {
                        this.chatService.reset();
                    }

                    if (!this.isModerator()) {
                        this.moderationService.reset();
                    }
                }
            });
    }
}
