import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder} from '@angular/forms';
import {UserAlertService} from '../../../../services/user-alert/user-alert.service';
import {ModalService} from '../../../../services/modal/modal.service';
import {ChatService} from '../../../../services/chat/chat.service';
import {Socket} from 'ngx-socket-io';
import {TranslateService} from '@ngx-translate/core';
import {Alert} from '../../../../models/alert/alert';

@Component({
    selector: 'app-user-alerts',
    templateUrl: './user-alerts.component.html',
    styleUrls: ['./user-alerts.component.css']
})
export class UserAlertsComponent implements OnInit {

    /**
     * Default page for pagination
     */
    p = 1;

    /**
     * Number of element per page
     */
    count = 8;

    /**
     * User alerts
     */
    userAlerts;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private dialogRef: MatDialogRef<UserAlertsComponent>,
                private userAlertService: UserAlertService,
                private modalService: ModalService,
                private chatService: ChatService,
                private socket: Socket,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.userAlerts = this.userAlertService.getUserAlerts();
    }

    /**
     * Remove alert for the current alert list, an
     * d inform the backend
     */
    removeAlert(alert: Alert) {
        if (this.modalService.message(this.translateService.instant('header.user_alerts.confirm'))) {

            this.socket.emit('remove_calendar_alert', alert.id);

            const i = this.userAlerts.indexOf(alert);
            if (i >= 0) {
                this.userAlerts.splice(i, 1);
            }
        }
    }

    /**
     * Manage alert
     * @param alert Alert
     */
    manageAlert(alert: Alert) {

        if (this.modalService.message(this.translateService.instant('header.user_alerts.sure'))) {

            const room = this.chatService.getFakeRooms().find(r => r.getId() === alert.roomId);

            if (room) {
                this.chatService.setCurrentRoom(room);
                // this.userAlertService.setAlertToManaged(alert);
            }

            // Close the modal
            this.dialogRef.close();
        }
    }
}
