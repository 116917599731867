import {
    AfterViewChecked,
    Component,
    ElementRef, Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChatService} from '../../../../services/chat/chat.service';
import {ActionComponent} from '../../animation/action/action.component';
import {UserManagementService} from '../../../../services/user/user-management.service';
import {CalendarService} from '../../../../services/calendar/calendar.service';
import {ActionService} from '../../../../services/action/action.service';
import {MatDialog} from '@angular/material';
import {ModalService} from '../../../../services/modal/modal.service';
import {Room} from '../../../../models/room/room';
import {Message} from '../../../../models/message/message';
import {User} from '../../../../models/user/user';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-chat-room',
    templateUrl: './room.component.html',
    styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnChanges, OnInit, AfterViewChecked {

    @ViewChild('messageList', {static: false}) private messageList: ElementRef;
    @ViewChild('messageInput', {static: false}) private messageInput: ElementRef;

    /**
     * Current room
     */
    @Input() room: Room;

    // Information about the real user in an animation context only
    profileDetails: any;

    /**
     * Display the message chat box or not
     */
    displayPanel = false;

    /**
     * Display the animation panel
     */
    displayAnimation = false;

    /**
     * Display the moderation panel
     */
    displayModeration = false;

    /**
     * Display the buttons for a given message
     */
    displayModerationButtons = 0;

    /**
     * List of all message received by the client
     */
    userMessage;

    /**
     * Form group to send message chat
     */
    messageForm: FormGroup;

    /**
     * Boolean to check if the form is submit or not
     */
    submitted = false;

    /**
     * First Message display per day
     */
    private messageDateSeparator;

    /**
     * Display the spinner
     */
    private loading = false;

    /**
     *
     */
    private userTypingMessage = '';

    /**
     * Know if user writing now
     */
    private userTyping = false;

    /**
     * timer for typing
     */
    private typingTimer = null;

    /**
     * Permet de savoir si l'on peut avoir le scroll infini
     */
    private hasInfiniteScroll = true;

    /**
     * Permet de savoir si on est scrollé tout en haut pour afficher le spinner
     */
    private isScrolled = false;

    /**
     * Scrolling asked
     */
    private scrollingDown = false;

    /**
     * Permet de savoir si on a déjà scrollé dans la conversation
     * avant de scroller en hauteur pour récupérer les messages
     */
    private alreadyScrolledDown = false;

    constructor(private userManagementService: UserManagementService,
                private chatService: ChatService,
                private calendarService: CalendarService,
                private formBuilder: FormBuilder,
                private actionService: ActionService,
                public dialog: MatDialog,
                private confirmModal: ModalService,
                private translateService: TranslateService) {

        // Form builder for the messageForm
        this.messageForm = this.formBuilder.group({
            message: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.messageForm.controls;
    }

    ngOnInit() {
        this.userMessage = this.chatService.getMessageToDisplay();

        this.chatService.getMessages().subscribe((message: Message) => {
            if (message.getRoom() === this.room) {
                // New message in this room : scroll down
                this.scrollDown();

                this.room.resetUnreadMessage();
                this.chatService.setRoomRead(this.room);
            }
        });
    }

    ngAfterViewChecked() {
        /**
         * Scrolling down and give focus to the input
         */
        if (this.scrollingDown) {
            this.scrollingDown = false;

            if (this.messageList && this.messageList.nativeElement.scrollHeight) {
                this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
            }

            if (this.messageInput) {
                this.messageInput.nativeElement.focus({
                    preventScroll: true
                });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        /**
         * New room selected ?
         */
        if (changes.room) {
            this.room = changes.room.currentValue;

            if (this.room) {
                // When we change room, reset alreadyScrolledDown
                // force user to scroll down before using infinite scroll (scrolling up) to load more message
                this.alreadyScrolledDown = false;

                // When we change room, reset hasInfiniteScroll for the next room
                this.hasInfiniteScroll = true;

                if (!this.room.isLoaded()) {
                    // Display spinner
                    this.loading = true;
                    this.displayPanel = false;

                    // Animation : getMe()
                    // Moderation : getUser1()
                    const user = this.room.getMe() ? this.room.getMe() : this.room.getUser1();

                    // Load messages of this room
                    this.chatService.getPrivateRoomMessages(this.room, user, 0, 10).subscribe(() => {
                        if (this.room && this.room.getMe()) {
                            this.chatService.retrieveUserProfilesDetails(this.room.getOther().id, this.room.getMe().id);
                            this.chatService.getUserInformationDetail().subscribe((userDetails: any) => {
                                this.profileDetails = userDetails;
                                this.showRoom();
                            });
                        } else {
                            this.showRoom();
                        }
                    });
                } else {
                    this.showRoom();
                }
            }
        }
    }

    getRelationshipTitle(type: string, relationship: string): string {
        if (!this.profileDetails || !this.profileDetails[type]) {
            return '';
        }
        const data = this.profileDetails[type][relationship];

        switch (relationship) {
            case 'favorite':
                if (type === 'relationship') {
                    if (!data) {
                        return 'button.add_to_favorite';
                    }
                } else if (data) {
                    return 'button.you_are_favorite';
                }
                break;
            case 'kissed':
                if (type === 'relationship') {
                    if (!data) {
                        return 'button.send_kiss';
                    }
                } else if (data) {
                    return 'button.you_are_kissed';
                }
                break;
            case 'friend':
                if (data) {
                    return 'button.you_are_friends';
                }
                return data === null ? 'button.friend_request' : 'button.friend_request_pending';
        }

        return '';
    }

    socialAction(relationship: string) {
        if (!this.profileDetails.relationship || this.profileDetails.relationship[relationship]) {
            return;
        }
        const forcedAction = relationship === 'friend' && this.profileDetails.relationship[relationship] === 0 ? 'acceptFriend' : null;

        this.chatService.socialAction(relationship, this.room.getMe(), this.room.getOther(), forcedAction).then(success => {
            if (success) {
                this.profileDetails.relationship[relationship] = relationship === 'friend' ? (forcedAction ? true : 0) : true;
            }
        });
    }

    actionUser(zmUserProfile, display, viewWith = false) {
        const data: any = {zmUserProfile, display};

        if (viewWith && zmUserProfile && this.room && this.room.getMe() && this.room.getMe().getId() !== zmUserProfile.getId()) {
            data.withUser = this.room.getMe();
        }

        this.dialog.open(ActionComponent, {
            data,
            minWidth: '800px'
        });

        this.actionService.alertUser(zmUserProfile);
    }

    scrollDown() {
        this.scrollingDown = true;
    }

    displayMessageDate(message): boolean {

        const isSameDay = (this.messageDateSeparator.getDate() === message.date.getDate()
            && this.messageDateSeparator.getMonth() === message.date.getMonth()
            && this.messageDateSeparator.getFullYear() === message.date.getFullYear());

        this.messageDateSeparator = message.date;

        if (!isSameDay) {
            return true;
        }

        return false;
    }

    unghost(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unghost'))) {
            this.chatService.reactiveUser(user, 'ghosted').subscribe();
        }
    }

    unban(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unbannish', {user: user.username}))) {
            this.chatService.reactiveUser(user, 'banned').subscribe();
        }
    }

    unsuspend(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unstop', {user: user.username}))) {
            this.chatService.reactiveUser(user, 'suspended').subscribe();
        }
    }

    submitMessage($event) {
        $event.stopPropagation();
        this.f.message.setValue(this.chatService.getMessageByEmojiReplaced(this.f.message.value));

        return true;
    }

    getMessageInputValue() {
        return this.messageInput.nativeElement.innerText;
    }

    sendMessage(user: User, recipient: User, $event) {
        this.submitted = true;

        // stop here if form is invalid
        if (this.messageForm.invalid) {
            return;
        }

        const message = this.getMessageInputValue();

        // Send message
        this.chatService.sendMessage(recipient.id, user.id, message);

        // Reset value from the form
        this.messageForm.reset();
    }

    getMessages(): Message[] {
        this.messageDateSeparator = new Date();

        if (this.room) {
            return this.room.getMessages();
        }

        return [];
    }

    deleteMessage(index) {
        const message = this.getMessages()[index];

        if (!this.confirmModal.message(this.translateService.instant('chat.confirm_delete_message') + ' "' + message.message + '"')) {
            return;
        }

        this.chatService.deleteMessage(message);
    }

    hasMessages(): boolean {
        return this.room && this.room.getMessages().length > 0;
    }

    isTypingByUser(): boolean {
        return this.room && this.room.isTyping();
    }

    startTypingTimeout(userId: number, roomId: number): void {
        if (this.userTyping) {
            // if user is typing, remove last timeout to create new timeout :
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }

            this.typingTimer = setTimeout(() => {
                this.chatService.notifyTypingToFriendUser({
                    typing: false,
                    roomId,
                    userId
                });

                this.userTyping = false;

                clearTimeout(this.typingTimer);
            }, 5000);
        }
    }

    onTypingMessage(event, user: User) {
        const message = this.getMessageInputValue();
        const typing = message ? message.length > 0 : false;

        if (typeof this.userTypingMessage === 'undefined') {
            this.userTypingMessage = '';
        }

        const messageIsDifferent = message !== this.userTypingMessage;

        // If we write or delete AND if the message is different:
        if (messageIsDifferent && this.userTyping !== typing) {
            this.chatService.notifyTypingToFriendUser({
                typing,
                roomId: this.room.getId(),
                userId: user.id
            });

            this.userTyping = typing;
        }

        if (typing) {
            // Tant qu'on écrit => restart le typing Timeout :
            this.startTypingTimeout(user.getId(), this.room.getId());
        }

        this.userTypingMessage = message;
    }

    textToEmoji($event) {
        const target = $event.target || $event.srcElement || $event.currentTarget;
        const text = this.chatService.textToEmoji(this.f.message.value, target);

        if (text !== this.f.message.value) {
            this.f.message.setValue(text);
        }
    }

    shortcut($event) {
        switch ($event.key) {
            case 'Enter':
                const element: HTMLElement = document.querySelector('#submit-message') as HTMLElement;
                element.click();
                $event.preventDefault();
                break;
        }
    }

    onClickedOutside(e: Event) {
        if (!(e.target as HTMLInputElement).closest('#actionSmiley') && this.chatService.emojisShow) {
            this.chatService.emojisShow = false;
        }
    }

    isLoading() {
        return this.loading;
    }

    /**
     * Permet de savoir si l'on peut avoir des messages plus anciens via l'infinite scroll
     * (afficher ou non le message "charger d'autres conversations")
     */
    canInfiniteScroll() {
        return this.hasInfiniteScroll && this.room && this.room.getMessages().length >= 10;
    }

    isScroll() {
        return this.isScrolled;
    }

    onScrollMessages() {
        const container = document.querySelector('.chatroom .dialogue');
        if (!container) {
            return;
        }
        const scrollTop = container.scrollTop;

        // load more messages only if user has already scroll down and finally scroll to top !
        if (scrollTop === 0 && this.canInfiniteScroll() && this.alreadyScrolledDown) {
            this.moreMessages();
            // Reset alreadyScrolledDown.
            // Attention : la fonction this.moreMessages est asynchrone, ne pas utiliser la variable alreadyScrolledDown
            // dans celle-ci car elle risque d'être à false avant l'exécution de la fonction moreMessages?
            // cela permet toutefois de ne pas faire plusieurs scroll infini à la fois.
            this.alreadyScrolledDown = false;
        } else if (scrollTop > 0 && !this.alreadyScrolledDown) {
            this.alreadyScrolledDown = true;
        }
    }

    moreMessages() {
        this.isScrolled = true;

        this.chatService.loadMoreMessages(this.room, (hasMessages) => {
            this.hasInfiniteScroll = hasMessages;
            this.isScrolled = false;
        });
    }

    checkEmojiSupport(message) {
        return this.chatService.checkEmojiSupport(message);
    }

    switchEmojisShow() {
        this.chatService.switchEmojisShow();
    }

    getEmojiShow() {
        return this.chatService.emojisShow;
    }

    isFrustrated(): boolean {
        const frustration = this.room.getOther().getFrustrationRule();

        if (!frustration || frustration.isCoin()) {
            return false;
        }

        return !(
            frustration.getDeferred() &&
            frustration.getDeferred().getTimeout() &&
            frustration.getDeferred().getTimeout() > 0 &&
            frustration.getDeferred().getMessageReceivedLeft() &&
            frustration.getDeferred().getMessageSentLeft()
        );
    }

    private showRoom() {
        this.loading = false;
        this.displayPanel = true;
        this.displayModeration = false;
        this.displayAnimation = false;

        if (this.room) {
            if (this.room.getMe()) {
                this.displayAnimation = true;
            } else {
                this.displayModeration = true;
            }
        }

        this.scrollDown();
    }
}
