import { Observable } from 'rxjs';
import { ProductGroup } from '../../models/product/product-group';
import { SocketService } from '../socket/socket.service';
import * as i0 from "@angular/core";
import * as i1 from "../socket/socket.service";
export class ProductGroupService {
    constructor(socketService) {
        this.socketService = socketService;
    }
    /**
     * Get product group list
     */
    getProductGroups() {
        return new Observable((observer) => {
            this.socketService.sendApiRequest('get_product_groups').subscribe((response) => {
                if (response.success) {
                    observer.next(response.data.productGroups.map((productGroup) => new ProductGroup().deserialize(productGroup)));
                }
                else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
}
ProductGroupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductGroupService_Factory() { return new ProductGroupService(i0.ɵɵinject(i1.SocketService)); }, token: ProductGroupService, providedIn: "root" });
