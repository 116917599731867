<div class="tab-content">
    <form [formGroup]="form" (ngSubmit)="submit()" [ngClass]="{'form-submitted':submitted}">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold required">{{ 'form.lastname' | translate }}</label>
                    <input formControlName="lastname" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.firstname' | translate }}</label>
                    <input formControlName="firstname" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.gender' | translate }}</label>
                    <select formControlName="gender" class="form-control custom-select">
                        <option [ngValue]="1">{{ 'form.gender_man' | translate }}</option>
                        <option [ngValue]="2">{{ 'form.gender_woman' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.birthdate' | translate }}</label>
                    <input formControlName="birthdate" class="form-control" value="0000-00-00" type="date">
                </div>
            </div>
            <!--            <div class="col-12">-->
            <!--                <img src="http://beweb.projets.payet-consulting.com/panel/images/homme-defaut.jpg" alt=""-->
            <!--                     class="photo-active">-->
            <!--                <div class="form-group text-left form-group-photo">-->
            <!--                    <a href="" class="close-photo"><i class="fas fa-times"></i></a>-->
            <!--                    <label class="font-weight-bold">Photo</label>-->
            <!--                    <div class="custom-file" id="customFile">-->
            <!--                        <input class="form-control custom-file-input" id="exampleInputFile" aria-describedby="fileHelp"-->
            <!--                               type="file">-->
            <!--                        <label class="custom-file-label" for="exampleInputFile">-->
            <!--                            Télécharger une photo ...-->
            <!--                        </label>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.username' | translate }}</label>
                    <input formControlName="username" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.password' | translate }}</label>
                    <input formControlName="password" class="form-control" type="text">
                </div>
            </div>
            <div class="col-12">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.email' | translate }}</label>
                    <input formControlName="email" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">Skype</label>
                    <input formControlName="skype" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">Telegram</label>
                    <input formControlName="telegram" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.mobile_phone' | translate }}</label>
                    <input formControlName="phone" class="form-control" type="text">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.state' | translate }}</label>
                    <select formControlName="status" class="form-control custom-select">
                        <option [ngValue]="'active'">{{ 'form.state_activated' | translate }}</option>
                        <option [ngValue]="'inactive'">{{ 'form.state_deactivated' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12" formGroupName="rights">
                <div class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.account_type' | translate }}</label>
                    <div class="form-check form-check-inline">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="animator" type="checkbox" class="custom-control-input"
                                   id="animateur">
                            <label class="custom-control-label" for="animateur">{{ 'form.account_type_animator' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-check form-check-inline">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="moderator" type="checkbox" class="custom-control-input"
                                   id="moderateur">
                            <label class="custom-control-label" for="moderateur">{{ 'form.account_type_moderator' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-check form-check-inline" *ngIf="isAdmin()">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="manager" type="checkbox" class="custom-control-input"
                                   id="team-manager">
                            <label class="custom-control-label" for="team-manager">{{ 'form.account_type_manager' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-check form-check-inline" *ngIf="isAdmin()">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="admin" type="checkbox" class="custom-control-input" id="superadmin">
                            <label class="custom-control-label" for="superadmin">{{ 'form.account_type_admin' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div formArrayName="languages" class="form-group text-left">
                    <label class="font-weight-bold">{{ 'form.spoken_languages' | translate }}</label>
                    <div *ngFor="let language of getLanguages(); let i = index" class="form-check form-check-inline">
                        <div class="custom-control custom-checkbox">
                            <input [formControlName]="i" type="checkbox" class="custom-control-input"
                                   [id]="'language-' + language">
                            <label class="custom-control-label"
                                   [attr.for]="'language-' + language">{{ language }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="isAdmin()">
                <label class="font-weight-bold">{{ 'form.manager' | translate }}</label>
                <select formControlName="managerId" class="form-control custom-select">
                    <option [ngValue]="null">---</option>
                    <option *ngFor="let manager of getManagers()"
                            [ngValue]="manager.getId()">{{ manager.getName() }}</option>
                </select>
            </div>

            <div class="col-12 text-right mt-2">
                <button class="btn btn-primary p-3 text-white" type="submit">{{ 'form.save' | translate }}</button>
            </div>
        </div>
    </form>
</div>
