/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cover-photo.component.css.shim.ngstyle";
import * as i1 from "../moderation-type.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../ui/star-rating/star-rating.component.ngfactory";
import * as i5 from "../../../../ui/star-rating/star-rating.component";
import * as i6 from "./cover-photo.component";
import * as i7 from "../../../../../services/moderation/moderation.service";
import * as i8 from "../../../../../services/modal/modal.service";
import * as i9 from "@angular/material/dialog";
var styles_CoverPhotoComponent = [i0.styles, i1.styles];
var RenderType_CoverPhotoComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CoverPhotoComponent, data: {} });
export { RenderType_CoverPhotoComponent as RenderType_CoverPhotoComponent };
export function View_CoverPhotoComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "span", [["class", "badge badge-light"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(3, 0, null, null, 14, "div", [["class", "moderation-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "div", [["class", "photo-mod"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 2, "a", [["class", "btn btn-primary modif-photo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropPhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(9, 0, null, null, 3, "div", [["class", "photo-check"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 2, "a", [["class", "text-white"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i2.ɵted(11, null, [" ", " "])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(13, 0, null, null, 4, "div", [["class", "photo-rating"]], null, null, null, null, null)), (_l()(), i2.ɵted(14, null, [" ", " : "])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(16, 0, null, null, 1, "app-star-rating", [], null, [[null, "ratingChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ratingChanged" === en)) {
        var pd_0 = (_co.setRating($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_StarRatingComponent_0, i4.RenderType_StarRatingComponent)), i2.ɵdid(17, 114688, null, 0, i5.StarRatingComponent, [], { rating: [0, "rating"], count: [1, "count"] }, { ratingChanged: "ratingChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.getRating(); var currVal_7 = 5; _ck(_v, 17, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("moderation.cover_photo")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getPhotoSrc(); _ck(_v, 5, 0, currVal_1); var currVal_2 = i2.ɵunv(_v, 7, 0, i2.ɵnov(_v, 8).transform("moderation.crop")); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.getGoogleImageUrl(); _ck(_v, 10, 0, currVal_3); var currVal_4 = i2.ɵunv(_v, 11, 0, i2.ɵnov(_v, 12).transform("moderation.check_google_image_link")); _ck(_v, 11, 0, currVal_4); var currVal_5 = i2.ɵunv(_v, 14, 0, i2.ɵnov(_v, 15).transform("moderation.photo_rating")); _ck(_v, 14, 0, currVal_5); }); }
export function View_CoverPhotoComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CoverPhotoComponent_0, RenderType_CoverPhotoComponent)), i2.ɵdid(1, 49152, null, 0, i6.CoverPhotoComponent, [i7.ModerationService, i8.ModalService, i9.MatDialog], null, null)], null, null); }
var CoverPhotoComponentNgFactory = i2.ɵccf("ng-component", i6.CoverPhotoComponent, View_CoverPhotoComponent_Host_0, {}, {}, []);
export { CoverPhotoComponentNgFactory as CoverPhotoComponentNgFactory };
