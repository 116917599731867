import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

    @Input()
    dataSource;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

}
