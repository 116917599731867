import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Operator} from '../../models/operator/operator.model';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    operator: Operator = null;

    /**
     *
     * @param http HttpClient
     */
    constructor(
        private http: HttpClient
    ) {
    }

    public setModerator(moderator: boolean) {
        if (this.operator) {
            this.operator.setModerator(moderator);
        }
    }

    public setAnimator(animator: boolean) {
        if (this.operator) {
            this.operator.setAnimator(animator);
        }
    }

    /**
     * Check if the current operator have the role moderator
     */
    public isModerator(): boolean {
        return this.operator && this.operator.isModerator();
    }

    /**
     * Check if the current operator have the role animator
     */
    public isAnimator(): boolean {
        return this.operator && this.operator.isAnimator();
    }

    /**
     * Manager
     */
    public isManager(): boolean {
        return this.operator && this.operator.isManager();
    }

    /**
     * Is admin
     */
    public isAdmin(): boolean {
        return this.operator && this.operator.isAdmin();
    }

    public getCurrentUser(): Operator {
        return this.operator;
    }

    public setCurrentUser(user: Operator) {
        this.operator = user;
    }

    /**
     * Generate a new password for this user
     *
     * @param username string
     * @param email string
     */
    forgottenPassword(username: string, email: string) {

    }

    /**
     * Logout action from the panel
     */
    logout() {
        this.operator = null;
    }
}
