import * as i0 from "@angular/core";
export class ElapsedTimeService {
    constructor() {
        this.timers = [];
        setInterval(() => {
            this.refresh();
        }, 10000);
    }
    refresh() {
        for (const timer of this.timers) {
            timer.refresh();
        }
    }
    addTimer(timer) {
        this.timers.push(timer);
    }
    removeTimer(timer) {
        const i = this.timers.indexOf(timer);
        if (i) {
            this.timers.splice(i, 1);
        }
    }
}
ElapsedTimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElapsedTimeService_Factory() { return new ElapsedTimeService(); }, token: ElapsedTimeService, providedIn: "root" });
