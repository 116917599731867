import { ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ChatService } from './services/chat/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { StorageService } from './services/storage/storage.service';
// Sentry
if (environment.sentry.dsn) {
    Sentry.init({ dsn: environment.sentry.dsn });
}
// SocketIo Connexion
const config = {
    url: environment.chat.socket.url + ':' + environment.chat.socket.port,
    options: {
        origin: '*',
        reconnect: true,
        forceNew: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        autoConnect: true,
        secure: environment.chat.socket.secure,
        query: {
            chatVersion: ChatService.CHAT_VERSION.version,
            chatType: ChatService.CHAT_VERSION.type
        }
    }
};
// Add the library fas
// Now can be displayed inside view with => <fa-icon [icon]="['fas', 'heart']"></fa-icon>
library.add(fas);
const build = require('../build.json');
// loader module
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + build.version);
}
export class SentryErrorHandler {
    constructor() {
    }
    handleError(error) {
        if (environment.sentry.dsn) {
            Sentry.captureException(error.originalError || error, {
                tags: {
                    build
                }
            });
        }
        else {
            throw error;
        }
    }
}
const ɵ0 = () => new Promise(() => { });
export class AppModule {
    constructor(translate, storage) {
        this.translate = translate;
        this.storage = storage;
        const languages = ['de', 'en', 'es', 'fr', 'it', 'pt'];
        translate.addLangs(languages);
        translate.setDefaultLang('fr');
        translate.use(storage.get('language') || 'fr');
    }
}
export { ɵ0 };
