export const environment = {
    production: true,
    chat: {
        socket: {
            url: 'https://chatserver.meetaff.com',
            port: 3000,
            secure: true,
        }
    },
    imageTools: 'https://medias.meetaff.com/resize/',
    sentry: {
        dsn: 'https://bc2eef869699425183765fe184cc0daa@sentry.sopeople.net/38'
    }
};
