import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    /**
     * Set a cookie
     * @param name string
     * @param value string
     */
    set(name: string, value) {
        if (typeof(Storage) !== 'undefined') {
            window.localStorage.setItem(name, value);
        } else {
            const d = new Date();
            d.setTime(d.getTime() + 3600 * 24 * 1000);
            document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';path=/';
        }
    }

    /**
     * Get a cookie
     * @param name string
     */
    get(name: string): string {
        if (typeof(Storage) !== 'undefined') {
            return window.localStorage.getItem(name);
        } else {
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                const nameValue = cookie.trim().split('=');
                if (name === nameValue[0]) {
                    if (nameValue.length > 1) {
                        return nameValue[1];
                    } else {
                        return null;
                    }
                }
            }

            return null;
        }
    }

    /**
     * Delete a cookie
     * @param name string
     */
    delete(name: string) {
        if (typeof(Storage) !== 'undefined') {
            localStorage.removeItem(name);
        } else {
            const d = new Date();
            d.setTime(0);
            document.cookie = name + '=;expires=' + d.toUTCString() + ';path=/';
        }
    }
}
