import {Component, Input, OnInit} from '@angular/core';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
    selector: 'app-chat-emoji-list',
    templateUrl: './emoji-list.component.html',
    styleUrls: ['./emoji-list.component.css']
})
export class EmojiListComponent implements OnInit {

    /**
     * Date input from the animation component
     */
    @Input()
    messageForm;

    /**
     * Object of all emojis
     */
    emojiList;
    /**
     * Object of current category selected
     */
    emojiCategory;


    constructor(private chatService: ChatService) {

    }

    ngOnInit() {
        this.emojiList = this.chatService.getEmojis();

        const emojiKeyList = (this.emojiList !== null && typeof this.emojiList !== 'undefined') ? Object.keys(this.emojiList) : [];

        if (typeof emojiKeyList[0] !== 'undefined') {
            const emoji = this.emojiList[Object.keys(this.emojiList)[0]];

            if (typeof emoji !== 'undefined' && emoji.title !== 'undefined') {
                this.emojiCategory = emoji.title;
            }
        }

        document.body.onclick = () => {
            const panelTrigger = document.querySelector('.smileyPanelTrigger');
            const panelSmiley = document.querySelector('.smile');

            const isVisible = panelSmiley && panelSmiley.classList && panelSmiley.classList.contains('show');

            if (isVisible && typeof panelTrigger !== 'undefined' && typeof panelSmiley !== 'undefined') {

                // ajouter la classe "closing" pour indiqué qu'on a déjà cliqué
                // sinon, il clique indéfiniement
                if (!panelSmiley.classList.contains('closing')) {
                    panelSmiley.classList.add('closing');

                    const mouseClick = new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    });

                    // on simule le click sur le bouton :
                    panelTrigger.dispatchEvent(mouseClick);

                    // retirer la classe "closing" quand l'event est terminé
                    // tout cela pour éviter le bug de click infini...
                    setTimeout(() => {
                        panelSmiley.classList.remove('closing');
                    }, 0);
                }
            }
        };
    }

    /**
     * Push smiley to input message for chat
     * @param smiley string
     */
    pushSmiley(emoji) {
        let currentMessage = '';
        let emojiByBrowserSupport = this.chatService.getEmojiByBrowserSupport(emoji);

        if(emojiByBrowserSupport.match(/<img.*src=".*">/g)) {
            this.chatService.addEmojiReplaced(emoji, emojiByBrowserSupport);
        }
        if (this.messageForm.get('message').value !== null) {
            currentMessage = this.messageForm.get('message').value;
        }
        this.messageForm.get('message').setValue(currentMessage + emojiByBrowserSupport);
        this.chatService.switchEmojisShow();
    }

    switchEmojiCategory(category) {
        this.emojiCategory = category;
    }

    getEmojiByBrowserSupport(emoji) {
        return this.chatService.getEmojiByBrowserSupport(emoji);
    }

    keepOriginalOrder(a, b) {
        return a.key;
    }

    getTitle() {
        if (this.emojiCategory && this.emojiList[this.emojiCategory]) {
            return this.emojiList[this.emojiCategory].title;
        }

        return '';
    }

    getEmojis() {
        if (this.emojiCategory && this.emojiList[this.emojiCategory]) {
            return this.emojiList[this.emojiCategory].emojis;
        }

        return [];
    }
}
