import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io/src/socket-io.service";
import * as i2 from "../modal/modal.service";
import * as i3 from "@ngx-translate/core";
export class CalendarService {
    /**
     *
     * @param socket Socket
     * @param modalService ModalService
     * @param translate
     */
    constructor(socket, modalService, translate) {
        this.socket = socket;
        this.modalService = modalService;
        this.translate = translate;
        this.calendarsObservable = [];
        this.calendars = [];
        // Init first data
        this.initSocket();
    }
    initSocket() {
        this.socket.on('fake_profile_calendar', (datas) => this.onFakeProfileCalendar(datas));
    }
    /**
     * Socket Event Private Message
     */
    onFakeProfileCalendar(datas) {
        this.calendars = [];
        for (const calendar of datas.calendarList) {
            this.calendars.push(calendar);
        }
        this.switchToUser(datas.zmUserId, datas.fakeProfileId);
    }
    /**
     * Swith user
     *
     * @param zmUserId inside the discussion
     * @param fakeProfilId inside the discussion
     */
    switchToUser(zmUserId, fakeProfilId) {
        this.calendarsObservable.splice(0);
        this.permutCalendar(fakeProfilId, zmUserId);
    }
    /**
     * Get the calendar retated to the right coupe fakeProfil / zmUserProfil
     * @param fakeProfilId linked to the calendar
     * @param zmUserProfilId linked to the calendar
     */
    permutCalendar(fakeProfilId, zmUserProfilId) {
        this.calendars.forEach((item) => {
            this.calendarsObservable.push(item);
        });
    }
    /**
     * Update calendar event
     *
     * @param calendar values
     * @param index of the choosen element
     */
    updateCalendarEvent(calendar, index) {
        // update calendar event :
        if (typeof this.calendarsObservable[index] !== 'undefined') {
            const updatedCalendar = this.calendarsObservable[index];
            updatedCalendar.alert = calendar.alert === true ? 1 : 0;
            updatedCalendar.date = calendar.date;
            updatedCalendar.repeat = calendar.repeat;
            updatedCalendar.title = calendar.title;
            this.socket.emit('update_calendar_event', updatedCalendar);
            this.calendarsObservable[index] = updatedCalendar;
        }
    }
    /**
     * Add calendar event
     *
     * @param calendarEvent values
     * @param fakeProfilId linked to the calendar event
     * @param zmUserProfilId linked to the calendar event
     */
    addCalendarEvent(calendarEvent, fakeProfilId, zmUserProfilId) {
        calendarEvent.fakeProfileId = fakeProfilId;
        calendarEvent.zmUserId = zmUserProfilId;
        this.socket.emit('add_calendar_event', calendarEvent);
        this.calendarsObservable.push(calendarEvent);
    }
    /**
     * Get Calendar Event
     *
     * @param fakeProfilId linked to the calendar event
     * @param zmUserProfilId linked to the calendar event
     */
    getCalendarEvent(fakeProfilId, zmUserProfilId) {
        const calendarObservable = new Observable(observer => {
            observer.next(this.calendarsObservable);
        });
        return calendarObservable;
    }
    /**
     * Delete calendar event
     *
     * @param index of the element
     * @param eventId of the calendar
     */
    deleteCalendarEvent(index, eventId) {
        if (this.modalService.message(this.translate.instant('confirm_delete_event'))) {
            this.socket.emit('remove_calendar_event', eventId);
            this.calendarsObservable.splice(index, 1);
        }
    }
    /**
     * @param zmUserId any
     * @param fakeProfileId any
     */
    onChange(zmUserId, fakeProfileId) {
        this.socket.emit('retrieve_fake_profile_events', {
            fakeProfileId,
            userId: zmUserId
        });
    }
}
CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.WrappedSocket), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.TranslateService)); }, token: CalendarService, providedIn: "root" });
