import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ModalComponent} from '../../components/modal/modal.component';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

/**
 * Class to manage all modals
 */
export class ModalService {

    static readonly BUTTON_OK = {
        id: 'ok',
        title: 'ok',
        className: 'btn btn-success',
        icon: 'fa-check'
    };

    static readonly BUTTON_CANCEL = {
        id: 'cancel',
        title: 'cancel',
        className: 'btn btn-danger',
        icon: 'fa-times'
    };

    static readonly ICON_ERROR = {
        class: 'fa-times-circle icon-red'
    };

    constructor(
        public matDialog: MatDialog,
        private translationService: TranslateService
    ) {
    }

    message(message) {
        return confirm(message);
    }

    /**
     * Open Modalbox :
     * @param title
     * @param message
     * @param options
     * @param parameters
     */
    open(title: string, message: string, options: any, parameters: any): Observable<string> {
        return new Observable<string>((observer) => {
            const data: any = {
                title,
                message,
                parameters,
                buttons: [],
                icon: null
            };

            if (options.buttons) {
                data.buttons = options.buttons;
            }

            if (options.icon) {
                data.icon = options.icon;
            }

            const config: any = {
                disableClose: true,
                data
            };

            if (options.height) {
                config.height = options.height + 'px';
            }

            if (options.width) {
                config.width = options.width + 'px';
            }

            config.panelClass = 'modal-dialog';
            config.minWidth = '400px';

            const modalDialog = this.matDialog.open(ModalComponent, config);

            modalDialog.afterClosed().subscribe(result => {
                observer.next(result);
            });
        });
    }

    openOk(title: string, message: string, icon: object = null): Observable<string> {
        return this.open(title, message, {
            buttons: [
                this.getTranslatedButton(ModalService.BUTTON_OK)
            ],
            icon
        }, null);
    }

    openOkCancel(title: string, message: string, icon: object = null): Observable<string> {
        return this.open(title, message, {
            buttons: [
                this.getTranslatedButton(ModalService.BUTTON_OK),
                this.getTranslatedButton(ModalService.BUTTON_CANCEL)
            ],
            icon
        }, null);
    }

    openError(error: string): Observable<string> {

        const errorMessage: string = typeof error === 'string' ? error : JSON.stringify(error);

        return this.open(
            this.translationService.instant('modal.error.title'),
            this.translationService.instant('modal.error.message') + '\n\n' + errorMessage,
            {
                buttons: [
                    this.getTranslatedButton(ModalService.BUTTON_OK)
                ],
                icon: ModalService.ICON_ERROR
            },
            null
        );
    }

    getTranslatedButton(button: any) {
        return {
            id: button.id,
            title: this.translationService.instant('modal.' + button.title),
            className: button.className,
            icon: button.icon
        };
    }
}
