<ul class="connectes" (scroll)="onScrollUsers()" (mouseleave)="hoveringAbove = null">

    <li *ngFor="let user of getUsers(); let i = index;"
        (click)="selectUser(user)"
        (mouseenter)="hoveringAbove = user.getId()"
        [class.active]="isSelectedUser(user)"
        [attr.data-userid]=user.getId() class="position-relative">
        <div [class.active]="isSelectedUser(user)" class="utilisateur d-flex align-items-center">

            <span *ngIf="hoveringAbove !== user.getId() && !user.isSubscribed() && user.getUnansweredRooms() && user.getTimeLimitToAnswer() !== null"
                  [ngClass]="user.getClassLimitToAnswer()"
                  class="badge badge-time-limit">
                <i class="fas fa-clock mr-1"></i> {{ user.getTimeLimitToAnswer() }}
            </span>

            <div class="users-img users-img-2">
                <img [src]="user | userImage: '64x64'"/>
                <span [class.bg-success]="user.isConnected()" [class.bg-secondary]="!user.isConnected()" class="fa-stack"></span>
            </div>
            <div class="users-detail text-truncate">
                <p>
                    {{ user.username| titlecase }}<i [ngClass]="user.getGenderClass()" class="pl-2"></i>
                    <span [ngClass]="user.getProduct().getSegment().getName().toLowerCase()" class="d-inline-block segment-name">
                        <span class="text-uppercase">{{ user.getProduct().getSegment().getName() }}</span>
                    </span>
                </p>
                <small>
                    <ng-container *ngIf="!user.isSubscribed()">
                        <ng-container *ngIf="user.getFrustrationRule() && user.getFrustrationRule().isSubscription() && user.getFrustrationRule().getDeferred() && !isFrustrated(user)">
                                <span [matTooltip]="'Temps restant avant blocage'">
                                    <i class="far fa-clock mr-1"></i>{{ user.getFrustrationRule().getDeferred().getTimeout() | timer: false }}
                                </span>
                            <span [matTooltip]="'Nombre de messages à envoyer autorisé'" class="position-relative">
                                    <i class="fas fa-arrow-up small"></i>
                                    <i class="fas fa-envelope ml-2"></i>
                                {{ user.getFrustrationRule().getDeferred().getMessageSentLeft() }}
                                </span>
                            <span [matTooltip]="'Nombre de messages à recevoir autorisé'" class="position-relative">
                                    <i class="fas fa-arrow-down small"></i>
                                    <i class="fas fa-envelope ml-2"></i>
                                {{ user.getFrustrationRule().getDeferred().getMessageReceivedLeft() }}
                                </span>
                        </ng-container>

                        <span *ngIf="isFrustrated(user)" class="text-danger"><i class="fas fa-user-lock"></i> Frustrated</span>
                    </ng-container>
                    <span [matTooltip]="'Mode'">
                            <i class="fas fa-lock ml-2"></i>
                        {{ (user.getFrustrationRule() && user.getFrustrationRule().isCoin()) ? 'COIN' : 'SUBS' }}
                        </span>
                    <span [matTooltip]="'Coins disponibles sur le compte'"><i class="fas fa-coins ml-2 mr-1"></i>{{ user.getCoins() }}</span>
                </small>
            </div>

            <ng-container *ngIf="hoveringAbove !== user.getId()">
                <div class="time-cnx">{{ getDate(user) }}</div>
                <span *ngIf="user.getUnansweredRooms()" class="rep">{{ user.getUnansweredRooms() }}</span>
                <span *ngIf="user.getUnreadMessages()" class="mess bg-warning">{{ user.getUnreadMessages() }}</span>
            </ng-container>

            <div *ngIf="hoveringAbove === user.getId()" class="btn-action">
                <button class="btn btn-primary text-white action" (click)="openModal(user, 'profil')" [title]="'button.display' | translate"><i class="fa fa-user"></i></button>
                <button class="btn btn-primary text-white action" (click)="openModal(user, 'alert')" [title]="'button.warning' | translate"><i class="fa fa-flag"></i></button>
                <button class="btn btn-primary text-white action" (click)="openModal(user, 'suspendre')" [title]="'button.stop'"><i class="far fa-clock"></i></button>
                <button class="btn btn-primary text-white action" (click)="openModal(user, 'bannir')" [title]="'button.bannish'"><i class="fa fa-ban"></i></button>
                <button class="btn btn-primary text-white action" (click)="openModal(user, 'ghost')" [title]="'button.ghost_user'" ><i class="fab fa-snapchat-ghost"></i></button>
            </div>
        </div>

        <ul *ngIf="isSelectedUser(user) && getFakeRooms().length">
            <li *ngFor="let room of getFakeRooms()" (click)="selectFakeRoom(room)" [class.active]="isSelectedRoom(room)" class="arbo">
                <div class="utilisateur d-flex align-items-center">
                    <div class="users-img">
                        <img [src]="room.getMe() | userImage: '64x64'"/>
                        <div class="users-loading" *ngIf="!room.getMe().loaded"><i class="fa fa-4x fa-spin fa-spinner"></i></div>
                    </div>
                    <div class="users-detail text-truncate">
                        <p>{{ room.getMe().getUsername() | titlecase }}<i [ngClass]="room.getMe().getGenderClass()" class="pl-2"></i><br>
                            <small><span class="ng-star-inserted">{{ room.getLastMessage() | maxStringSize: 45 }}</span></small>
                        </p>
                        <div class="time-cnx">{{ getDate(room) }}</div>
                        <span *ngIf="!room.isAnswered()" class="rep"><i class="fas fa-comment-dots"></i></span>
                        <span *ngIf="room.unreadMessage " class="mess bg-warning">{{ room.unreadMessage }}</span>
                    </div>
                </div>
            </li>
            <li *ngIf="canLoadMoreRooms()" class="arbo p-0">
                <a (click)="getMoreRooms()" class="btn btn-dark btn-block text-center btn-more-rooms">{{ 'chat.room_list.other' | translate }}</a>
            </li>
        </ul>
    </li>

    <li class="text-center no-hover-bg" *ngIf="showNewUserLoaded()">{{ writeNewRoomNb() }}</li>

    <li class="text-center" *ngIf="!showNewUserLoaded()">
        <i *ngIf="isLoadingUsers()" class="fa fa-2x fa-spin fa-spinner"></i>
        <span *ngIf="!isLoadingUsers() && hasMaxUsers() && canReceiveNotifications()">{{ 'chat.room_list.maximum' | translate }}</span>
        <span *ngIf="!canReceiveNotifications()">{{ 'chat.room_list.inactive' | translate }}</span>
        <span *ngIf="!isLoadingUsers() && !hasMaxUsers() && canReceiveNotifications()"
              (click)="getMoreUsers()"
              class="cursor-pointer">{{ 'chat.room_list.users' | translate }} <span *ngIf="additionalSearchDay > 1">({{ additionalSearchDay }} derniers jours)</span></span>
    </li>
</ul>
