import {Deserializable} from '../deserialize/deserializable.model';
import {UserRight} from '../user-right/user-right';
import {ProductGroup} from '../product/product-group';

/**
 * Operator
 */
export class Operator implements Deserializable {
    static readonly STATUS_ACTIVE = 'active';
    static readonly STATUS_INACTIVE = 'inactive';

    static readonly GENDER_MAN = 1;
    static readonly GENDER_WOMEN = 2;

    /**
     * Id
     */
    id: number = null;

    /**
     * Username
     */
    username: string = null;

    /**
     * Firstname
     */
    private firstname: string = null;

    /**
     * Lastname
     * @private
     */
    private lastname: string = null;

    /**
     * Email
     * @private
     */
    private email: string = null;

    /**
     * Password
     * @private
     */
    private password: string = null;

    /**
     * Authorized IPs
     */
    private ips: string[] = [];

    /**
     * Languages
     */
    private languages: string[] = [];

    /**
     * Countries
     * @private
     */
    private countries: string[] = [];

    /**
     * Rights
     */
    rights: UserRight = new UserRight();

    /**
     * Attached product groups
     */
    private productGroups: number[] = [];

    /**
     * Status
     */
    private status: string = null;

    /**
     * Last connection date & time
     * @private
     */
    private lastConnection: Date = null;

    /**
     * Skype
     */
    private skype: string = null;

    /**
     * Telegram
     */
    private telegram: string = null;

    /**
     * Is connected ?
     */
    connected = false;

    /**
     * Gender
     * @private
     */
    private gender: number = null;

    /**
     * Birth date YYYY-MM-DD
     * @private
     */
    private birthdate: string = null;

    /**
     * Phone
     * @private
     */
    private phone: string = null;

    /**
     * Manager
     * @private
     */
    private managerId: number = null;

    constructor() {
    }

    deserialize(data: any): this {
        Object.assign(this, data);

        this.rights = new UserRight();

        if (data.lastConnection) {
            this.lastConnection = new Date(data.lastConnection);
        }

        if (data.rights) {
            this.rights.deserialize(data.rights);
        }

        if (Array.isArray(data.languages)) {
            this.languages = data.languages;
        } else {
            this.languages = [];
        }

        if (Array.isArray(data.productGroups)) {
            this.productGroups = data.productGroups;
        } else {
            this.productGroups = [];
        }

        return this;
    }

    getId(): number {
        return this.id;
    }

    setAdmin(admin: boolean) {
        this.rights.admin = admin;
    }

    isAdmin(): boolean {
        return !!this.rights.admin;
    }

    setModerator(moderator: boolean) {
        this.rights.moderator = moderator;
    }

    isModerator(): boolean {
        return !!this.rights.moderator;
    }

    setAnimator(animator: boolean) {
        this.rights.animator = animator;
    }

    isAnimator(): boolean {
        return !!this.rights.animator;
    }

    isManager(): boolean {
        return !!this.rights.manager;
    }

    isSupport(): boolean {
        return false;
    }

    setFirstname(firstname: string) {
        this.firstname = firstname;
    }

    getFirstname(): string {
        return this.firstname;
    }

    setLastname(lastname: string) {
        this.lastname = lastname;
    }

    getLastname(): string {
        return this.lastname;
    }

    setUsername(username: string) {
        this.username = username;
    }

    getUsername(): string {
        return this.username;
    }

    setEmail(email: string) {
        this.email = email;
    }

    getEmail(): string {
        return this.email;
    }

    setPassword(password: string) {
        this.password = password;
    }

    setProductGroups(productGroups: number[]) {
        this.productGroups = productGroups;
    }

    getProductGroups(): number[] {
        return this.productGroups;
    }

    setStatus(status: string) {
        this.status = status;
    }

    getStatus(): string {
        return this.status;
    }

    isActive(): boolean {
        return this.status === Operator.STATUS_ACTIVE;
    }

    isInactive(): boolean {
        return this.status === Operator.STATUS_INACTIVE;
    }

    isConnected(): boolean {
        return this.connected;
    }

    getLastConnection(): Date {
        return this.lastConnection;
    }

    setGender(gender: number) {
        this.gender = gender;
    }

    getGender(): number {
        return this.gender;
    }

    setBirthdate(birthdate: string) {
        this.birthdate = birthdate;
    }

    getBirthdate(): string {
        return this.birthdate;
    }

    setPhone(phone: string) {
        this.phone = phone;
    }

    getPhone(): string {
        return this.phone;
    }

    setLanguages(languages: string[]) {
        this.languages = languages;
    }

    getLanguages(): string[] {
        return this.languages;
    }

    getName(): string {
        return (this.lastname + ' ' + this.firstname).trim();
    }

    setManagerId(managerId: number) {
        this.managerId = managerId;
    }

    getManagerId(): number {
        return this.managerId;
    }

    setSkype(skype: string) {
        this.skype = skype;
    }

    setTelegram(telegram: string) {
        this.telegram = telegram;
    }

    setRights(rights: UserRight) {
        this.rights = rights;
    }
}
