/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./moderation-type.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../helpers/directive/moderation.directive";
import * as i3 from "./moderation-type.component";
import * as i4 from "../../../../services/moderation/moderation.service";
var styles_ModerationTypeComponent = [i0.styles];
var RenderType_ModerationTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModerationTypeComponent, data: {} });
export { RenderType_ModerationTypeComponent as RenderType_ModerationTypeComponent };
function View_ModerationTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ModerationTypeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { appModerationType: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModerationTypeComponent_1)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.ModerationDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_ModerationTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-moderation-type", [], null, null, null, View_ModerationTypeComponent_0, RenderType_ModerationTypeComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModerationTypeComponent, [i4.ModerationService, i1.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModerationTypeComponentNgFactory = i1.ɵccf("app-moderation-type", i3.ModerationTypeComponent, View_ModerationTypeComponent_Host_0, { moderation: "moderation" }, { loadingChanged: "loadingChanged" }, []);
export { ModerationTypeComponentNgFactory as ModerationTypeComponentNgFactory };
