import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Operator} from '../../../../../models/operator/operator.model';
import {OperatorService} from '../../../../../services/operator/operator.service';
import {ModalService} from '../../../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-operator-edit',
    templateUrl: './operator-edit.component.html',
    styleUrls: ['./operator-edit.component.css']
})
export class OperatorEditComponent implements OnInit {

    /**
     * Operator
     */
    operator: Operator = null;

    /**
     * Loading
     */
    loading = false;

    /**
     * Managers
     */
    managers: Operator[] = [];

    /**
     * Active tab
     */
    activeTab = 'account';

    constructor(
        private activatedRoute: ActivatedRoute,
        private operatorService: OperatorService,
        private modalService: ModalService,
        private router: Router,
        private translationService: TranslateService
    ) {
    }

    ngOnInit() {
        this.loading = true;

        const operatorId = +this.activatedRoute.snapshot.paramMap.get('id');
        this.operatorService.getOperatorById(operatorId).subscribe((operator: Operator) => {
            this.operator = operator;

            if (operator === null) {
                this.loading = false;
                this.modalService.openError('operator_not_found').subscribe(() => {
                    this.router.navigate(['/operator']);
                    return;
                });
            }

            this.operatorService.getOperators({manager: true}).subscribe((managers: Operator[]) => {
                this.loading = false;
                this.managers = managers;
            });
        });
    }

    save(operator: Operator) {
        this.loading = true;

        this.operatorService.updateOperator(operator).subscribe(() => {
            this.loading = false;
            this.modalService.openOk(
                this.translationService.instant('operator.saved_title'),
                this.translationService.instant('operator.saved_message')
            ).subscribe(() => {
            });
        }, (error) => {
            this.loading = false;
            this.modalService.openError(error).subscribe();
        });
    }
}
