<div class="modal-container">
    <h1 mat-dialog-title class="title-left">
        {{data.title}}
        <span class="fa fa-times-circle modal-close" mat-dialog-close="close"></span>
    </h1>

    <div mat-dialog-content>
        <div class="content-icon" *ngIf="data.icon">
            <i class="fas fa-3x" [ngClass]="data.icon.class"></i>
        </div>
        <div class="content-left">
            {{data.message}}
        </div>
    </div>
    <mat-dialog-actions class="action-button">
        <button class="{{button.className}}" *ngFor="let button of data.buttons"
                mat-dialog-close="{{button.id}}">
            <i class="fas" *ngIf="button.icon" [ngClass]="button.icon"></i>
            {{button.title}}
        </button>
    </mat-dialog-actions>
</div>
