import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from './models/user/user';
import {Router} from '@angular/router';
import {ChatService} from './services/chat/chat.service';
import {UserManagementService} from './services/user/user-management.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    title = 'frontend';

    public values: any[];

    constructor(
        private router: Router,
        private chatService: ChatService,
        private userManagementService: UserManagementService
        ) {
    }

    ngOnDestroy() {
        localStorage.setItem('ConnectedUserList', '');
    }

    isConnected(): boolean {
        return this.userManagementService.getCurrentUser() && this.chatService.isLogged();
    }
}
