<div mat-dialog-content>
    <div class="modal-header">
        <h5 class="mb-0 font-weight-bold"><i class="fa fa-flag"></i> {{ 'title.warning' | translate }} </h5>
    </div>

    <div class="position-relative" style="min-height:450px;">
        <div class="table-responsive">
            <table class="table table-dark table-hover">
                <thead class="">
                <tr>
                    <th scope="col">{{ 'header.user_alerts.informations' | translate }}</th>
                    <th scope="col" style="width:120px;">{{ 'date' | translate }}</th>
                    <th scope="col" style="width:80px;" class="text-center">{{ 'header.user_alerts.action' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let alert of userAlerts | paginate: { itemsPerPage: count, currentPage: p }; let i = index">
                    <td>{{ alert.title }} <span *ngIf="alert.isManaged()"
                                                    class="text-success">{{ 'header.user_alerts.alert_ok' | translate }}</span></td>
                    <td>{{ alert.date | date:'le dd/MM/yyyy' }}</td>

                    <td class="text-center">
                        <button [ngClass]="alert.isManaged() ? 'disabled' : ''"
                                [disabled]='alert.isManaged()' (click)="manageAlert(alert)"
                                class="btn btn-primary action text-white mr-1"
                                title="{{ 'header.user_alerts.go' | translate }}"><i
                                class="fa fa-user-plus"></i></button>
                        <button (click)="removeAlert(alert)" class="btn btn-primary action text-white"
                                title="{{ 'button.delete' | translate }}"><i
                                class="fa fa-times-circle"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example" style="text-align:right;position:absolute;bottom: 0;right:0;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </nav>
        </div>
    </div>
</div>
