import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder} from '@angular/forms';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-picture-truncate-modal',
    templateUrl: './truncate-picture-modal.component.html',
    styleUrls: ['./truncate-picture-modal.component.css']
})
export class TruncatePictureModalComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    croppedImageBase64: any = '';
    username: string;

    @ViewChild(ImageCropperComponent, {static: true}) imageCropper: ImageCropperComponent;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private dialogRef: MatDialogRef<TruncatePictureModalComponent>,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        this.username = this.data.username;

        this.toDataUrl(this.data.picture, (myBase64) => {
                this.croppedImageBase64 = myBase64;
            }
        );
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    rotateRight() {
        this.imageCropper.rotateRight();
    }

    rotateLeft() {
        this.imageCropper.rotateLeft();
    }

    saveCrop() {
        // Get the new image sent to the backend for save
        // Todo Waiting for integration

        // Close the dialog box
        this.dialogRef.close({newCroppedPictureB64: this.croppedImage});

        // Display the toaster
        this.toastrService.success('La photo du profil ' + this.username + ' a été recadrée avec succès', 'Moderation - Recadrage Photo');
    }

    getDataUri(url, callback) {
        const image = new Image();

        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
            canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

            canvas.getContext('2d').drawImage(image, 0, 0);

            // Get raw image data
            callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

            // ... or get as Data URI
            callback(canvas.toDataURL('image/png'));
        };

        image.src = url;
    }

    toDataUrl(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}
