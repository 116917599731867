import {Photo} from '../../../../models/photo/photo';
import {CropPhotoComponent} from '../modal/crop-photo/crop-photo.component';
import {ApiResponse} from '../../../../api/response/api-response';
import {ModerationService} from '../../../../services/moderation/moderation.service';
import {MatDialog} from '@angular/material';
import {ModalService} from '../../../../services/modal/modal.service';
import {DynamicModerationTypeComponent} from './dynamic-moderation-type.component';
import {AbstractModeration} from '../../../../models/moderation/abstract-moderation';
import {EventEmitter} from '@angular/core';

/**
 * PhotoModerationTypeComponent
 */
export abstract class PhotoModerationTypeComponent implements DynamicModerationTypeComponent {
    /**
     * Loading event
     */
    loadingChanged: EventEmitter<boolean> = null;

    /**
     * Photo
     */
    photo: Photo = null;

    /**
     * New photo
     */
    newPhoto: Photo = new Photo();

    /**
     * Reject buttons
     */
    rejectButtons = [
        {
            action: 'rejected',
            value: 'ko',
            icon: 'fas fa-times'
        }
    ];

    protected constructor(
        protected moderationService: ModerationService,
        protected modalService: ModalService,
        protected matDialog: MatDialog
    ) {
    }

    getPhotoId(): number {
        return this.photo.getId();
    }

    setLoadingChanged(event: EventEmitter<boolean>) {
        this.loadingChanged = event;
    }

    setLoading(loading: boolean) {
        this.loadingChanged.emit(loading);
    }

    cropPhoto() {
        /**
         * Load current photo
         */
        this.setLoading(true);
        this.moderationService.loadPhoto(this.getPhotoId()).subscribe((photo: Photo) => {
            this.setLoading(false);

            /**
             * Open crop dialog
             */
            const cropPhotoDialog = this.matDialog.open(CropPhotoComponent, {
                width: (500 + this.getAspectRatio() * 80) + 'px',
                panelClass: 'modal-dialog',
                data: {
                    photo,
                    aspectRatio: this.getAspectRatio()
                }
            });

            cropPhotoDialog.afterClosed().subscribe((result: any) => {
                if (result && result.photo) {
                    this.newPhoto.setBase64(result.photo.getBase64());
                }
            });
        }, (error) => {
            this.setLoading(false);
            this.modalService.openError(error).subscribe();
        });
    }

    setModeration(moderation: AbstractModeration) {
        throw new Error('Method not implemented.');
    }

    accept(): string[] {
        return [];
    }

    reject(action: string, reasons: string[]): string[] {
        return [];
    }

    getRejectButtons(): object[] {
        return this.rejectButtons;
    }

    setRating(event: any) {
        this.newPhoto.setRating(event.rating);
    }

    getRating(): number {
        return this.newPhoto.getRating();
    }

    getPhotoSrc(): string {
        if (this.newPhoto.getBase64()) {
            return this.newPhoto.getBase64();
        }

        return this.photo.getUrl();
    }

    getAspectRatio(): number {
        return 1;
    }

    getGoogleImageUrl(): string {
        return 'https://images.google.com/searchbyimage?image_url=' + this.photo.getUrl();
    }
}
