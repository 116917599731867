import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../api/response/api-response';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io/src/socket-io.service";
export class SocketService {
    constructor(socket) {
        this.socket = socket;
    }
    /**
     * Send API Request and return an API Response
     * @param event
     * @param body
     */
    sendApiRequest(event, body) {
        return new Observable((observer) => {
            this.socket.emit(event, body, (data) => {
                observer.next(new ApiResponse().deserialize(data));
            });
        });
    }
}
SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.WrappedSocket)); }, token: SocketService, providedIn: "root" });
