<div class="container-fluid main-section">
    <div class="row">
        <!-- Left column -->
        <div class="col-md-3 col-sm-6 col-12 sidebar discussions" id="sidebar-left">
            <ul class="nav nav-tabs tabs" role="tablist">
                <li class="nav-item w-50 text-center">
                    <a (click)="leftTab = NOT_SUBSCRIBED" [class.active]="!isSubscriptionTab()" class="nav-link">
                        {{ 'discussions' | translate }} - {{ 'not_subscribed_users' | translate }} ({{ getAnimationUsers(NOT_SUBSCRIBED).length }})
                    </a>
                </li>
                <li class="nav-item w-50 text-center">
                    <a (click)="leftTab = SUBSCRIBED" [class.active]="isSubscriptionTab()" class="nav-link">
                        {{ 'discussions' | translate }} - {{ 'subscribed_users' | translate }} ({{ getAnimationUsers(SUBSCRIBED).length }})
                    </a>
                </li>
            </ul>

            <!-- Users without subscription -->
            <div class="mt-0 room-list">
                <div *ngIf="!hasAnimationUsers()" class="utilisateur">
                    <div class="text-center mt-2 text-truncate">
                        {{ 'animation.none.animate' | translate }}
                    </div>
                </div>

                <div class="room-list-scroll">
                    <app-animation-list [subscribed]="isSubscriptionTab()"></app-animation-list>
                </div>
            </div>
        </div>
        <!-- Left column -->

        <!-- Chat room (middle column) -->
        <div class="col-md-6 col-sm-6 col-12 chatroom">
            <app-chat-room [room]="getCurrentRoom()"></app-chat-room>
        </div>
        <!-- Chat room -->

        <!-- Right Column -->
        <div class="col-md-3 col-sm-6 col-12 sidebar discussions" id="sidebar-right">
            <div class="mt-0 right-room-list">
                <ul class="nav nav-tabs tabs" role="tablist">
                    <li class="nav-item w-50 text-center">
                        <a (click)="rightTab = MODERATION" [class.active]="isModerationTab()" class="nav-link">
                            {{ 'title.moderate' | translate }} ({{ getRealRooms().length }})
                        </a>
                    </li>
                    <li class="nav-item w-50 text-center">
                        <a (click)="rightTab = USERS" [class.active]="!isModerationTab()" class="nav-link">
                            {{ 'title.connected_user' | translate }}
                        </a>
                    </li>
                </ul>

                <div *ngIf="isModerationTab() && !hasRealRooms()" class="utilisateur">
                    <div class="text-center mt-2 text-truncate">
                        {{ 'animation.none.moderate' | translate }}
                    </div>
                </div>

                <div class="h-100">
                    <!-- Users to moderate -->
                    <app-room-list *ngIf="isModerationTab() && hasRealRooms()"></app-room-list>

                    <!-- User list -->
                    <app-chat-user-list *ngIf="!isModerationTab()"></app-chat-user-list>
                </div>
            </div>

            <div class="mt-0 block-note d-flex">
                <app-animation-note *ngIf="getCurrentRoom() && getCurrentRoom().getMe()" [list]="getCurrentRoom().getNotes()" [roomId]="getCurrentRoom().getId()"></app-animation-note>
            </div>
        </div>
        <!-- Right Column -->
    </div>
</div>
