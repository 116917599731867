import {Inject, Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {User} from '../../models/user/user';
import {Room} from '../../models/room/room';
import {Message} from '../../models/message/message';
import {NotificationService} from '../notification/notification.service';
import {UserManagementService} from '../user/user-management.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UserAlertService} from '../user-alert/user-alert.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import * as emojisData from '../../../assets/chat/emojis.json';
import {TranslateService} from '@ngx-translate/core';
import {Operator} from '../../models/operator/operator.model';
import {ModerationService} from '../moderation/moderation.service';
import {AbstractModeration} from '../../models/moderation/abstract-moderation';
import {ApiResponse} from '../../api/response/api-response';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    public constructor(
        private socket: Socket,
        private notificationService: NotificationService,
        private userManagementService: UserManagementService,
        private router: Router,
        private toastr: ToastrService,
        private userAlertService: UserAlertService,
        private translate: TranslateService,
        private moderationService: ModerationService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.window = this.document.defaultView;
        this.moderationService.setChatService(this);

        this.loadEmojis();

        this.initSocket();

        this.startUserLoaderManager();
    }

    public static readonly CHAT_VERSION = {
        version: '1.0.8',
        type: 'panel'
    };

    /**
     * Constants sent from the chat server
     * min: we stop removing rooms
     * max: we stop loading rooms
     */
    roomsConfig;

    animationConfig;

    animationUsers: User[] = [];

    freemiumUsers: User[] = [];

    subscribedUsers: User[] = [];

    /**
     * All rooms list
     */
    privateRooms: Room[] = [];

    /**
     * Rooms with fake users
     */
    fakeRooms: Room[] = [];

    /**
     * Rooms with real users
     */
    realRooms: Room[] = [];

    /**
     * Rooms in loading
     */
    loadingRooms = [];

    /**
     * UserMessage
     */
    userMessage = [];

    /**
     * List of all users except the operator connected to the chat
     */
    connectedUsers = [];

    /**
     * List of all users connected to the chat
     */
    users: User[] = [];

    /**
     * List of operator's username joined by a comma
     * Used in header.component.html by a tooltip
     */
    operatorUsernameList = '';

    /**
     * List of FakeProfils
     */
    fakeUsers: User[] = [];

    /**
     * Operator users informations
     */
    me: Operator;

    /**
     * Emoji list
     */
    emojis = [];

    /**
     * Emoji show
     */
    emojisShow = false;

    /**
     * List of opened Room;
     */
    openedRooms = [];

    /**
     * Current Couple ZmUserId / FakeUserId
     */
    userDetails$: Subject<any> = new BehaviorSubject<any>({});

    /**
     * User logged to chat
     */
    logged = false;

    /**
     * Message observable
     */
    messages: Subject<Message> = new Subject<Message>();

    /**
     * Message in loading state
     */
    loadingMessages = [];

    /**
     * user loader timer
     */
    userLoaderTimer: number = null;

    currentSelectedRoom: Room;

    currentSelectedUser: User;

    /**
     * Store the timeout id of each room which should be removed due to inactivity
     * roomId: {
     *     timeoutId: timeoutId for clearTimeout
     *     readyToBeRemoved: boolean
     * }
     */
    inactiveRooms = {};

    /**
     * Notifications status : if true, operator can receive new conversations.
     * If false, operator can manage current conversations only (not new conversation)
     */
    notificationsStatus = true;

    /**
     * Instruct the number of room to get in case of moreConversations()
     */
    moderationRoomNeeded = 50;
    animationUserNeeded = 50;

    additionalSearchDay = 0;

    private window: any;

    private emojisReplaced = [];

    private fetchingMetaData = false;

    private static sortByLastMessageTime(a, b) {
        const timeA = a.lastMessageTime || new Date();
        const timeB = b.lastMessageTime || new Date();

        return timeB.getTime() - timeA.getTime();
    }

    /**
     * Init all socket event
     */
    initSocket() {
        this.socket.on('user_disconnected', user => this.onUserDisconnected(user));
        this.socket.on('users', users => this.onUsers(users));
        this.socket.on('private_rooms', data => this.onPrivateRooms(data, false));
        this.socket.on('animation_users', data => this.onAnimationUsers(data, false));
        this.socket.on('more_animation_users', data => this.onAnimationUsers(data, true));
        this.socket.on('more_private_rooms', data => this.onPrivateRooms(data, true));
        this.socket.on('logout', message => this.onLogout(message));
        this.socket.on('disconnect', event => this.onDisconnect(event));
        this.socket.on('notify_user_typing', data => this.onNotifyUserTyping(data));
        this.socket.on('fake_profiles_available', data => this.onFakeProfilesAvailable(data));
        this.socket.on('private_message', message => this.onPrivateMessage(message));
        this.socket.on('moderations', moderations => this.onModerations(moderations));
        this.socket.on('update_user', user => this.onUserUpdate(user));
    }

    /**
     * reset and clean all datas
     */
    reset() {
        this.messages = new Subject<Message>();
        this.currentSelectedRoom = null;
        this.users = [];
        this.connectedUsers = [];
        this.fakeRooms = [];
        this.fakeUsers = [];
        this.openedRooms = [];
        this.inactiveRooms = [];
        this.me = null;
        this.privateRooms = [];
        this.realRooms = [];
        this.loadingRooms = [];
        this.animationUsers = [];
    }

    /**
     * Get message to display
     */
    public getMessageToDisplay() {
        return this.userMessage;
    }

    /**
     * Load Emojis from JSON file
     */
    loadEmojis() {
        this.emojis = (emojisData as any).default;
    }

    getEmojis() {
        return this.emojis;
    }

    getFreemiumUsers(): User[] {
        return this.freemiumUsers;
    }

    getSubscribedUsers(): User[] {
        return this.subscribedUsers;
    }

    getFakeRooms(): Room[] {
        return this.fakeRooms;
    }

    getRealRooms(): Room[] {
        return this.realRooms;
    }

    /**
     * Ask private message to server
     * @param room Current room
     * @param user Fake profile
     * @param start first message index
     * @param count number of messages
     */
    getPrivateRoomMessages(room: Room, user: User, start: number, count: number): Observable<Message[]> {
        return new Observable<Message[]>((observer) => {
            this.socket.emit('private_room_get_messages', {
                roomId: room.getId(),
                userId: user.getId(),
                joinNotes: true,
                start,
                count
            }, data => {
                room.setLoaded(true);
                room.setNotes(data.notes);

                const messages = [];

                for (const messageData of data.messages) {
                    const message = this.addMessageToRoomFromData(messageData, room);
                    messages.push(message);
                }

                observer.next(messages);
            });
        });
    }

    /**
     * Action to send a new message
     * @param recipientId User profile Id
     * @param fakeProfileId Fake profile Id
     * @param message Message
     */
    sendMessage(recipientId, fakeProfileId, message: string) {
        if (message && message.length > 0) {
            this.socket.emit('message', {
                recipientId,
                fakeProfileId,
                message: message.trim()
            }, (response) => {
                if (!response.success && response.errorMessage) {
                    this.toastr.error(this.translate.instant('error_send_message') + ' ' + response.errorMessage);
                }
            });
        }
    }

    /**
     * Remove user from connected list
     * @param userId number
     */
    removeFromConnectedUser(userId: number) {
        // Remove from all users
        const indexConnected = this.connectedUsers.findIndex(data => data.id === userId);
        if (indexConnected !== -1) {
            this.connectedUsers.splice(indexConnected, 1);
        }

        // Remove from all fake users
        const indexFake = this.fakeUsers.findIndex(data => data.id === userId);
        if (indexFake !== -1) {
            this.fakeUsers.splice(indexFake, 1);
        }
    }

    /**
     * Add user to connected list
     * @param user User
     */
    addConnectedUser(user: User) {
        if (!this.connectedUsers.find(userEntity => userEntity.id === user.id)) {
            this.connectedUsers.push(user);
        }
    }

    /**
     * Add fake user to list
     * @param userFake User
     */
    addUserFake(userFake: User) {
        if (!this.fakeUsers.find(userEntity => userEntity.id === userFake.id)) {
            this.fakeUsers.push(userFake);
        }
    }

    /**
     * Add user to list
     * @param user User
     */
    addUser(user: User) {
        if (!this.users.find(userEntity => userEntity.id === user.id)) {
            this.users.push(user);
        }
    }

    /**
     * Get user from list of all user by id
     * @param id number
     */
    getUserById(id) {
        const u = this.users.find(user => user.id === id);
        return u ? u : null;
    }

    /**
     * Logout user from app, and forward to the login page
     */
    logoutUserAndRedirectToLogin() {
        this.logged = false;
        this.userManagementService.logout();
        this.router.navigate(['/login']).then();
        location.reload();

        this.toastr.error(this.translate.instant('error_chat_connect'), 'Message Système');
    }

    /**
     * Get all connected user
     */
    public getConnectedUsers() {
        return this.connectedUsers;
    }

    /**
     * Get all FakeProfile related to the right product and segment available by the operator
     * @param user object
     */
    public getFakeProfileRelatedToUserProductAndNiche(user: User) {
        if (!user.getProduct() || !user.getProduct().getSegment()) {
            return [];
        }

        const segmentId = user.getProduct().getSegment().getId();

        const listToReturn = [];

        for (const fakeUser of this.getFakeUsers()) {
            if (!fakeUser.isConnected()) {
                continue;
            }

            if (!fakeUser.getProduct()) {
                continue;
            }

            if (!fakeUser.getProduct().getSegment()) {
                continue;
            }

            if (fakeUser.getProduct().getSegment().getId() === segmentId) {
                listToReturn.push(fakeUser);
            }
        }

        return listToReturn;
    }

    /**
     * Get fake user list
     */
    public getFakeUsers(): User[] {
        return this.fakeUsers;
    }

    /**
     * Action logout from the chat
     */
    public logout() {
        // Logout socket action
        this.socket.emit('logout');
        this.reset();
    }

    /**
     * Login to the chat
     * @param username
     * @param password
     */
    public login(username: string, password: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            // Login to the socket and init data
            this.socket.emit('login_operator', {username, password}, (data: any) => {
                if (data.success) {
                    const operator = new Operator().deserialize({
                        id: data.id,
                        uid: data.uid,
                        groups: data.groups,
                        rights: data.rights,
                        loaded: true
                    });

                    this.userManagementService.setCurrentUser(operator);

                    // Logged
                    this.logged = true;

                    this.roomsConfig = data.roomsConfig;
                    this.animationConfig = data.animationConfig;

                    console.log('Logged to chat service');
                    observer.next(true);
                } else {
                    console.log('Log in to chat service failed');
                    let errorMessage;

                    switch (data.errorMessage) {
                        case 'BAD_CHAT_VERSION':
                            errorMessage = this.translate.instant('update_version') +
                                this.translate.instant('reload_page');
                            break;
                        case 'BAD_USERNAME_OR_PASSWORD':
                            errorMessage = this.translate.instant('bad_id');
                            break;
                        default:
                            errorMessage = this.translate.instant('server_connect') + ' (' + data.errorMessage + ')' +
                                this.translate.instant('thanks_try');
                            break;
                    }
                    observer.error(errorMessage);
                }
            });
        });
    }

    /**
     * Bann the user from the chat
     * @param data arrau of element to bann the user
     */
    public banUser(data) {

        // this.addUserIdToBannedUser(data.user.id);

        // Login to the socket and init data
        const dataToBan = {
            userId: data.user.id,
            uid: data.uid,
            moderationStatus: User.MODERATION_STATUS_BANNED,
            reason: {
                type: data.type,
                message: data.message
            }
        };

        this.socket.emit('update_status_moderation', dataToBan, (datas) => {
            if (datas.success) {
                // Sent the toaster
                this.toastr.success(this.translate.instant('the_profile') + ' ' + data.user.username +
                    ' ' + this.translate.instant('now_bannish_profile'), 'Moderation - Bannir');
            } else {
                // Sent the toaster
                this.toastr.error(this.translate.instant('error_bannish') + ' '
                    + data.username + this.translate.instant('thanks_try'), 'Moderation - Bannir');
            }
        });
    }

    /**
     * Retrieve all data about the target profiles
     * @param userId from zmUser / fakeProfile
     * @param withUserId user requesting to see the profile (required for relationships)
     */
    retrieveUserProfilesDetails(userId, withUserId) {

        const userDataId: any = {
            userId,
            withUserId
        };

        this.socket.emit('get_user_profile', userDataId, data => {
            this.setUserInformationDetail(data);
        });
    }

    reactiveUser(user: User, action: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            const dataToReactive = {
                userId: user.id,
                uid: user.uid,
                moderationStatus: User.MODERATION_STATUS_ACTIVE
            };

            this.socket.emit('update_status_moderation', dataToReactive, (datas) => {

                if (datas.success) {
                    switch (action) {
                        case 'banned':
                            this.toastr.success(this.translate.instant('the_profile') + ' ' +
                                user.username + ' ' + this.translate.instant('profile_unbannish'),
                                'Moderation - Bannir'
                            );
                            break;
                        case 'ghosted':
                            this.toastr.success(
                                this.translate.instant('the_profile') + ' ' + user.username
                                + ' ' + this.translate.instant('profile_unghost'),
                                'Moderation - Ghost'
                            );
                            break;
                        case 'suspended':
                            this.toastr.success(
                                this.translate.instant('the_profile') + ' ' + user.username + ' ' +
                                this.translate.instant('profile_unsuspend'),
                                'Moderation - Suspendre'
                            );
                            break;
                    }

                    user.setModerationStatus(User.MODERATION_STATUS_ACTIVE);

                    observer.next(true);
                } else {
                    switch (action) {
                        case 'banned':
                            this.toastr.error(
                                this.translate.instant('error_unbannish'),
                                'Moderation - Bannir'
                            );
                            break;
                        case 'ghosted':
                            this.toastr.error(
                                this.translate.instant('error_unghost'),
                                'Moderation - Ghost'
                            );
                            break;
                        case 'suspended':
                            this.toastr.error(
                                this.translate.instant('error_unsuspend'),
                                'Moderation - Suspendre');
                            break;
                    }

                    observer.next(false);
                }
            });
        });
    }

    /**
     * Ghost the user from the chat
     * @param user
     * @param reason
     * @param message
     */
    public ghostUser(user: User, reason: string, message: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            const dataToGhost = {
                userId: user.getId(),
                uid: user.getUid(),
                moderationStatus: User.MODERATION_STATUS_GHOSTED,
                reason: {
                    type: reason,
                    message
                }
            };

            this.socket.emit('update_status_moderation', dataToGhost, (data) => {
                if (data.success) {
                    // Sent the toaster
                    this.toastr.success(this.translate.instant('the_profile') + ' ' + user.getUsername() +
                        ' ' + this.translate.instant('profile_ghost'), 'Moderation - Ghost');

                    user.setModerationStatus(User.MODERATION_STATUS_GHOSTED);

                    observer.next(true);
                } else {
                    // Sent the toaster
                    this.toastr.error(this.translate.instant('error_ghost') + ' '
                        + user.getUsername() + this.translate.instant('thanks_try'), 'Moderation - Ghost');

                    observer.next(false);
                }
            });
        });
    }

    public warnUser(data) {

        // Login to the socket and init data
        const dataToWarn = {
            userId: data.user.id,
            uid: data.uid,
            moderationStatus: User.MODERATION_STATUS_WARNED,
            reason: {
                type: data.type,
                message: data.message
            }
        };

        this.socket.emit('update_status_moderation', dataToWarn, (datas) => {
            if (datas.success) {
                const user = this.getUserById(data.user.id);

                user.moderationWarning = datas.moderationWarning;
                user.moderationWarningDate = datas.moderationWarningDate;

                // Sent the toaster
                this.toastr.success(this.translate.instant('the_profile') + ' ' + data.user.username +
                    ' ' + this.translate.instant('profile_alert'), 'Moderation - Alerte');
            } else {
                // Sent the toaster
                this.toastr.error(this.translate.instant('error_alert') + ' '
                    + data.user.username + this.translate.instant('thanks_try'), 'Moderation - Alerte');
            }
        });
    }

    /**
     * Bann the user from the chat
     * @param data array of element to ban the user
     */
    public suspendUser(data) {
        // Login to the socket and init data
        const dataToSuspend = {
            userId: data.user.id,
            uid: data.uid,
            moderationStatus: User.MODERATION_STATUS_SUSPENDED,
            reason: {
                type: data.type,
                message: data.message
            },
            timeSuspension: data.minute
        };

        this.socket.emit('update_status_moderation', dataToSuspend, (datas) => {
            if (datas.success) {
                // Sent the toaster
                this.toastr.success(this.translate.instant('the_profile') + ' ' + data.user.username +
                    ' ' + this.translate.instant('profile_suspend'), 'Moderation - Suspendre');
            } else {
                // Sent the toaster
                this.toastr.error(this.translate.instant('error_suspend') + ' '
                    + data.user.username + this.translate.instant('thanks_try'), 'Moderation - Suspendre');
            }
        });
    }

    /**
     * Send social action to profile
     * @param relationship string
     * @param user User
     * @param targetUser User
     * @param forcedAction string
     */
    public socialAction(relationship: string, user: User, targetUser: User, forcedAction: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const socialActionData: any = {
                profileFromUid: user.uid,
                profileToUid: targetUser.uid
            };

            if (forcedAction) {
                socialActionData.action = forcedAction;
            } else {
                switch (relationship) {
                    case 'favorite':
                        socialActionData.action = 'addFavorite';
                        break;
                    case 'kissed':
                        socialActionData.action = 'addKiss';
                        break;
                    case 'friend':
                        socialActionData.action = 'addFriend';
                        break;
                    default:
                        console.error('Action not found for relationship: ' + relationship);
                        return;
                }
            }

            this.socket.emit('put_social_action', socialActionData, data => {
                if (data.success) {
                    this.toastr.success(this.translate.instant('social_action.' + relationship, {nickname: targetUser.username}));
                    resolve(true);
                } else {
                    if (forcedAction !== 'acceptFriend') {
                        this.toastr.error(this.translate.instant('modal.error.title'));
                    }
                    resolve(false);
                }
            });
        });
    }

    /**
     * Set user information detail
     * @param zmProfileDetail any
     */
    setUserInformationDetail(zmProfileDetail) {
        this.userDetails$.next(zmProfileDetail);
    }

    /**
     * Get user information detail
     */
    getUserInformationDetail() {
        return this.userDetails$;
    }

    isLogged(): boolean {
        return this.logged;
    }

    private onUserDisconnected(user): void {
        // update the user connected state
        this.removeFromConnectedUser(user.id);

        // Remove typing
        for (const i in this.privateRooms) {
            if (this.privateRooms[i].getUser1().id === user.id || this.privateRooms[i].getUser2().id === user.id) {
                this.privateRooms[i].setTyping(false);
            }
        }
    }

    /**
     * List of connected users
     * @param users array
     */
    private onUsers(users): void {
        for (const i of Object.keys(users)) {
            const userId = users[i].id;

            // Existing user ?
            let user = this.getUserById(userId);

            if (user === null) {
                // New user
                user = new User();
                this.addUser(user);
            }

            user.deserialize(users[i]);

            // Display all connected users
            if (user.isConnected()) {
                if (user.isFake()) {
                    this.addUserFake(user);
                } else {
                    this.addConnectedUser(user);
                }
            }

            // The user is fully loaded
            user.setLoaded(true);
            user.setLoading(false);
        }

        // Try to load messages
        this.retryLoadingMessages();
    }

    private onUserUpdate(userToUpdate): void {
        const userId = +userToUpdate.id;
        const user = this.getAnimationUserById(userId);
        if (user && user instanceof User) {
            user.deserialize(userToUpdate);
        }

        this.realRooms.forEach(room => {
            if (room.getUser1().getId() === userId) {
                room.getUser1().deserialize(userToUpdate);
            } else if (room.getUser2().getId() === userId) {
                room.getUser2().deserialize(userToUpdate);
            }
        });
    }

    private onAnimationUsers(dataUsers: any[], oldUsers: boolean): void  {
        if (!oldUsers) {
            // keep existing subscribed users
            this.subscribedUsers = this.subscribedUsers.filter((user: User) => {
                for (const dataUser of dataUsers) {
                    if (dataUser.id === user.getId()) {
                        return true;
                    }
                }
                return false;
            });

            // keep existing subscribed users
            this.freemiumUsers = this.freemiumUsers.filter((user: User) => {
                for (const dataUser of dataUsers) {
                    if (dataUser.id === user.getId()) {
                        return true;
                    }
                }
                return false;
            });

            this.animationUsers = [...this.subscribedUsers, ...this.freemiumUsers];
        }

        for (const dataUser of dataUsers) {
            if (this.hasAnimationUser(dataUser.id)) {
                continue;
            }
            const user: User = this.getUserFromData(dataUser);
            this.addAnimationUser(user);
        }

        this.getUserRoomMeta();

        this.subscribedUsers = this.subscribedUsers.sort(ChatService.sortByLastMessageTime);
        this.freemiumUsers = this.freemiumUsers.sort(ChatService.sortByLastMessageTime);
    }

    private getUserRoomMeta() {
        if (this.fetchingMetaData || !this.animationUsers.length) {
            return;
        }
        this.fetchingMetaData = true;

        const data = {
            usersId: [],
            additionalSearchDay: this.additionalSearchDay
        };
        for (const user of this.animationUsers) {
            data.usersId.push(user.getId());
        }

        this.socket.emit('get_user_room_meta', data, (userRoomMeta: any[]) => {
            for (const user of this.animationUsers) {
                const meta = userRoomMeta.find(userRoom => user.getId() === +userRoom.user_id);
                if (meta) {
                    user.setMeta(meta);
                } else {
                    user.resetMeta();
                }
            }
            this.notificationService.countUnreadMessages(this.realRooms, this.animationUsers);
            this.fetchingMetaData = false;
        });
    }

    /**
     * Get the full private rooms list
     * @param dataRooms object
     * @param oldConversations Old conversations
     */
    private onPrivateRooms(dataRooms: any[], oldConversations: boolean): void {
        this.me = this.userManagementService.getCurrentUser();

        if (!oldConversations) {
            // keep existing real rooms
            this.realRooms = this.realRooms.filter((room: Room) => {
                for (const dataRoom of dataRooms) {
                    if (dataRoom.id === room.getId()) {
                        return true;
                    }
                }
                return false;
            });

            // keep existing fake rooms
            this.fakeRooms = this.fakeRooms.filter((room: Room) => {
                for (const dataRoom of dataRooms) {
                    if (dataRoom.id === room.getId()) {
                        return true;
                    }
                }
                return false;
            });

            this.privateRooms = [...this.realRooms, ...this.fakeRooms];
        }

        for (const data of dataRooms) {
            // Prevent room duplication
            if (this.privateRooms.find((r: Room) => r.getId() === data.id)) {
                continue;
            }

            const room = new Room().deserialize(
                {
                    id: data.id,
                    lastMessage: data.lastMessage,
                    lastMessageTime: new Date(data.time),
                    lastMessageId: data.lastMessageId,
                    lastMessageUserId: data.lastMessageUserId,
                    messageId: data.lastMessageId
                }
            );

            room.setUser1(this.getUserFromData(data.user1));
            room.setUser2(this.getUserFromData(data.user2));

            if (room.getUser1().isFake()) {
                room.setUnreadMessage(data.user1UnreadCount);
            } else if (room.getUser2().isFake()) {
                room.setUnreadMessage(data.user2UnreadCount);
            } else if (data.moderationUnreadCount) {
                room.setUnreadMessage(data.moderationUnreadCount);
            }

            this.privateRooms.push(room);

            if (room.getUser1().isFake() || room.getUser2().isFake()) {
                const fakeRoomIndex = this.fakeRooms.findIndex(fakeRoom => fakeRoom && room && fakeRoom.getId() === room.getId());

                if (fakeRoomIndex === -1) {
                    this.fakeRooms.push(room);
                }
            } else {
                const realRoomIndex = this.realRooms.findIndex(realRoom => realRoom && room && realRoom.getId() === room.getId());

                if (realRoomIndex === -1) {
                    this.realRooms.push(room);
                }
            }
        }

        this.notificationService.countUnreadMessages(this.realRooms, this.animationUsers);
        this.sortPrivateRooms();

        if (this.currentSelectedUser && !this.currentSelectedRoom && this.fakeRooms && this.fakeRooms.length) {
            this.selectRoom(this.fakeRooms[0]);
        }
    }

    /**
     * Order by lastMessageTime
     */
    private sortPrivateRooms() {
        this.fakeRooms = this.fakeRooms.sort(
            (a, b) => new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()
        );

        this.realRooms = this.realRooms.sort(
            (a, b) => new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()
        );
    }

    private addMessageToRoomFromData(messageData: any, room: Room) {
        const message = new Message().deserialize({
            id: messageData.messageId,
            date: new Date(messageData.time),
            message: messageData.message,
            ghosted: messageData.ghosted,
            operatorUsername: messageData.operatorUsername
        });

        message.setUser(this.getUserById(messageData.userId));
        message.setRecipient(this.getUserById(messageData.recipientId));
        message.setRoom(room);

        // Last message
        if (room.getLastMessageId() === null || message.getId() > room.getLastMessageId()) {
            room.setLastMessageId(message.getId());
            room.setLastMessage(message.getMessage());
            room.setLastMessageTime(message.getDate());
            room.setLastMessageUserId(message.getUser().getId());
        }

        // It's my message ?
        if (message.getUser() === room.getMe()) {
            message.setMe(true);
        }

        // Only add message to loaded room
        if (room.isLoaded()) {
            room.addMessage(message);
        }

        return message;
    }

    /**
     * Socket Event Private Message
     */
    private onPrivateMessage(messageData) {

        const missingUsers = [];

        const user = this.getUserById(messageData.userId);
        const recipient = this.getUserById(messageData.recipientId);

        if (user === null || recipient === null) {
            // User is missing ?
            if (user === null) {
                if (missingUsers.indexOf(messageData.userId) === -1) {
                    missingUsers.push(messageData.userId);
                }
            }

            // Recipient is missing ?
            if (recipient === null) {
                if (missingUsers.indexOf(messageData.recipientId) === -1) {
                    missingUsers.push(messageData.recipientId);
                }
            }

            this.loadingMessages.push(messageData);
        } else {
            // Add message
            this.addMessageFromData(messageData);
        }

        // Load missing users
        if (missingUsers.length) {
            this.socket.emit('get_users', missingUsers, (usersData) => {
                this.onUsers(usersData);
            });
        }
    }

    /**
     * Moderations event
     */
    private onModerations(dataModerations: any) {
        this.moderationService.parseModerationsData(dataModerations);
    }

    /**
     * Try to load messages
     */
    private retryLoadingMessages() {
        const messages = this.loadingMessages;
        this.loadingMessages = [];

        for (const messageData of messages) {
            const message = this.addMessageFromData(messageData);

            if (message === null) {
                this.loadingMessages.push(messageData);
            }
        }
    }

    /**
     * Add message
     * @param data Message data
     */
    private addMessageFromData(data: any): Message {
        let room = this.getRoomById(data.roomId);
        let animationUser = null;

        /**
         * New room ?
         */
        if (room === null) {
            /**
             * Sender
             */
            const user = this.getUserById(data.userId);

            if (user === null) {
                // User not found : waiting for loading from server
                return null;
            }

            /**
             * Recipient
             */
            const recipient = this.getUserById(data.recipientId);

            if (recipient === null) {
                // User not found : waiting for loading from server
                return null;
            }

            // Create the new room
            room = new Room();
            room.setId(data.roomId);

            // User1.id must be lesser User2.id (room server rule)
            if (user.getId() < recipient.getId()) {
                room.setUser1(user);
                room.setUser2(recipient);
            } else {
                room.setUser1(recipient);
                room.setUser2(user);
            }

            // If it's an animation room (aka with fake)
            if (room.getOther()) {
                // First we try to add the user to the list if he doesn't already exists.
                this.addAnimationUser(room.getOther());

                // Then we search for the real user to animate
                animationUser = this.getAnimationUserById(room.getOther().getId());

                if (animationUser && animationUser.getId() === data.userId) {
                    // If the real user did send the message, we update it's lastMessageTime
                    animationUser.lastMessageTime = new Date(data.time);
                }
                this.getUserRoomMeta();

                // If this message doesn't belong to the current user, we stop here because we don't need to add the room
                if (!this.currentSelectedUser || room.getOther().getId() !== this.currentSelectedUser.getId()) {
                    return null;
                }

                this.fakeRooms.push(room);
            } else {
                this.realRooms.push(room);
            }

            this.privateRooms.push(room);
        }

        const message: Message = this.addMessageToRoomFromData(data, room);

        /**
         * Message received to me
         */
        if (!message.isMe()) {
            if (!this.currentSelectedRoom || this.currentSelectedRoom.getId() !== room.getId()) {
                // Add one if the room is closed
                room.addOneToUnreadMessage();
            } else if (this.currentSelectedRoom) {
                if (!this.currentSelectedRoom.getOther()) {
                    // Room to moderate
                    this.notifyConversationAsReadByOperator(this.currentSelectedRoom);
                } else {
                    // Room to animate was read
                    this.setRoomRead(this.currentSelectedRoom);
                }
            }
        }

        // if this room has a timeout we remove it before it removes the room
        this.clearInactiveRoomTimeout(room.getId());

        // Room read
        if (this.currentSelectedRoom === room) {
            this.notifyConversationAsReadByOperator(this.currentSelectedRoom);
        }

        // Notifications
        this.notificationService.countUnreadMessages(this.realRooms, this.animationUsers);

        // Sorting rooms
        this.sortPrivateRooms();

        // Notify message to room
        this.messages.next(message);

        return message;
    }

    /**
     * param message any
     */
    private onLogout(message: any) {
        // Redirect user to the login page
        this.router.navigate(['/login']).then();
        // Display toaster message
        this.toastr.info(this.translate.instant(message.message));
        // Logout from the chat
        this.userManagementService.logout();
        // Logout from the app
        this.logout();
    }

    /**
     * Manage socket reconnect
     */
    private onDisconnect(event) {
        console.log('Disconnected from chat service', event);

        this.reset();

        if (this.logged) {
            this.logoutUserAndRedirectToLogin();
        } else {
            this.socket.connect();
        }
    }

    addAnimationUser(user: User) {
        if (!this.hasAnimationUser(user.getId())) {
            this.animationUsers.push(user);
        }

        const listIndex = user.isSubscribed() ? 'subscribedUsers' : 'freemiumUsers';
        const reversedListIndex = user.isSubscribed() ? 'freemiumUsers' : 'subscribedUsers';

        if (!this[listIndex].find(u => u.getId() === user.getId())) {
            // We first search if the user is in the opposite list
            const reverseIndex = this[reversedListIndex].findIndex(u => u.getId() === user.getId());
            if (reverseIndex > -1) {
                // If he is, we remove it from this list
                this[reversedListIndex].splice(reverseIndex, 1);
            }

            // Then we add the user to the right list
            this[listIndex].push(user);
        }
    }

    /**
     * Messages observable
     */
    getMessages(): Subject<Message> {
        return this.messages;
    }

    /**
     * Get user from data. Create user if not exists
     */
    getUserFromData(data: any): User {
        let user = this.getUserById(data.id);
        if (user) {
            // User already exist, update it before returning it
            user.deserialize(data);
            return user;
        }

        user = new User().deserialize(data);

        this.addUser(user);

        return user;
    }

    /**
     * Load users list
     * @param users Users list
     */
    loadUsers(users: User[]) {
        const userList = [];

        for (const user of users) {
            if (!user.isLoaded() && !user.isLoading()) {
                user.setLoading(true);
                userList.push(user.getId());
            }
        }

        this.socket.emit('get_users', userList, (usersData) => {
            this.onUsers(usersData);
        });
    }

    /**
     * Start user loader manager
     */
    startUserLoaderManager() {
        this.stopUserLoaderManager();

        this.userLoaderTimer = setTimeout(() => {
            this.userLoaderTimer = null;
            this.userLoaderManager();
            this.startUserLoaderManager();
        }, 1000) as any;
    }

    /**
     * User loader manager
     */
    stopUserLoaderManager() {
        if (this.userLoaderTimer !== null) {
            clearTimeout(this.userLoaderTimer);
            this.userLoaderTimer = null;
        }
    }

    /**
     * User lazy loading
     */
    userLoaderManager() {
        const users = [];

        for (const room of this.privateRooms) {
            if (!room.getUser1().isLoading() && !room.getUser1().isLoaded()) {
                users.push(room.getUser1());
            }

            if (!room.getUser2().isLoading() && !room.getUser2().isLoaded()) {
                users.push(room.getUser2());
            }
        }

        if (users.length) {
            this.loadUsers(users);
        }
    }

    /**
     * Is socket is connected to server ?
     */
    isSocketConnected() {
        return this.socket.ioSocket.connected;
    }

    /**
     * Enable / disable server notification
     * @param enabled Enable / disable
     */
    setNotificationStatus(enabled: boolean) {
        this.notificationsStatus = enabled;

        this.socket.emit('set_notification_status', enabled);
    }

    canReceiveNotifications() {
        return this.notificationsStatus;
    }

    /**
     * Notify user while typing
     */
    notifyTypingToFriendUser(data) {
        this.socket.emit('notify_user_typing', data);
    }

    /**
     * Notify server mark conversation as read by operator
     * @param room Room
     */
    notifyConversationAsReadByOperator(room) {
        this.socket.emit('set_room_moderation_read', {roomId: room.getId()});
    }

    /**
     * Cleaning up the timeout to prevent it from running
     * @param roomId Room.getId()
     */
    clearInactiveRoomTimeout(roomId) {
        if (this.inactiveRooms[roomId]) {
            clearTimeout(this.inactiveRooms[roomId].timeoutId);
            this.inactiveRooms[roomId].readyToBeRemoved = false;
        }
    }

    /**
     * We set a timeout in order to remove the room if it's still inactive in 5 min
     * @param roomId Room id
     */
    setInactiveRoomTimeout(roomId) {
        this.clearInactiveRoomTimeout(roomId);

        this.inactiveRooms[roomId] = {
            readyToBeRemoved: true,
            timeoutId: setTimeout(() => {
                this.removeInactiveRoom(roomId);
            }, 300000)
        };
    }

    removeInactiveRoom(roomId) {
        // We don't remove the room if there is less than than the given constant roomsConfig.min
        if (this.realRooms.length <= this.roomsConfig.min) {
            // comment the return out if you don't have enough rooms for testing the other conditions
            return false;
        }

        /**
         * Whatever happens now the room will not be in the list anymore for removal
         * This readyToBeRemoved property only has a meaning because of this.roomsConfig.min
         * So DON'T move around the line below unless you're sure about what you're doing
         */
        if (this.inactiveRooms[roomId]) {
            this.inactiveRooms[roomId].readyToBeRemoved = false;
        }

        // We don't remove the room if we are currently in this room
        if (this.currentSelectedRoom && this.currentSelectedRoom.getId() === roomId) {
            return false;
        }

        // We look for the room to remove it
        const realRoomIndex = this.realRooms.findIndex(room => room.getId() === roomId);
        this.realRooms.splice(realRoomIndex, 1);

        this.socket.emit('remove_room_from_operator', {roomId});

        return true;
    }

    /**
     * Notify server all room messages are read
     * @param room Room
     */
    setRoomRead(room: Room) {
        if (room.getMe()) {
            this.socket.emit('set_room_read', {
                roomId: room.getId(),
                userId: room.getMe().getId()
            }, () => {
                this.getUserRoomMeta();
            });
        }
    }

    /**
     * Retrieve all available fakes profiles ids
     * @param userId User Id
     * @param callback Callback
     */
    getFakeProfilesAvailable(userId, callback) {
        this.socket.emit('get_fake_profiles_available', {
            userId
        }, (ids) => {
            callback(ids);
        });
    }

    /**
     * Delete a message
     * @param message Message
     */
    deleteMessage(message: Message) {
        this.socket.emit('delete_message', {id: message.getId()}, data => {
            if (data.success) {

                const room = message.getRoom();
                room.removeMessage(message.id);

                if (data.roomDeleted) {
                    this.removeRoom(room);
                }

                this.toastr.success(this.translate.instant('message_deleted'));
            } else {
                this.toastr.error(this.translate.instant('error_delete'));
            }

        });
    }

    /**
     * When user typing message to fake profil : notify operator on conversation
     * @param data Data
     */
    private onNotifyUserTyping(data) {
        const roomIndex = this.privateRooms.findIndex(room => room.getId() === data.roomId);

        if (this.privateRooms[roomIndex]) {
            this.privateRooms[roomIndex].setTyping(data.typing);
        }
    }

    /**
     * Refresh fake profiles available by user id
     * @param data Data
     */
    private onFakeProfilesAvailable(data) {
        for (const user of this.connectedUsers) {
            if (user.getId() === data.userId) {
                // List of all fake profiles
                const listOfFakeProfile = this.getFakeProfileRelatedToUserProductAndNiche(user);
                const availableFakeProfiles = [];

                // Get fake profiles only if there're within the available list of fake ids
                for (const fakeUser of listOfFakeProfile) {
                    if (data.list.find(id => fakeUser && id === fakeUser.getId())) {
                        availableFakeProfiles.push(fakeUser);
                    }
                }

                // Updating list of available fake profiles for this user
                user.setUsersFakeAvailable(availableFakeProfiles);
            }
        }
    }

    loadMoreRooms(callback) {
        return this.socket.emit('get_more_rooms', {
            count: this.moderationRoomNeeded
        }, callback);
    }

    loadMoreAnimation(subscribed, callback) {
        return this.socket.emit('get_more_animation', {
            count: this.animationUserNeeded,
            additionalSearchDay: this.additionalSearchDay,
            subscribed
        }, callback);
    }

    loadMoreMessages(room: Room, callback) {
        const quantity = 10;

        const nbMessage = room.getMessages().length;
        const user = room.getMe() ? room.getMe() : room.getUser1();

        return this.socket.emit('private_room_get_messages', {
            roomId: room.getId(),
            userId: user.getId(),
            start: nbMessage,
            count: quantity
        }, data => {

            room.setLoaded(true);

            const messages = [];

            for (const messageData of data) {
                const message = this.addMessageToRoomFromData(messageData, room);
                messages.push(message);
            }

            room.messages.sort((a, b) => a.getId() - b.getId());

            callback(messages.length > 0);
        });
    }

    textToEmoji(text, target) {
        if (!text) {
            return '';
        }

        // regex récupérant tous les mots ainsi que le pattern :string:
        let words = text.match(/(:[^:]*:)/g) || [];
        words = Array.from(
            new Set(
                words.concat(text.match(/([^"\s]+)/g) || [])
            )
        );

        if (words.length > 0) {
            const savedSelectionOld = this.saveSelection(target);
            const savedSelection = Object.assign({}, savedSelectionOld);

            for (const word of words) {
                const emoji = this.getEmojiByText(word);

                if (emoji) {
                    const emojiByBrowserSupport = this.getEmojiByBrowserSupport(emoji);
                    let replaceLength = word.length - 1;
                    // Regex build, escaping special characters
                    const replaceWord = new RegExp(word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
                    const replaceCpt = text.match(replaceWord).length;

                    if (emojiByBrowserSupport.match(/<img.*src=".*">/g)) {
                        this.addEmojiReplaced(emoji, emojiByBrowserSupport);
                        replaceLength = emoji.str.length;
                    }

                    // Calculate the cursor position to set back
                    savedSelection.start = savedSelection.start - (replaceLength * replaceCpt) + replaceCpt;
                    savedSelection.end = savedSelection.end - (replaceLength * replaceCpt) + replaceCpt;
                    text = text.replace(replaceWord, emojiByBrowserSupport);

                    if (savedSelectionOld.start !== savedSelection.start && savedSelectionOld.end !== savedSelection.end) {
                        setTimeout(() => {
                            this.restoreSelection(target, savedSelection);
                        });
                    }
                }
            }
        }

        return text;
    }

    getEmojiByText(text) {
        if (typeof this.emojis === 'object') {
            const emojiList = Object.values(this.emojis);
            for (const emojiCategory of emojiList) {
                const categoryEmojis: Array<any> = Object.values(emojiCategory.emojis);
                for (const emoji of categoryEmojis) {
                    if (emoji.str === text) {
                        return emoji;
                    }
                }
            }
        }

        return '';
    }

    getEmojiByUnicode(unicode) {
        if (typeof this.emojis === 'object') {
            const emojiList = Object.values(this.emojis);
            for (const emojiCategory of emojiList) {
                const categoryEmojis: Array<any> = Object.values(emojiCategory.emojis);
                for (const emoji of categoryEmojis) {
                    if (emoji.emoji === unicode) {
                        return emoji;
                    }
                }
            }
        }

        return '';
    }

    getEmojiByBrowserSupport(emoji) {
        let emojiHtml;

        if (emoji.supportByBrowser) {
            emojiHtml = emoji.emoji;
        } else if (emoji.img) {
            emojiHtml = '<img alt="" class="emoji-img" src="' + emoji.img + '">';
        }

        return emojiHtml;
    }

    checkEmojiSupport(message) {
        if (message) {
            const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            const emojis = message.match(regex, '');

            // Filter html in the message
            message = message.replace(/[&<>'"]/g, x => '&#' + x.charCodeAt(0) + ';');

            if (Array.isArray(emojis)) {
                for (const emoji of emojis) {
                    const emojiUnicode = this.getEmojiByUnicode(emoji);
                    const emojiReplaced = this.getEmojiByBrowserSupport(emojiUnicode);

                    if (emojiReplaced && emoji !== emojiReplaced) {
                        message = message.replace(emoji, emojiReplaced);
                    }
                }
            }
        }

        return message;
    }

    addEmojiReplaced(emojiOld, emojiNew) {
        const emojiReplaced = {
            old: emojiOld.emoji,
            new: emojiNew
        };
        if (!this.emojisReplaced.includes(emojiReplaced)) {
            this.emojisReplaced.push(emojiReplaced);
        }
    }

    getMessageByEmojiReplaced(message) {
        if (!message) {
            return '';
        }

        for (const emojiReplace of this.emojisReplaced) {
            message = message.replace(emojiReplace.new, emojiReplace.old);
        }

        return message;
    }

    switchEmojisShow() {
        this.emojisShow = !this.emojisShow;
    }

    saveSelection(containerEl) {
        if (this.window.getSelection && this.document.createRange) {
            const range = this.window.getSelection().getRangeAt(0);
            const preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(containerEl);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);
            const start = preSelectionRange.toString().length;

            return {
                start,
                end: start + range.toString().length
            };
        }
    }

    restoreSelection(containerEl, savedSel) {
        if (this.window.getSelection && this.document.createRange) {
            let charIndex = 0;
            const range = this.document.createRange();
            range.setStart(containerEl, 0);
            range.collapse(true);
            const nodeStack = [containerEl];
            let foundStart = false;
            let stop = false;

            while (!stop) {
                const node = nodeStack.pop();
                if (!node) {
                    break;
                }

                if (node.nodeType === 3) {
                    const nextCharIndex = charIndex + node.length;
                    if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
                        range.setStart(node, savedSel.start - charIndex);
                        foundStart = true;
                    }
                    if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
                        range.setEnd(node, savedSel.end - charIndex);
                        stop = true;
                    }
                    charIndex = nextCharIndex;
                } else {
                    let i = node.childNodes.length;
                    while (i--) {
                        nodeStack.push(node.childNodes[i]);
                    }
                }
            }

            const sel = this.window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }

    /**
     * Get the current active room
     */
    getCurrentRoom(): Room {
        return this.currentSelectedRoom;
    }

    selectRoom(room: Room) {
        if (this.getCurrentRoom() && this.getCurrentRoom().getId() === room.getId()) {
            return;
        }
        this.currentSelectedRoom = room;

        if (room.getOther()) {
            const animationUser = this.getAnimationUserById(room.getOther().getId());
            if (animationUser) {
                animationUser.subUnreadMessages(room.getUnreadMessage());
            }
            this.setRoomRead(room);
        } else {
            this.currentSelectedUser = null;
            this.removeAllFakeRooms();
            this.setInactiveRoomTimeout(this.getCurrentRoom().getId());
            this.notifyConversationAsReadByOperator(room);
        }

        room.resetUnreadMessage();
        this.notificationService.countUnreadMessages(this.realRooms, this.animationUsers);
    }

    /**
     * Set the current active room
     * @param room Room
     */
    setCurrentRoom(room: Room) {
        this.currentSelectedRoom = room;
        this.notificationService.countUnreadMessages(this.realRooms, this.animationUsers);
    }

    getCurrentAnimationUser(): User {
        return this.currentSelectedUser;
    }

    setCurrentAnimationUser(user: User) {
        this.currentSelectedUser = user;
        this.removeAllFakeRooms();

        this.currentSelectedRoom = null;

        if (user) {
            this.loadMoreFakeRooms();
        }
    }

    loadMoreFakeRooms(callback = null) {
        let count = this.currentSelectedUser.getUnansweredRooms() ? Math.ceil(this.currentSelectedUser.getUnansweredRooms() / 3) : 3;
        if (count > 3) {
            count = 3;
        }

        count += this.fakeRooms.length;

        return this.socket.emit('animation_rooms', {
            userId: this.currentSelectedUser.getId(),
            additionalSearchDay: this.additionalSearchDay,
            count
        }, callback);
    }

    getRoomById(id: number): Room {
        const room = this.privateRooms.find((r: Room) => r.getId() === id);
        return room ? room : null;
    }

    removeRoom(room: Room) {
        let i;

        i = this.privateRooms.findIndex((r: Room) => r.getId() === room.getId());
        if (i !== -1) {
            this.privateRooms.splice(i, 1);
        }

        i = this.realRooms.findIndex((r: Room) => r.getId() === room.getId());
        if (i !== -1) {
            this.realRooms.splice(i, 1);
        }

        i = this.fakeRooms.findIndex((r: Room) => r.getId() === room.getId());
        if (i !== -1) {
            this.fakeRooms.splice(i, 1);
        }

        if (this.currentSelectedRoom && this.currentSelectedRoom.getId() === room.getId()) {
            this.currentSelectedRoom = null;
        }
    }

    /**
     * Accept or reject a moderation
     * @param moderation
     * @param action
     * @param reasons
     */
    updateModeration(
        moderation: AbstractModeration,
        action: string,
        reasons: string[] = null
    ): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('moderation_profile', {
                id: moderation.getId(),
                action,
                reasons,
                data: moderation.getData()
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    updateProfileGender(uid: number, gender: number, searchedGender: number): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('update_profile_gender', {
                uid,
                gender,
                searchedGender
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    updatePhotoRating(profileId: number, photoId: number, rating: number): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('update_photo_rating', {
                profileId,
                photoId,
                rating
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    getAutologinUrl(moderation: AbstractModeration): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('get_autologin', {
                id: moderation.getId()
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    loadPhoto(id: number, base64 = false): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('get_photo', {
                id,
                base64
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    savePhoto(id: number, base64: string): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('update_photo', {
                id,
                base64
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    setOperatorStatus(moderationEnabled: boolean, animationEnabled: boolean): Observable<ApiResponse> {
        return new Observable<ApiResponse>((observer) => {
            this.socket.emit('operator_status', {
                moderationEnabled,
                animationEnabled
            }, (result: any) => {
                observer.next((new ApiResponse()).deserialize(result));
            });
        });
    }

    getAnimationUserById(userId: number): User {
        return this.animationUsers.find((user: User) => +user.getId() === +userId);
    }

    hasAnimationUser(userId: number): boolean {
        return !!this.getAnimationUserById(userId);
    }

    canSendNewMessageToUser(userId: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.socket.emit('can_send_new_message', {userId}, response => {
                resolve(response);
            });
        });
    }

    /**
     * Current timestamp in seconds.
     */
    getTime(): number {
        return Math.trunc(Date.now() / 1000);
    }

    incrementSearchDay(): void {
        this.additionalSearchDay += 7;
    }

    private removeAllFakeRooms() {
        for (const fakeRoom of this.fakeRooms) {
            const index = this.privateRooms.findIndex(room => room.getId() === fakeRoom.getId());
            this.privateRooms.splice(index, 1);
        }
        this.fakeRooms = [];
    }
}
