import {Deserializable} from '../deserialize/deserializable.model';
import {UserRight} from '../user-right/user-right';
import {Product} from '../product/product';
import {Segment} from '../segment/segment';
import {Subscription} from '../subscription/subscription';
import {FrustrationRule} from '../frustration-rule/frustration-rule';
import {Room} from '../room/room';

export class User implements Deserializable {
    static readonly STATE_CONNECTED = 'online';
    static readonly STATE_OFFLINE = 'offline';
    static readonly STATE_AUTHENTICATED = 'authenticated';

    static readonly STATUS_OFFLINE = 0;
    static readonly STATUS_ONLINE = 1;
    static readonly STATUS_INVISIBLE = 2;
    static readonly STATUS_DND = 3;
    static readonly STATUS_AWAY = 4;
    static readonly STATUS_ISOPERATOR = 1;
    static readonly STATUS_ZMUSER = 0;

    static readonly GENDER_MAN = 1;
    static readonly GENDER_WOMEN = 2;
    static readonly GENDER_MAN_CLASS = 'fa fa-mars hom';
    static readonly GENDER_WOMEN_CLASS = 'fa fa-venus fem';

    static readonly MODERATION_STATUS_ACTIVE = 1;
    static readonly MODERATION_STATUS_GHOSTED = 2;
    static readonly MODERATION_STATUS_SUSPENDED = 3;
    static readonly MODERATION_STATUS_BANNED = 4;
    static readonly MODERATION_STATUS_WARNED = 5;

    id: number;
    uid: number;
    username: string;
    password: string;
    gender: number;
    searchedGender: number;
    status: number;
    date: object;
    lastConnection: object;
    firstname: string;
    token: string;
    email: string;
    rights: UserRight;
    operator: boolean;
    product: Product = null;
    fake: boolean;
    photo: string = null;
    photoId: number = null;
    photoRating: number = null;
    disconnected: boolean;
    loaded: boolean;
    loading: boolean;
    moderationStatus: number;
    moderationDateSuspension: Date = null;
    moderationWarning: string;
    moderationWarningDate: Date = null;
    usersFakeAvailable: User;
    birthdate: Date = null;
    country: string = null;
    city: string;
    subscription: Subscription = null;
    ip: string = null;
    registerDate: Date = null;
    coins = 0;
    frustrationRule: FrustrationRule = null;
    lastMessageTime: Date = null;

    private unreadMessages = 0;
    private unansweredRooms = 0;

    constructor() {
        this.disconnected = false;
        this.loaded = false;
        this.loading = false;
    }

    isAMen() {
        return this.gender === User.GENDER_MAN;
    }

    getGenderClass() {
        return this.isAMen() ? User.GENDER_MAN_CLASS : User.GENDER_WOMEN_CLASS;
    }

    deserialize(data: any): this {
        Object.assign(this, data);

        this.rights = new UserRight();

        if (data && data.rights) {
            this.rights.deserialize(data.rights);
        }

        if (data && data.product) {
            this.product = new Product().deserialize(data.product);
        }

        if (data && data.subscription) {
            this.subscription = new Subscription().deserialize(data.subscription);
        }

        if (data.moderationDateSuspension) {
            this.moderationDateSuspension = new Date(data.moderationDateSuspension);
        }

        if (data.moderationWarningDate) {
            this.moderationWarningDate = new Date(data.moderationWarningDate);
        }

        if (data.birthdate) {
            this.birthdate = new Date(data.birthdate);
        }

        if (data.frustrationRule) {
            this.frustrationRule = new FrustrationRule().deserialize(data.frustrationRule);
        }

        if (data.lastMessageTime) {
            this.lastMessageTime = new Date(data.lastMessageTime);
        }

        return this;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getUid() {
        return this.uid;
    }

    getUsername(): string {
        return this.username;
    }

    getFirstname(): string {
        return this.firstname;
    }

    getGender() {
        return this.gender;
    }

    getSearchedGender() {
        return this.searchedGender;
    }

    getUserRights() {
        return this.rights;
    }

    getProduct(): Product {
        return this.product;
    }

    getSegment(): Segment {
        return this.product ? this.product.segment : null;
    }

    getUsersFakeAvailable() {
        return this.usersFakeAvailable;
    }

    setUsersFakeAvailable(usersFakeAvailable: any) {
        this.usersFakeAvailable = usersFakeAvailable;
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        this.token = token;
    }

    isConnected() {
        return (this.status === User.STATUS_ONLINE);
    }

    itsMe(id) {
        return this.id === id;
    }

    isOperator() {
        return this.operator;
    }

    isFake() {
        return this.fake;
    }

    getPhoto(): string {
        return this.photo;
    }

    setPhoto(photo: string) {
        this.photo = photo;
    }

    setPhotoId(photoId: number) {
        this.photoId = photoId;
    }

    getPhotoId(): number {
        return this.photoId;
    }

    getPhotoRating(): number {
        return this.photoRating;
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    isLoading(): boolean {
        return this.loading;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setModerationStatus(moderationStatus: number) {
        this.moderationStatus = moderationStatus;
    }

    getModerationStatus(): number {
        return this.moderationStatus;
    }

    getModerationDateSuspension(): Date {
        return this.moderationDateSuspension;
    }

    getSubscription(): Subscription {
        return this.subscription;
    }

    getBirthdate(): Date {
        return this.birthdate;
    }

    getRegisterDate(): Date {
        return this.registerDate;
    }

    getCity(): string {
        return this.city;
    }

    getCountry(): string {
        return this.country;
    }

    getIp(): string {
        return this.ip;
    }

    /**
     * Is suspended ?
     */
    isSuspended(): boolean {
        // Status suspended
        if (this.moderationStatus !== User.MODERATION_STATUS_SUSPENDED) {
            return false;
        }

        // Suspension expired ?
        if (new Date() > this.moderationDateSuspension) {
            return false;
        }

        return true;
    }

    /**
     * Is banned ?
     */
    isBanned(): boolean {
        return this.moderationStatus === User.MODERATION_STATUS_BANNED;
    }

    /**
     * Is ghosted ?
     */
    isGhosted(): boolean {
        return this.moderationStatus === User.MODERATION_STATUS_GHOSTED;
    }

    getFrustrationRule(): FrustrationRule {
        return this.frustrationRule;
    }

    getCoins(): number {
        return this.coins;
    }

    isSubscribed(): boolean {
        return this.subscription && this.subscription.isSubscribed();
    }

    getClassLimitToAnswer(): string {
        if (!this.lastMessageTime) {
            return null;
        }
        const timeDifference = Math.trunc((new Date().getTime() - this.lastMessageTime.getTime()) / 1000);

        if (timeDifference > 39) {
            return 'text-danger';
        } else if (timeDifference > 19) {
            return 'text-warning';
        } else if (timeDifference > 9) {
            return '';
        } else {
            return 'text-success';
        }
    }

    getTimeLimitToAnswer(): number {
        if (!this.lastMessageTime) {
            return null;
        }
        const timeDifference = Math.trunc((new Date().getTime() - this.lastMessageTime.getTime()) / 1000);

        if (timeDifference > 59) {
            return 0;
        } else if (timeDifference > 39) {
            return 60 - timeDifference;
        } else if (timeDifference > 19) {
            return 40 - timeDifference;
        } else if (timeDifference > 9) {
            return 20 - timeDifference;
        } else {
            return 10 - timeDifference;
        }
    }

    getUnansweredRooms(): number {
        return this.unansweredRooms;
    }

    incUnansweredRooms() {
        ++this.unansweredRooms;
    }

    getUnreadMessages(): number {
        return this.unreadMessages;
    }

    incUnreadMessages() {
        ++this.unreadMessages;
    }

    subUnreadMessages(count: number) {
        if (this.unreadMessages > count) {
            this.unreadMessages -= count;
        } else {
            this.unreadMessages = 0;
        }
    }

    setMeta(data: any) {
        this.unansweredRooms = data.unansweredRooms;
        this.unreadMessages = data.unreadMessages;
    }

    resetMeta() {
        this.unansweredRooms = 0;
        this.unreadMessages = 0;
    }
}

