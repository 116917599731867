import {Injectable} from '@angular/core';
import {ElapsedTimeComponent} from '../../components/panel/animation/elapsed-time/elapsed-time.component';

@Injectable({
    providedIn: 'root'
})
export class ElapsedTimeService {

    private timers: ElapsedTimeComponent[] = [];

    constructor() {
        setInterval(() => {
            this.refresh();
        }, 10000);
    }

    private refresh() {
        for (const timer of this.timers) {
            timer.refresh();
        }
    }

    public addTimer(timer: ElapsedTimeComponent) {
        this.timers.push(timer);
    }

    public removeTimer(timer: ElapsedTimeComponent) {
        const i = this.timers.indexOf(timer);
        if (i) {
            this.timers.splice(i, 1);
        }
    }
}
