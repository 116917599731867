import { Router } from '@angular/router';
import { UserManagementService } from '../user/user-management.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../user/user-management.service";
export class AuthGuard {
    constructor(router, userManagementService) {
        this.router = router;
        this.userManagementService = userManagementService;
    }
    canActivate(route, state) {
        const user = this.userManagementService.getCurrentUser();
        if (user === null) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return this.hasRight(user, state);
    }
    hasRight(currentUser, state) {
        if (state.url === '/') {
            return true;
        }
        if (state.url.startsWith('/animation') && currentUser.isAnimator()) {
            return true;
        }
        if (state.url.startsWith('/moderation') && currentUser.isModerator()) {
            return true;
        }
        if (state.url.startsWith('/operator') && (currentUser.isAdmin() || currentUser.isManager())) {
            return true;
        }
        if (state.url.startsWith('/dispatch-board') && (currentUser.isAdmin() || currentUser.isManager())) {
            return true;
        }
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.UserManagementService)); }, token: AuthGuard, providedIn: "root" });
