import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../api/response/api-response';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io/src/socket-io.service";
export class StatisticService {
    constructor(socket) {
        this.socket = socket;
    }
    getRooms() {
        return new Observable((observer) => {
            this.socket.emit('operator_room_statistic', {}, (result) => {
                observer.next(new ApiResponse().deserialize(result));
            });
        });
    }
}
StatisticService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StatisticService_Factory() { return new StatisticService(i0.ɵɵinject(i1.WrappedSocket)); }, token: StatisticService, providedIn: "root" });
