<div class="utilisateur row no-gutters">
    <div class="col-12">
        <div class="users-img rollover-container"
             (mouseenter)="displayModerationButtons = currentUser.getId()"
             (mouseleave)="displayModerationButtons = 0">
            <img src="{{ currentUser | userImage: '78x78' }}"/>
            <div class="sex">
                <i [class]="currentUser.getGenderClass()"></i>
            </div>

            <div *ngIf="displayModerationButtons === currentUser.getId()"
                 class="menu forProfile">
                <button (click)="actionUser(currentUser,'alert')"
                        class="btn btn-primary mr-1 text-white action"
                        title="{{ 'button.warning' | translate }}">
                    <i class="fa fa-flag"></i>
                </button>

                <span *ngIf="!(currentUser.isSuspended())">
                    <button (click)="actionUser(currentUser,'suspendre')"
                            class="btn btn-primary mr-1 text-white action"
                            title="{{ 'button.stop' | translate }}">
                        <i class="fa fa-clock"></i>
                    </button>
                </span>

                <span *ngIf="(currentUser.isSuspended())">
                    <button (click)="unsuspend(currentUser)"
                            class="btn btn-primary mr-1 text-white action"
                            title="{{ 'button.unstop' | translate }}">
                        <i class="fa fa-clock text-danger"></i>
                    </button>
                </span>

                <span *ngIf="!(currentUser.isBanned())">
                    <button (click)="actionUser(currentUser,'bannir')"
                            class="btn btn-primary mr-1 text-white action"
                            title="{{ 'button.bannish' | translate }}">
                        <i class="fa fa-ban"></i>
                    </button>
                </span>

                <span *ngIf="(currentUser.isBanned())">
                    <button (click)="unban(currentUser)"
                            class="btn btn-primary mr-1 text-white action"
                            title="{{ 'button.unbannish' | translate }}">
                        <i class="fa fa-ban text-danger"></i>
                    </button>
                </span>

                <button (click)="actionUser(currentUser,'profil')"
                        class="btn btn-primary mr-1 text-white action"
                        title="{{ 'button.display' | translate }}">
                    <i class="fa fa-user"></i>
                </button>

                <span *ngIf="!(currentUser.isGhosted())">
                    <button [class.mr-1]="currentUser.isGhosted()"
                            (click)="actionUser(currentUser,'ghost')"
                            class="btn btn-primary text-white action"
                            title="{{ 'button.ghost_user' | translate }}">
                        <i class="fab fa-snapchat-ghost"></i>
                    </button>
                </span>

                <span *ngIf="currentUser.isGhosted()">
                    <button (click)="unghost(currentUser)"
                            class="btn btn-primary action"
                            title="{{ 'button.unghost' | translate }}">
                        <i class="fab fa-snapchat-ghost text-danger"></i>
                    </button>
                </span>
            </div>
        </div>

        <div class="users-detail text-truncate text-uppercase">
            <span class="pseudo">{{ currentUser | profile: ['username'] }}</span> ({{ currentUser | profile: ['gender', 'age'] }})
            <br>

            <strong>{{ 'localization' | translate }} : </strong>
            <span>{{ currentUser | profile: ['city', 'country'] }}</span>
            <br>
            <span><strong>IP :</strong> {{ currentUser | profile: ['ip'] }}
                <br></span>
            <strong>{{ 'registered_since_the' | translate }}
                :</strong> {{ currentUser.date | date:'dd/MM/yy' }}
        </div>
    </div>
</div>
