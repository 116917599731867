import {Component} from '@angular/core';
import {Room} from '../../../../models/room/room';
import {ChatService} from '../../../../services/chat/chat.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-room-list',
    templateUrl: './room-list.component.html',
    styleUrls: ['./room-list.component.css']
})
export class RoomListComponent {

    rooms: Room[] = [];

    private hasInfiniteScroll = true;

    private loadingRooms = false;

    private getMoreRoomsLoading = false;

    private moreRoomsTime = null;

    private newRoomLoaded = null;

    constructor(
        private chatService: ChatService,
        private translateService: TranslateService
    ) {
        this.rooms = this.chatService.getRealRooms();
    }

    selectRoom(room: Room) {
        this.chatService.selectRoom(room);
    }

    isSelectedRoom(room: Room): boolean {
        return this.chatService.getCurrentRoom() &&
            this.chatService.getCurrentRoom().getId() === room.getId();
    }

    canInfiniteScroll() {
        return this.hasInfiniteScroll;
    }

    isLoadingRooms() {
        return this.loadingRooms;
    }

    showNewRoomLoaded() {
        return this.newRoomLoaded !== null;
    }

    writeNewRoomNb() {
        if (this.newRoomLoaded === 0) {
            return this.translateService.instant('chat.room.not_new');
        }

        return this.translateService.instant('chat.room.loaded_room', {
            number: this.newRoomLoaded,
            count: this.newRoomLoaded
        });
    }

    onScrollRooms() {
        const container = document.querySelector('#sidebar-left .connectes');
        if (!container) {
            return;
        }
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const containerScrollHeight = container.scrollHeight;

        if (scrollTop + containerHeight >= containerScrollHeight) {
            this.moreConversations();
        }
    }

    canReceiveNotifications() {
        return this.chatService.canReceiveNotifications();
    }

    hasMaxRooms() {
        return this.rooms.length === this.chatService.roomsConfig.max;
    }

    moreConversations() {
        if (this.canInfiniteScroll() && this.canLoadMoreRooms() && this.chatService.canReceiveNotifications()) {
            this.loadingRooms = true;

            this.getMoreRoomsLoading = true;

            this.moreRoomsTime = this.chatService.getTime();

            setTimeout(() => {
                this.chatService.loadMoreRooms(response => {
                    this.hasInfiniteScroll = response.nbRooms > 0;

                    this.loadingRooms = false;

                    this.newRoomLoaded = response.nbRooms;
                    setTimeout(() => {
                        this.newRoomLoaded = null;
                    }, 3500);

                    this.getMoreRoomsLoading = false;
                });
            }, 4000);
        }
    }

    private canLoadMoreRooms(): boolean {
        // Before anything else we're looking if we don't have too much rooms
        if (this.hasReachedMaxRoom()) {
            return false;
        }

        const now = this.chatService.getTime();

        if (this.moreRoomsTime !== null && typeof this.moreRoomsTime !== 'undefined') {
            if ((now - this.moreRoomsTime) <= 1 || this.getMoreRoomsLoading !== false) {
                return false;
            }
        }

        return true;
    }

    /**
     * Read the function name carefully
     * Return false if we can load more room
     * Return true  if we already have enough room
     * >> Will also calculate the quantity of room we can request with moreConversations
     */
     private hasReachedMaxRoom(): boolean {
        this.chatService.moderationRoomNeeded = this.chatService.roomsConfig.max - this.chatService.realRooms.length;

        if (this.chatService.moderationRoomNeeded > 50) {
            this.chatService.moderationRoomNeeded = 50;
        } else if (this.chatService.moderationRoomNeeded < 50) {
            for (const roomId in this.chatService.inactiveRooms) {
                if (!this.chatService.inactiveRooms[roomId].readyToBeRemoved || !this.chatService.removeInactiveRoom(roomId)) {
                    continue;
                }

                ++this.chatService.moderationRoomNeeded;
                if (this.chatService.moderationRoomNeeded === 50) {
                    break;
                }
            }
        }

        return this.chatService.moderationRoomNeeded === 0;
    }
}
