import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserManagementService} from '../../../../services/user/user-management.service';
import {ChatService} from '../../../../services/chat/chat.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-animation-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {

    /**
     * CurrentUser
     */
    currentUser: any = false;
    /**
     * Context user needed for relationship and photos
     */
    withUser: any = false;
    /**
     * Check if the form is submit or not
     */
    submitted = false;
    /**
     * Loading spinner
     */
    loading = false;
    click = true;
    display: string;
    isFake = false;
    moderating = false;

    /**
     * Action form for each action
     */
    actionAlertForm; // Alert
    actionSuspendForm; // Suspend
    actionBannedForm; // Banned
    actionGhostForm; // Ghost
    actionCensuredForm; // Censured
    actionMessageForm; // Send private message

    selectedFakeProfileId;

    /**
     * Informations Details of current clicked user
     */
    profileDetails: any;

    currentTab = 'about';

    /**
     *
     * @param data Data
     * @param formBuilder FormBuilder
     * @param chatService ChatService
     * @param dialogRef ActionComponent Dialog
     * @param toastrService ToastrService
     * @param translateService
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private chatService: ChatService,
                private dialogRef: MatDialogRef<ActionComponent>,
                private toastrService: ToastrService,
                private translateService: TranslateService) {
        this.selectedFakeProfileId = null;
        this.currentUser = data.zmUserProfile;
        this.withUser = data.withUser;
        this.display = data.display;

        // Launch the spinner
        this.loading = true;

        // Get all informations available for the target user
        this.chatService.retrieveUserProfilesDetails(this.currentUser.id, this.withUser ? this.withUser.id : null);
        this.chatService.getUserInformationDetail().subscribe((userDetails: any) => {
            this.profileDetails = userDetails;
            this.loading = false;
        });

        /**
         * All actions
         */
        this.actionAlertFormInit();
        this.actionSuspendFormInit();
        this.actionBannedFormInit();
        this.actionGhostFormInit();
        this.actionCensuredFormInit();
        this.actionMessage();
    }

    setTab(tab: string) {
        this.currentTab = tab;
    }

    isTab(tab: string) {
        return this.currentTab === tab;
    }

    // convenience getter for easy access to form fields
    get fAlert() {
        return this.actionAlertForm.controls;
    }

    // convenience getter for easy access to form fields
    get fGhost() {
        return this.actionGhostForm.controls;
    }

    // convenience getter for easy access to form fields
    get fCensured() {
        return this.actionCensuredForm.controls;
    }

    // convenience getter for easy access to form fields
    get fSuspend() {
        return this.actionSuspendForm.controls;
    }

    // convenience getter for easy access to form fields
    get fBanned() {
        return this.actionBannedForm.controls;
    }

    // convenience getter for easy access to form fields
    get fMessage() {
        return this.actionMessageForm.controls;
    }

    /**
     * Send message to target user with the selected fakeProfile
     */
    actionMessage() {

        // liste de tous les fakes profiles :
        const listOfFakeProfile = this.chatService.getFakeProfileRelatedToUserProductAndNiche(this.currentUser);

        // récupérer les ids des fakes profiles disponibles (sans conversation)
        this.chatService.getFakeProfilesAvailable(this.currentUser.id, (ids) => {
            const availableFakeProfiles = [];

            // Récupérer les fakes profiles uniquement s'ils sont parmis les ids des fakes dispo :
            for (const fakeUser of listOfFakeProfile) {
                if (ids.find(id => fakeUser && id === fakeUser.getId())) {
                    availableFakeProfiles.push(fakeUser);
                }
            }

            this.currentUser.setUsersFakeAvailable(availableFakeProfiles);
        });

        this.actionMessageForm = this.formBuilder.group({
            message: ['']
        });
    }

    actionBannedFormInit() {
        this.actionBannedForm = this.formBuilder.group({
            actionBanned: ['', Validators.required],
            actionBannedMessage: ['']
        });
    }

    actionGhostFormInit() {
        this.actionGhostForm = this.formBuilder.group({
            actionGhost: ['', Validators.required],
            actionGhostMessage: ['']
        });
    }

    actionCensuredFormInit() {
        this.actionCensuredForm = this.formBuilder.group({
            actionCensured: ['', Validators.required],
            actionCensuredInform: ['', Validators.required],
            actionCensuredMessage: ['', Validators.required]
        });
    }

    actionSuspendFormInit() {
        this.actionSuspendForm = this.formBuilder.group({
            actionSuspendMessage: [''],
            actionSuspendTime: ['', Validators.required],
            actionSuspendType: ['', Validators.required]
        });
    }

    actionAlertFormInit() {
        this.actionAlertForm = this.formBuilder.group({
            actionAvertissement: ['', Validators.required],
            actionAvertissementMessage: ['', Validators.required]
        });
    }

    ngOnInit() {
        // Get Profile
        if (this.display === 'profil') {
            this.loading = true;
        }
        // this.actionForm = this.formBuilder.group({});
        this.isFake = (this.currentUser.isFakeProfile === 1);

        if (this.withUser) {
            this.moderating = !this.currentUser.fake && !this.withUser.fake;
        }
    }

    displayModal(type) {
        this.display = type;
    }


    /**
     * Comme form part for every actions
     *
     * @param form Form
     * @param type Type
     * @param event Event
     */
    commontFormPart(form, type, event) {
        if (confirm(this.translateService.instant('animation.action.confirm'))) {
            // The form is already submit
            this.submitted = true;

            // stop here if form is invalid
            if (form.invalid) {
                this.toastrService.error(
                    this.translateService.instant('animation.action.required_fields')
                );
                return;
            }

            // Process in progress, display the spinner
            this.loading = true;

            switch (type) {
                case 'alert':
                    // Add user to suspended list
                    this.chatService.warnUser({
                        uid: this.currentUser.uid,
                        type: this.fAlert.actionAvertissement.value,
                        message: this.fAlert.actionAvertissementMessage.value,
                        user: this.currentUser
                    });
                    break;
                case 'suspend':
                    // Add user to suspended list
                    this.chatService.suspendUser({
                        uid: this.currentUser.uid,
                        type: this.fSuspend.actionSuspendType.value,
                        message: this.fSuspend.actionSuspendMessage.value,
                        minute: this.fSuspend.actionSuspendTime.value,
                        user: this.currentUser
                    });
                    break;
                case 'banned':
                    // Add user to suspended list
                    this.chatService.banUser({
                        uid: this.currentUser.uid,
                        type: this.fBanned.actionBanned.value,
                        message: this.fBanned.actionBannedMessage.value,
                        user: this.currentUser
                    });
                    break;
                case 'ghost':
                    this.chatService.ghostUser(
                        this.currentUser,
                        this.fGhost.actionGhost.value,
                        this.fGhost.actionGhostMessage.value
                    ).subscribe();
                    break;
                case 'censured':
                    break;
                case 'message':
                    if (typeof this.selectedFakeProfileId === 'number') {
                        const message = event.target.querySelector('#dialogue').textContent;
                        this.chatService.sendMessage(this.currentUser.id, this.selectedFakeProfileId, message);
                    } else {
                        this.toastrService.error(
                            this.translateService.instant('animation.action.choose_fakeprofile'),
                            this.translateService.instant('animation.action.send_message') + ' ' + this.currentUser.username
                        );
                        return;
                    }
                    break;
            }

            form.reset();
            this.submitted = false;

            // Close the modal
            this.dialogRef.close();
        }
    }

    setFakeUser(fakeId) {
        if (this.selectedFakeProfileId === fakeId) {
            this.selectedFakeProfileId = [];
        } else {
            this.selectedFakeProfileId = fakeId;
        }
    }

    alertSubmit(event) {
        this.commontFormPart(this.actionAlertForm, 'alert', event);
    }

    bannedSubmit(event) {
        this.commontFormPart(this.actionBannedForm, 'banned', event);
    }

    suspendSubmit(event) {
        this.commontFormPart(this.actionSuspendForm, 'suspend', event);
    }

    ghostSubmit(event) {
        this.commontFormPart(this.actionGhostForm, 'ghost', event);
    }

    censuredSubmit(event) {
        this.commontFormPart(this.actionCensuredForm, 'censured', event);
    }

    submitMessage($event) {
        $event.stopPropagation();
        this.fMessage.message.setValue(this.chatService.getMessageByEmojiReplaced(this.fMessage.message.value));

        return true;
    }

    sendMessage(userId, $event) {
        this.commontFormPart(this.actionMessageForm, 'message', $event);
    }

    displayProfileBetween(type, min, max) {

        if ((typeof min !== 'undefined') && (typeof max !== 'undefined')) {

            const toDisplay = this.translateService.instant('range', {min, max});
            // const toDisplay = 'Entre ' + min + ' et ' + max;

            switch (type) {
                case 'age':
                    return toDisplay + ' ' + this.translateService.instant('years_old');
                case 'size':
                    return toDisplay + ' ' + this.translateService.instant('cm');
                case 'weight':
                    return toDisplay + ' ' + this.translateService.instant('kg');
            }
        } else {
            return this.translateService.instant('animation.action.profile.no_info');
            // return 'Information non renseignée';
        }
    }

    textToEmoji($event) {
        const target = $event.target || $event.srcElement || $event.currentTarget;
        const text = this.chatService.textToEmoji(this.fMessage.message.value, target);

        if (text !== this.fMessage.message.value) {
            this.fMessage.message.setValue(text);
        }
    }

    shortcut($event) {
        switch ($event.key) {
            case 'Enter':
                const element: HTMLElement = document.querySelector('#submit-new-message') as HTMLElement;
                element.click();
                $event.preventDefault();
                break;
            default:
                break;
        }
    }

    switchEmojisShow() {
        this.chatService.switchEmojisShow();
    }

    getEmojiShow() {
        return this.chatService.emojisShow;
    }

    onClickedOutside(e: Event) {
        if (this.chatService.emojisShow) {
            this.chatService.emojisShow = false;
        }
    }
}
