/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./moderation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./moderation-item/moderation-item.component.ngfactory";
import * as i4 from "./moderation-item/moderation-item.component";
import * as i5 from "../../../services/moderation/moderation.service";
import * as i6 from "../../../services/modal/modal.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "ngx-toastr";
import * as i9 from "@angular/common";
import * as i10 from "./moderation.component";
var styles_ModerationComponent = [i0.styles];
var RenderType_ModerationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModerationComponent, data: {} });
export { RenderType_ModerationComponent as RenderType_ModerationComponent };
function View_ModerationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "nomoderation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("moderation.nothing_to_moderate")); _ck(_v, 2, 0, currVal_0); }); }
function View_ModerationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-moderation-item", [], null, [[null, "deleted"], ["body", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onBodyClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleted" === en)) {
        var pd_1 = (_co.removeModeration(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ModerationItemComponent_0, i3.RenderType_ModerationItemComponent)), i1.ɵdid(1, 638976, [[1, 4], ["moderationItemComponent", 4]], 0, i4.ModerationItemComponent, [i5.ModerationService, i6.ModalService, i2.TranslateService, i7.MatDialog, i8.ToastrService], { moderation: [0, "moderation"] }, { deleted: "deleted" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModerationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { moderationItemComponents: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container-fluid main-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-12 chatroom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "row"], ["style", "padding:0 5px;margin-top:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModerationComponent_1)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModerationComponent_2)), i1.ɵdid(9, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.getModerations().length === 0); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.getModerations(); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ModerationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-moderation", [], null, null, null, View_ModerationComponent_0, RenderType_ModerationComponent)), i1.ɵdid(1, 245760, null, 0, i10.ModerationComponent, [i5.ModerationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModerationComponentNgFactory = i1.ɵccf("app-moderation", i10.ModerationComponent, View_ModerationComponent_Host_0, {}, {}, []);
export { ModerationComponentNgFactory as ModerationComponentNgFactory };
