import {Deserializable} from '../deserialize/deserializable.model';

export class ProductGroup implements Deserializable {
    /**
     * Id
     * @private
     */
    private id: number = null;

    /**
     * Name
     * @private
     */
    private name: string = null;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    setId(id: number) {
        this.id = id;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }
}
