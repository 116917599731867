import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NoteService} from '../../../../services/note/note.service';
import {ModalService} from '../../../../services/modal/modal.service';
import {Note} from '../../../../models/note/note';

@Component({
    selector: 'app-animation-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.css']
})
export class NoteComponent {

    /**
     * Notes attached to a given room
     */
    @Input()
    list: Note[];

    /**
     * Given room id
     */
    @Input()
    roomId: any;

    currentNote: Note = new Note();

    typeList = this.noteService.getTypeList();

    constructor(private translate: TranslateService, private noteService: NoteService, private modalService: ModalService) {
    }

    getTypeLabel(typeId) {
        const index = this.typeList.findIndex(type => +type.id === +typeId);
        if (this.typeList[index]) {
            return 'animation.note.selector.option.' + this.typeList[index].name;
        } else {
            console.error(typeId);
        }
    }

    onSubmit() {
        if (!this.currentNote.getTitle()) {
            return false;
        }

        // Workaround for angular not supporting :number with <select>
        this.currentNote.setType(+this.currentNote.getType());

        if (this.currentNote.getId()) {
            /**
             * Update
             */
            this.noteService.update({
                id: this.currentNote.getId(),
                type: this.currentNote.getType(),
                roomId: this.roomId,
                title: this.currentNote.getTitle()
            }, (response: any) => {
                if (response.success) {
                    const index = this.list.findIndex(d => +d.getId() === +this.currentNote.getId());

                    if (index > -1) {
                        this.list[index].setTitle(this.currentNote.getTitle());
                        this.list[index].setType(this.currentNote.getType());

                        this.resetNote();
                    }
                }
            });
        } else {
            /**
             * Insert
             */
            this.noteService.insert({
                type: this.currentNote.getType(),
                roomId: this.roomId,
                title: this.currentNote.getTitle()
            }, (response: any) => {
                if (response.success) {
                    const note = response.note;
                    this.currentNote.setId(note.id);
                    this.currentNote.setDate(new Date(note.date));

                    this.list.unshift(this.currentNote);

                    this.resetNote();
                }
            });
        }
    }

    edit(index) {
        this.currentNote = new Note().deserialize(this.list[index]);
    }

    /**
     * Delete selected note
     */
    delete(index) {
        if (!this.list[index]) {
            return false;
        }
        if (!this.modalService.message(this.translate.instant('chat.confirm_delete_note'))) {
            return false;
        }

        this.noteService.delete(this.list[index].getId(), (response: any) => {
            if (response.success) {
                if (this.currentNote && this.list[index].getId() === this.currentNote.getId()) {
                    this.resetNote();
                }

                this.list.splice(index, 1);
            }
        });
    }

    private resetNote() {
        this.currentNote = new Note();
    }
}
