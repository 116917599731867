<div class="connectes">
    <ul class="col-12">
        <li *ngFor="let user of getConnectedUsers() | sortBy: 'username':'asc' | filter:{ username: filterText } | filter:{ subscriptionId: filterStatus } : true; let i=index">
            <div class="utilisateur" (mouseenter)="displayModerationButtons = user.id"
                 (mouseleave)="displayModerationButtons = 0">
                <div class="users-img">
                    <img src="{{ user | userImage: '64x64' }}" alt="">
                    <span *ngIf="user.isGhosted()" class="fa-stack bg-danger">
                      <i class="fab fa-snapchat fa-stack-1x text-white"></i>
                    </span>
                </div>
                <div class="users-detail text-truncate">
                    <p>{{user.username | titlecase}} 
                        <i *ngIf="user" [ngClass]="user.getGenderClass()" class="pl-1 pr-1"></i><br>
                        <small>
                            <span class="text-capitalize">{{ user | profile: ['city', 'country', 'age'] }}</span>
                            <br>{{ 'status' | translate }} : {{ user.subscription.name | translate }}
                        </small>
                        <br/>
                        <i *ngIf="user.product.name" [className]="user.product.segment.getCssClass() + ' ' + user.product.segment.name">&nbsp;
                            <span class="product-name">{{user.product.segment.name }}</span>
                        </i>
                    </p>
                </div>
                <div class="icons" *ngIf="displayModerationButtons === user.id">
                    <!-- pour mettre en rouge  text-danger -->

                    <button (click)="actionUser(user,'profil')" class="btn" title="{{ 'button.display' | translate }}">
                        <i
                                class="fa fa-user"></i></button>
                    <button (click)="sendMessageToUser(user)" class="btn" title="{{ 'button.chat' | translate }}"><i
                            class="fa fa-comment"></i></button>
                    <button (click)="actionUser(user,'alert')" class="btn"
                            title="{{ 'button.warning' | translate }}"><i
                            class="fa fa-flag"></i></button>
                    <span *ngIf="!user.isSuspended()">
                    <button (click)="actionUser(user,'suspendre')" class="btn"
                            title="{{ 'button.stop' | translate }}"><i
                            class="fa fa-clock"></i></button></span>
                    <span *ngIf="user.isSuspended()">
                    <button (click)="unsuspend(user)" class="btn" title="{{ 'button.unstop' | translate }}"><i
                            class="fa fa-clock text-danger"></i></button></span>
                    <span *ngIf="!user.isBanned()">
                    <button (click)="actionUser(user,'bannir')" class="btn"
                            title="{{ 'button.bannish' | translate }}"><i
                            class="fa fa-ban"></i></button></span>
                    <span *ngIf="user.isBanned()">
                    <button (click)="unban(user)" class="btn"
                            title="{{ 'button.unbannish' | translate }}"><i
                            class="fa fa-ban text-danger"></i></button></span>
                    <span *ngIf="!user.isGhosted()">
                    <button (click)="actionUser(user,'ghost')" class="btn" title="{{ 'button.ghost' | translate }}"><i
                            class="fab fa-snapchat-ghost"></i>
                    </button></span>
                    <span *ngIf="user.isGhosted()">
                        <button (click)="unghost(user)" class="btn" title="{{ 'button.unghost' | translate }}"><i
                                class="fab fa-snapchat-ghost text-danger"></i>
                    </button>
                    </span>
                </div>
            </div>
        </li>

        <!--
        <li class="nohover">
            <div class="post-divider">
                <hr>
                <span>{{ 'title.internal_user' | translate }}</span>
            </div>
        </li>
        <li *ngFor="let fakeProfile of getFakeUsers() | sortBy: 'username':'asc' | filter:{ username: filterText } | filter:{subscriptionId: filterStatus } : true; let i = index">
            <div class="utilisateur" (mouseenter)="displayModerationButtons = fakeProfile.id"
                 (mouseleave)="displayModerationButtons = 0">
                <div class="users-img"><img [src]="fakeProfile | userImage: '64x64'" alt=""> <span [ngClass]="fakeProfile.isConnected()?'fa-stack bg-success':'fa-stack bg-secondary'"></span></div>
                <div class="users-detail text-truncate">
                    <p>{{ fakeProfile.username | titlecase }} <i
                            [ngClass]="fakeProfile.getGenderClass()"
                            class="pl-1 pr-1"></i>
                        <br>
                        <small>
                            <span>{{ fakeProfile | profile: ['city', 'country', 'age'] }}</span>
                            <br>{{ 'status' | translate }} : {{
                            fakeProfile.subscription.name }}
                        </small>
                        <br/><i *ngIf="fakeProfile.product.name"
                                [className]="fakeProfile.product.segment.getCssClass() + ' ' + fakeProfile.product.segment.name">&nbsp;
                            <span class="product-name">{{fakeProfile.product.segment.name }}</span></i>
                    </p>

                </div>
                <div class="icons" *ngIf="displayModerationButtons === fakeProfile.id">
                    <button (click)="actionUser(fakeProfile,'profil')" class="btn"
                            title="{{ 'button.display' | translate }}"><i
                            class="fa fa-user"></i></button>
                </div>
            </div>
        </li>
        -->
    </ul>
</div>
