import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserManagementService} from '../user/user-management.service';
import {Operator} from '../../models/operator/operator.model';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private userManagementService: UserManagementService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user: Operator = this.userManagementService.getCurrentUser();

        if (user === null) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }

        return this.hasRight(user, state);
    }

    hasRight(currentUser: Operator, state) {
        if (state.url === '/') {
            return true;
        }

        if (state.url.startsWith('/animation') && currentUser.isAnimator()) {
            return true;
        }

        if (state.url.startsWith('/moderation') && currentUser.isModerator()) {
            return true;
        }

        if (state.url.startsWith('/operator') && (currentUser.isAdmin() || currentUser.isManager())) {
            return true;
        }

        if (state.url.startsWith('/dispatch-board') && (currentUser.isAdmin() || currentUser.isManager())) {
            return true;
        }

        return false;
    }
}
