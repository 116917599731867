import {Deserializable} from '../deserialize/deserializable.model';

export class UserRight implements Deserializable {
    admin = false;
    animator = false;
    moderator = false;
    manager = false;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}
