<ul class="liste-smileys-category">
    <li *ngFor="let category of emojiList | keyvalue:keepOriginalOrder;">
        <a href="" (click)="switchEmojiCategory(category.key); $event.preventDefault()">
            <i class="fa fa-{{ category.value.class }}" [ngClass]="{'active': (category.key == emojiCategory)}"></i>
        </a>
    </li>
</ul>

<h2>{{ getTitle() }}</h2>
<ul class="liste-smileys">
    <li *ngFor="let emoji of getEmojis();">
        <button class="removeButtonStyle" type="button" (click)="pushSmiley(emoji); $event.preventDefault()"
                title="{{ emoji.str }}">
            <span [innerHTML]="getEmojiByBrowserSupport(emoji)"></span>
        </button>
    </li>
</ul>
