<div mat-dialog-content>
    <div class="modal-header">
        <h5 class="mb-0 font-weight-bold"><i class="fa fa-flag"></i> {{ 'crop_avatar' | translate }} {{
            username
            }}</h5>
    </div>

    <div class="position-relative">
        <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [resizeToWidth]="128"
                (imageLoaded)="imageLoaded()"
                (imageCropped)="imageCropped($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                imageBase64="{{croppedImageBase64}}"
        ></image-cropper>
    </div>


    <div>
        <div class="position-relative">
            <div class="row boutons-recadrage">
                <div class="col-6">
                    <button (click)="rotateRight()" class="btn btn-light btn-block"><i class="fa fa-undo"></i></button>
                </div>
                <div class="col-6">
                    <button (click)="rotateLeft()" class="btn btn-light btn-block"><i
                            class="fa fa-undo fa-flip-horizontal"></i>
                    </button>
                </div>
            </div>
        </div>
        <button (click)="saveCrop()" class="btn btn-primary btn-lg btn-block next mx-auto p-3 text-white mb-3">
            <i class="fa fa-check" aria-hidden="true"></i> {{ 'button.validate' | translate }}
        </button>

    </div>

    <div style="clear:both;"></div>
</div>