import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    private typeList = [
        {
            id: 1,
            name: 'hobbies',
        },
        {
            id: 2,
            name: 'tastes',
        },
        {
            id: 3,
            name: 'work',
        },
        {
            id: 4,
            name: 'family',
        },
        {
            id: 5,
            name: 'animals',
        },
        {
            id: 6,
            name: 'others',
        }
    ];

    public constructor(private socket: Socket) {}

    getTypeList(): any[] {
        return this.typeList;
    }

    insert(note, callback) {
        this.socket.emit('fake_room_note', {
            action: 'insert',
            note
        }, result => callback(result));
    }

    update(note, callback) {
        this.socket.emit('fake_room_note', {
            action: 'update',
            note
        }, result => callback(result));
    }

    delete(id: number, callback) {
        this.socket.emit('fake_room_note', {
            action: 'delete',
            id
        }, result => callback(result));
    }
}
