import {AbstractModeration} from './abstract-moderation';

/**
 * IdealPresentationModeration
 */
export class IdealPresentationModeration extends AbstractModeration {
    /**
     * Presentation
     * @private
     */
    private presentation: string = null;

    /**
     * Presentation
     * @private
     */
    private moderatedPresentation: string = null;

    constructor() {
        super(AbstractModeration.MODERATION_IDEAL_PRESENTATION);
    }

    setPresentation(presentation: string) {
        this.presentation = presentation;
    }

    getPresentation(): string {
        return this.presentation;
    }

    setModeratedPresentation(moderatedPresentation: string) {
        this.moderatedPresentation = moderatedPresentation;
    }

    getModeratedPresentation(): string {
        return this.moderatedPresentation;
    }

    getData(): any {
        if (this.moderatedPresentation) {
            return {
                presentation: this.moderatedPresentation
            };
        }

        return null;
    }

    getReasons(): string[] {
        return [
            'empty_text',
            'no_sense_text',
            'vulgarity',
            'violence',
            'personal_informations',
            'flood',
            'text_too_short',
            'soliciting'
        ];
    }
}
