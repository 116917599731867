import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {DynamicModerationTypeComponent} from '../dynamic-moderation-type.component';
import {AbstractModeration} from '../../../../../models/moderation/abstract-moderation';
import {IdealPresentationModeration} from '../../../../../models/moderation/ideal-presentation-moderation';

@Component({
    selector: 'app-ideal-presentation',
    templateUrl: './ideal-presentation.component.html',
    styleUrls: ['./ideal-presentation.component.css', '../moderation-type.component.css']
})
export class IdealPresentationComponent implements DynamicModerationTypeComponent {

    /**
     * Moderation
     */
    moderation: IdealPresentationModeration;

    /**
     * Edit mode
     */
    editMode = false;

    /**
     * Presentation text
     */
    presentation = '';

    /**
     * Reject buttons
     */
    rejectButtons = [
        {
            action: 'rejected',
            value: 'ko',
            icon: 'fas fa-times'
        }
    ];

    @ViewChild('textarea', {static: false}) textareaRef: ElementRef;

    constructor() {
    }

    setModeration(moderation: AbstractModeration) {
        this.moderation = moderation as IdealPresentationModeration;
        this.presentation = this.moderation.getPresentation();
    }

    enterToEditMode() {
        this.editMode = true;
        setTimeout(() => {
            this.textareaRef.nativeElement.focus();
        }, 0);
    }

    exitFromEditMode() {
        this.editMode = false;
    }

    canUndo(): boolean {
        return this.presentation !== this.moderation.getPresentation();
    }

    undo() {
        this.presentation = this.moderation.getPresentation();
    }

    accept(): string[] {
        if (this.presentation !== this.moderation.getPresentation()) {
            this.moderation.setModeratedPresentation(this.presentation);
        }

        return [];
    }

    reject(action: string, reasons: string[]): string[] {
        return [];
    }

    getRejectButtons(): object[] {
        return this.rejectButtons;
    }

    setLoadingChanged(event: EventEmitter<boolean>) {
    }
}
