import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Photo} from '../../../../../models/photo/photo';

@Component({
    selector: 'app-crop-photo',
    templateUrl: './crop-photo.component.html',
    styleUrls: ['./crop-photo.component.css']
})
export class CropPhotoComponent implements OnInit {

    @ViewChild('imageCropperComponent', {static: false}) imageCropperComponent: ImageCropperComponent;
    @ViewChild('buttonCrop', {static: false}) buttonCrop: ElementRef;

    photo: Photo = null;

    aspectRatio = 1;

    constructor(
        private dialogRef: MatDialogRef<CropPhotoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { photo: Photo, aspectRatio: number }
    ) {
        this.photo = new Photo().deserialize(this.data.photo);

        if (data.aspectRatio) {
            this.aspectRatio = data.aspectRatio;
        }
    }

    ngOnInit() {
    }

    imageCropped(event: ImageCroppedEvent) {
        this.photo.setBase64(event.base64);
    }

    getBase64(): string {
        return this.data.photo.getBase64();
    }

    rotateClockwise() {
        this.imageCropperComponent.rotateRight();
    }

    rotateCounterClockwise() {
        this.imageCropperComponent.rotateLeft();
    }

    crop() {
        this.dialogRef.close({
            photo: this.photo
        });
    }
}
