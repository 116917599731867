import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';
import {User} from '../../models/user/user';

@Pipe({
    name: 'userImage'
})
export class UserImagePipe implements PipeTransform {

    transform(user: User, size): any {

        if (user === null) {
            return 'assets/images/default_user_1.png';
        }

        if (user && user.photo === null) {
            if (user.isAMen()) {
                return 'assets/images/default_user_1.png';
            } else {
                return 'assets/images/default_user_2.png';
            }
        }

        if (user && user.photo && size) {
            const m = size.match(/([0-9]+)x([0-9]+)/);
            if (m.length === 3) {
                const width = m[1];
                const height = m[2];
                return environment.imageTools + width + 'x' + height + '.jpg?' + user.photo;
            }
        }

        return user ? user.photo : false;
    }
}
