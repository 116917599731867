import {Component, EventEmitter} from '@angular/core';
import {DynamicModerationTypeComponent} from '../dynamic-moderation-type.component';
import {AbstractModeration} from '../../../../../models/moderation/abstract-moderation';
import {ReportModeration} from '../../../../../models/moderation/report-moderation';
import {ModerationService} from '../../../../../services/moderation/moderation.service';

@Component({
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css', '../moderation-type.component.css']
})
export class ReportComponent implements DynamicModerationTypeComponent {

    /**
     * Moderation
     */
    moderation: ReportModeration;

    /**
     * Reject buttons
     */
    rejectButtons = [
        {
            action: 'warned',
            value: 'warn',
            icon: 'fas fa-exclamation-triangle'
        },
        {
            action: 'banned',
            value: 'ban',
            icon: 'fas fa-hand-paper'
        },
        {
            action: 'ghosted',
            value: 'ghost',
            icon: 'fab fa-snapchat-ghost'
        }
    ];

    constructor() {
    }

    setModeration(moderation: AbstractModeration) {
        this.moderation = moderation as ReportModeration;
    }

    accept(): string[] {
        return [];
    }

    reject(action: string, reasons: string[]): string[] {
        /**
         * Find the block Id from the first reason
         */
        if (action === 'banned' || action === 'warned' || action === 'ghosted') {
            if (reasons.length) {
                this.moderation.setBlockReasonType(this.getBlockReasonId(reasons[0]));
            }
        }

        return [];
    }

    getBlockReasonId(value: string): number {
        const blockReasons: any[] = ModerationService.getBlockReasons();
        for (const i in blockReasons) {
            if (blockReasons[i] && blockReasons[i].value === value) {
                return blockReasons[i].id;
            }
        }

        throw new Error('The reject reason ' + value + ' does not exist in block reasons');
    }

    getReasonText(): string {
        return 'moderation.report_reasons.' + this.moderation.getReasonId();
    }

    getRejectButtons(): object[] {
        return this.rejectButtons;
    }

    setLoadingChanged(event: EventEmitter<boolean>) {
    }
}
