/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nickname.component.css.shim.ngstyle";
import * as i1 from "../moderation-type.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./nickname.component";
var styles_NicknameComponent = [i0.styles, i1.styles];
var RenderType_NicknameComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_NicknameComponent, data: {} });
export { RenderType_NicknameComponent as RenderType_NicknameComponent };
function View_NicknameComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary text-white undo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.undo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-undo"]], null, null, null, null, null))], null, null); }
function View_NicknameComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enterToEditMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nickname; _ck(_v, 1, 0, currVal_0); }); }
function View_NicknameComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(4, 0, [[1, 0], ["text", 1]], null, 5, "input", [["class", "form-control w-100 texteditor"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "ngModelChange"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (_co.exitFromEditMode() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.nickname = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i2.ɵdid(5, 16384, null, 0, i4.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i2.ɵdid(7, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i2.ɵdid(9, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.nickname; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 2, 0, i2.ɵnov(_v, 3).transform("moderation.nickname_edit")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i2.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i2.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i2.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i2.ɵnov(_v, 9).ngClassValid; var currVal_6 = i2.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i2.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_NicknameComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(671088640, 1, { textRef: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 2, "span", [["class", "badge badge-light"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NicknameComponent_1)), i2.ɵdid(5, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 4, "div", [["class", "moderation-block"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NicknameComponent_2)), i2.ɵdid(8, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NicknameComponent_3)), i2.ɵdid(10, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.canUndo(); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.editMode; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 2, 0, i2.ɵnov(_v, 3).transform("moderation.nickname")); _ck(_v, 2, 0, currVal_0); }); }
export function View_NicknameComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NicknameComponent_0, RenderType_NicknameComponent)), i2.ɵdid(1, 49152, null, 0, i6.NicknameComponent, [], null, null)], null, null); }
var NicknameComponentNgFactory = i2.ɵccf("ng-component", i6.NicknameComponent, View_NicknameComponent_Host_0, {}, {}, []);
export { NicknameComponentNgFactory as NicknameComponentNgFactory };
