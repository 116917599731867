import { Socket } from 'ngx-socket-io';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io/src/socket-io.service";
export class NoteService {
    constructor(socket) {
        this.socket = socket;
        this.typeList = [
            {
                id: 1,
                name: 'hobbies',
            },
            {
                id: 2,
                name: 'tastes',
            },
            {
                id: 3,
                name: 'work',
            },
            {
                id: 4,
                name: 'family',
            },
            {
                id: 5,
                name: 'animals',
            },
            {
                id: 6,
                name: 'others',
            }
        ];
    }
    getTypeList() {
        return this.typeList;
    }
    insert(note, callback) {
        this.socket.emit('fake_room_note', {
            action: 'insert',
            note
        }, result => callback(result));
    }
    update(note, callback) {
        this.socket.emit('fake_room_note', {
            action: 'update',
            note
        }, result => callback(result));
    }
    delete(id, callback) {
        this.socket.emit('fake_room_note', {
            action: 'delete',
            id
        }, result => callback(result));
    }
}
NoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoteService_Factory() { return new NoteService(i0.ɵɵinject(i1.WrappedSocket)); }, token: NoteService, providedIn: "root" });
