// Core Component
import {BrowserModule, HAMMER_LOADER} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {environment} from '../environments/environment';
// Global app component
import {AppComponent} from './app.component';
// Page compoments
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/panel/home/home.component';
import {ModerationComponent} from './components/panel/moderation/moderation.component';
import {AnimationComponent} from './components/panel/animation/animation.component';
import {HeaderComponent} from './components/panel/header/header.component';
import {CalendarComponent} from './components/panel/animation/calendar/calendar.component';
import {NoteComponent} from './components/panel/animation/note/note.component';
import {ActionComponent} from './components/panel/animation/action/action.component';
import {ModerationTypeComponent} from './components/panel/moderation/type/moderation-type.component';
import {ElapsedTimeComponent} from './components/panel/animation/elapsed-time/elapsed-time.component';
// Ui components
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserListComponent} from './components/panel/chat/user-list/user-list.component';
import {DatePipe} from '@angular/common';
import {FilterPipe} from './helpers/filters/filter.pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
// Importing the pagination module for the application.
import {NgxPaginationModule} from 'ngx-pagination';
// Importing the pagination module for the application.
import {ContenteditableValueAccessorModule} from '@tinkoff/angular-contenteditable-accessor';
// Importing the click outside module for the application.
import {ClickOutsideModule} from 'ng-click-outside';
// FontAwesomeComponent
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

import {ForgottenPasswordComponent} from './components/login/forgotten-password/forgotten-password.component';
import {SortByPipe} from './helpers/filters/orderby.pipe';
import {ModerationDirective} from './helpers/directive/moderation.directive';
import {PresentationComponent} from './components/panel/moderation/type/presentation/presentation.component';
import {ToastrModule} from 'ngx-toastr';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncatePictureComponent} from './components/ui/truncate-picture/truncate-picture.component';
import {TruncatePictureModalComponent} from './components/ui/truncate-picture/modal/truncate-picture-modal.component';
import {StarRatingComponent} from './components/ui/star-rating/star-rating.component';
import {RoomComponent} from './components/panel/chat/room/room.component';
import {UserAlertsComponent} from './components/panel/header/user-alerts/user-alerts.component';
import {EmojiListComponent} from './components/ui/emoji-list/emoji-list.component';
import {FilterSubscriptionPipe} from './helpers/filters/filter-subscription.pipe';
import {UserDisconnectionComponent} from './components/panel/header/user-disconnection/user-disconnection.component';
import {UserProfileComponent} from './components/ui/user-profile/user-profile.component';
import {RealUserProfileComponent} from './components/ui/real-user-profile/real-user-profile.component';
import {OnlineSwitchComponent} from './components/panel/online-switch/online-switch.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RoomListComponent} from './components/panel/chat/room-list/room-list.component';
import {UserImagePipe} from './pipe/user-image/user-image.pipe';
import {MaterialModule} from './material/material.module';
import {ChatService} from './services/chat/chat.service';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {OperatorEditComponent} from './components/panel/admin/operator/operator-edit/operator-edit.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {OperatorListComponent} from './components/panel/admin/operator/operator-list.component';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';

import * as Sentry from '@sentry/browser';
import {StorageService} from './services/storage/storage.service';
import {ModalComponent} from './components/modal/modal.component';
import {AgePipe} from './helpers/filters/age.pipe';
import {ProfilePipe} from './helpers/filters/profile.pipe';
import {ModerationItemComponent} from './components/panel/moderation/moderation-item/moderation-item.component';
import {NicknameComponent} from './components/panel/moderation/type/nickname/nickname.component';
import {AlbumPhotoComponent} from './components/panel/moderation/type/album-photo/album-photo.component';
import {IdealPresentationComponent} from './components/panel/moderation/type/ideal-presentation/ideal-presentation.component';
import {ReportComponent} from './components/panel/moderation/type/report/report.component';
import {CoverPhotoComponent} from './components/panel/moderation/type/cover-photo/cover-photo.component';
import {ProfilePhotoComponent} from './components/panel/moderation/type/profile-photo/profile-photo.component';
import {GhostComponent} from './components/panel/moderation/modal/ghost/ghost.component';
import {CropPhotoComponent} from './components/panel/moderation/modal/crop-photo/crop-photo.component';
import {DropdownSelectComponent} from './components/ui/dropdown-select/dropdown-select.component';
import {OperatorAddComponent} from './components/panel/admin/operator/operator-add/operator-add.component';
import {OperatorAccountComponent} from './components/panel/admin/operator/element/operator-account/operator-account.component';
import {LoaderComponent} from './components/ui/loader/loader.component';
import {OperatorProductComponent} from './components/panel/admin/operator/element/operator-product/operator-product.component';
import { DispatchBoardComponent } from './components/panel/dispatch-board/dispatch-board.component';
import {TimerPipe} from './pipe/timer/timer.pipe';
import {AnimationListComponent} from './components/panel/chat/animation-list/animation-list.component';
import {MaxStringSizePipe} from './pipe/max-string-size/max-string-size.pipe';

// Sentry
if (environment.sentry.dsn) {
    Sentry.init({dsn: environment.sentry.dsn});
}

// SocketIo Connexion
const config: SocketIoConfig = {
    url: environment.chat.socket.url + ':' + environment.chat.socket.port,
    options: {
        origin: '*',
        reconnect: true,
        forceNew: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        autoConnect: true,
        secure: environment.chat.socket.secure,
        query: {
            chatVersion: ChatService.CHAT_VERSION.version,
            chatType: ChatService.CHAT_VERSION.type
        }
    }
};

// Add the library fas
// Now can be displayed inside view with => <fa-icon [icon]="['fas', 'heart']"></fa-icon>
library.add(fas);

const build = require('../build.json');

// loader module
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + build.version);
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
    }

    handleError(error) {
        if (environment.sentry.dsn) {
            Sentry.captureException(error.originalError || error, {
                tags: {
                    build
                }
            });
        } else {
            throw error;
        }
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        HomeComponent,
        ModerationComponent,
        AnimationComponent,
        UserListComponent,
        ForgottenPasswordComponent,
        CalendarComponent,
        NoteComponent,
        ActionComponent,
        FilterPipe,
        FilterSubscriptionPipe,
        SortByPipe,
        ModerationTypeComponent,
        ElapsedTimeComponent,
        ModerationDirective,
        PresentationComponent,
        NicknameComponent,
        AlbumPhotoComponent,
        TruncatePictureModalComponent,
        TruncatePictureComponent,
        StarRatingComponent,
        RoomComponent,
        UserAlertsComponent,
        EmojiListComponent,
        UserDisconnectionComponent,
        UserProfileComponent,
        RealUserProfileComponent,
        OnlineSwitchComponent,
        RoomListComponent,
        UserImagePipe,
        TimerPipe,
        OperatorEditComponent,
        OperatorListComponent,
        OperatorListComponent,
        ModalComponent,
        AgePipe,
        ProfilePipe,
        ModerationItemComponent,
        IdealPresentationComponent,
        ReportComponent,
        CoverPhotoComponent,
        ProfilePhotoComponent,
        GhostComponent,
        CropPhotoComponent,
        DropdownSelectComponent,
        OperatorAddComponent,
        OperatorAccountComponent,
        LoaderComponent,
        OperatorProductComponent,
        DispatchBoardComponent,
        AnimationListComponent,
        MaxStringSizePipe
    ],
    imports: [
        MaterialModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        FontAwesomeModule,
        SocketIoModule.forRoot(config),
        MatDialogModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        MatTooltipModule,
        ImageCropperModule,
        MatSnackBarModule,
        NgxPaginationModule,
        MatSlideToggleModule,
        ContenteditableValueAccessorModule,
        ClickOutsideModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatMenuModule,
        MatDialogModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        ImageCropperModule
    ],
    exports: [TranslateModule],
    providers: [
        AgePipe,
        DatePipe,
        TranslateService,
        {provide: ErrorHandler, useClass: SentryErrorHandler},
        // Dummy loader
        {
            provide: HAMMER_LOADER,
            useValue: () => new Promise(() => {})
        }
    ],
    entryComponents: [
        ActionComponent,
        PresentationComponent,
        NicknameComponent,
        AlbumPhotoComponent,
        IdealPresentationComponent,
        ReportComponent,
        CoverPhotoComponent,
        ProfilePhotoComponent,
        TruncatePictureModalComponent,
        StarRatingComponent,
        UserAlertsComponent,
        UserDisconnectionComponent,
        ModalComponent,
        GhostComponent,
        CropPhotoComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private translate: TranslateService, private storage: StorageService) {
        const languages = ['de', 'en', 'es', 'fr', 'it', 'pt'];
        translate.addLangs(languages);
        translate.setDefaultLang('fr');
        translate.use(storage.get('language') || 'fr');
    }
}
