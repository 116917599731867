<!-- Container -->
<div class="container-fluid main-section">
    <div class="btn-toolbar float-right">
        <div class="btn-group">
            <a (click)="loadRoomStatistic()" class="btn btn-primary text-white">
                <i class="fas fa-refresh"></i> Actualiser
            </a>
        </div>
    </div>

    <h1 class="mt-4 mb-1">Répartition des discussions par opérateur</h1>

    <app-loader [loading]="loading"></app-loader>

    <!-- Statistic table -->
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-dark table-hover mt-2 table-striped operator-list">
                    <thead>
                    <tr>
                        <th scope="col">Opérateur</th>
                        <th scope="col">Total</th>
                        <th scope="col">Abonnés</th>
                        <th scope="col">Non abonnés</th>
                        <th scope="col">A modérer</th>
                        <th *ngFor="let group of getGroups()" scope="col">{{ group.name }}</th>
                        <th>Messages envoyés (1h)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let statistic of getRoomStatistic()">
                        <td>{{ statistic.getOperator().name }}</td>
                        <td>{{ statistic.getTotal() }}</td>
                        <td>{{ statistic.getSubscribedCount() }}</td>
                        <td>{{ statistic.getFreemiumCount() }}</td>
                        <td>{{ statistic.getModerationCount() }}</td>
                        <th *ngFor="let group of getGroups()" scope="col">{{ statistic.getGroup()[group.id] }}</th>
                        <th>{{ statistic.getLastHourTotalMessage() }}</th>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>{{ getTotal().getTotal() }}</td>
                        <td>{{ getTotal().getSubscribedCount() + getPercent(getTotal().getSubscribedCount()) }}</td>
                        <td>{{ getTotal().getFreemiumCount() + getPercent(getTotal().getFreemiumCount()) }}</td>
                        <td>{{ getTotal().getModerationCount() + getPercent(getTotal().getModerationCount()) }}</td>
                        <th *ngFor="let group of getGroups()" scope="col">{{ getTotal().getGroup()[group.id] + getPercent(getTotal().getGroup()[group.id]) }}</th>
                        <td>{{ getTotal().getLastHourTotalMessage() }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- Statistic table -->

</div>
<!-- Container -->
