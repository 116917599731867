import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnInit {
    @Input() rating: number;
    @Input() count = 5;
    @Output() ratingChanged: EventEmitter<any> = new EventEmitter<any>();

    previewRating: number = null;

    ngOnInit() {
    }

    setRating(rating: number): void {
        this.rating = rating;
        this.ratingChanged.emit({
            rating
        });
    }

    getChecked(rating: number): boolean {
        if (this.previewRating) {
            return this.previewRating >= rating;
        }

        return this.rating >= rating;
    }

    setOver(rating: number) {
        this.previewRating = rating;
    }

    setOut() {
        this.previewRating = null;
    }

    getRatings(): number[] {
        const ratings = [];
        for (let i = 1; i <= this.count; i++) {
            ratings.push(i);
        }

        return ratings;
    }
}
