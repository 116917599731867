<div class="modal-container">
    <h1 mat-dialog-title class="title-center">
        {{ 'title.title_ghost' | translate }}
        <span class="fa fa-times-circle modal-close" mat-dialog-close></span>
    </h1>
    <div mat-dialog-content>
        <select class="form-control custom-select mb-2" [(ngModel)]="reason">
            <option [ngValue]="null">---{{ 'animation.action.warning.reason_block.choose_ghost' | translate }}---
            </option>
            <option *ngFor="let reason of getBlockReasons()"
                    [ngValue]="reason.id"> {{ 'moderation.reason.' + reason.value | translate }}</option>
        </select>
        <textarea class="form-control" rows="7" [(ngModel)]="message"></textarea>
    </div>
    <mat-dialog-actions class="action-button">
        <button class="btn btn-success" (click)="ghost()" [disabled]="!canGhost()">
            <i class="fas fa-check"></i>
            {{ 'button.ghost' | translate }}
        </button>
        <button class="btn btn-danger" mat-dialog-close>
            <i class="fas fa-times"></i>
            {{ 'modal.cancel' | translate }}
        </button>
    </mat-dialog-actions>
</div>
