import {Deserializable} from '../deserialize/deserializable.model';

export class Segment implements Deserializable {
    id: number;
    name: string;

    deserialize(input: any): this {
        // Assign input to our object BEFORE deserialize our cars to prevent already deserialized cars from being overwritten.
        // noinspection TypeScriptValidateTypes
        Object.assign(this, input);

        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getCssClass(): string {
        switch (this.name) {
            case 'love':
                return 'fa fa-heart';
            case 'sexy':
                return 'fa fa-fire';
            case 'casual':
                return 'fa fa-eye';
        }

        return null;
    }
}
