import {Component} from '@angular/core';
import {AbstractModeration} from '../../../../../models/moderation/abstract-moderation';
import {CoverPhotoModeration} from '../../../../../models/moderation/cover-photo-moderation';
import {PhotoModerationTypeComponent} from '../photo-moderation-type.component';
import {ModerationService} from '../../../../../services/moderation/moderation.service';
import {ModalService} from '../../../../../services/modal/modal.service';
import {MatDialog} from '@angular/material';

@Component({
    templateUrl: './cover-photo.component.html',
    styleUrls: ['./cover-photo.component.css', '../moderation-type.component.css']
})
export class CoverPhotoComponent extends PhotoModerationTypeComponent {

    /**
     * Moderation
     */
    moderation: CoverPhotoModeration;

    constructor(
        moderationService: ModerationService,
        modalService: ModalService,
        matDialog: MatDialog
    ) {
        super(moderationService, modalService, matDialog);
    }

    setModeration(moderation: AbstractModeration) {
        this.moderation = moderation as CoverPhotoModeration;
        this.photo = this.moderation.getPhoto();
    }

    accept(): string[] {
        if (this.newPhoto.getRating() === null) {
            return [
                'photo_rating_mandatory'
            ];
        }

        this.moderation.getPhoto().setRating(this.newPhoto.getRating());
        this.moderation.getPhoto().setBase64(this.newPhoto.getBase64());

        return [];
    }

    getAspectRatio(): number {
        return 7;
    }
}
