import {Component, OnInit} from '@angular/core';
import {StatisticService} from '../../../services/statistic/statistic.service';
import {ApiResponse} from '../../../api/response/api-response';
import {Statistic} from '../../../models/statistic/statistic';

@Component({
    selector: 'app-dispatch-board',
    templateUrl: './dispatch-board.component.html',
    styleUrls: ['./dispatch-board.component.css']
})
export class DispatchBoardComponent implements OnInit {

    private roomStatistic: Statistic[];
    private groups: any[];
    private total = new Statistic();

    loading = true;

    constructor(private statisticService: StatisticService) {
    }

    ngOnInit() {
        this.loadRoomStatistic();
    }

    getRoomStatistic(): Statistic[] {
        return this.roomStatistic || [];
    }

    getGroups(): any[] {
        return this.groups || [];
    }

    getPercent(stat): string {
        const percent = this.total.getTotal() === 0 ? 0 : ((stat * 100) / this.total.getTotal());
        return ' (' + Math.round(percent) + '%)';
    }

    getTotal(): Statistic {
        return this.total;
    }

    loadRoomStatistic() {
        this.loading = true;
        this.statisticService.getRooms().subscribe((response: ApiResponse) => {
            if (response.success) {
                this.roomStatistic = [];
                this.total = new Statistic();
                this.groups = response.data.groups;
                for (const data of response.data.statistic) {
                    this.roomStatistic.push(new Statistic().deserialize(data));
                    this.total.total += data.total;
                    this.total.subscribedCount += data.subscribedCount;
                    this.total.freemiumCount += data.freemiumCount;
                    this.total.moderationCount += data.moderationCount;
                    this.total.lastHourTotalMessage += data.lastHourTotalMessage;

                    for (const group of this.groups) {
                        if (!data.group[group.id]) {
                            continue;
                        }
                        if (!this.total.group[group.id]) {
                            this.total.group[group.id] = 0;
                        }
                        this.total.group[group.id] += data.group[group.id];
                    }
                }
            }

            this.loading = false;
        });
    }
}
