<span class="badge badge-warning">{{ 'moderation.report' | translate }}</span>
<div class="moderation-block warning">
    <div class="report-info">
        <i class="fas fa-exclamation-triangle text-warning"></i>
        {{ 'moderation.signaled_by' | translate }}<span>{{ moderation.getFromUser().getUsername() }}</span>
    </div>
    <span class="badge badge-danger">{{ getReasonText() | translate }}</span>
    <br>
    {{ moderation.getMessage() }}
</div>
