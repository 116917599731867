import {Deferred} from './deferred';

/**
 * FrustrationRule
 */
export class FrustrationRule {

    static readonly COIN_MODE = 'coin';
    static readonly SUBSCRIPTION_MODE = 'subscription';

    mode = FrustrationRule.SUBSCRIPTION_MODE;
    deferred: Deferred = null;

    deserialize(data: any): this {
        Object.assign(this, data);

        if (data && data.deferred) {
            this.deferred = new Deferred().deserialize(data.deferred);
        } else {
            this.deferred = null;
        }

        return this;
    }

    isSubscription(): boolean {
        return this.mode === FrustrationRule.SUBSCRIPTION_MODE;
    }

    isCoin(): boolean {
        return this.mode === FrustrationRule.COIN_MODE;
    }

    getDeferred(): Deferred {
        return this.deferred;
    }
}
