<div class="container-fluid main-section">
    <div class="btn-toolbar float-right">
        <div class="btn-group">
            <a [routerLink]="['/operator']" class="btn btn-primary text-white"><i class="fas fa-arrow-left"></i> {{ 'form.back' | translate }}</a>
        </div>
    </div>
    <h1 class="mt-4 mb-1">{{ 'form.edit_user' | translate }}</h1>
    <app-loader [loading]="loading"></app-loader>
    <div class="row">
        <div class="col-12">
            <!-- Onglets -->
            <ul class="nav nav-tabs" id="onglets" role="tablist">
                <li class="nav-item">
                    <span class="nav-link" [ngClass]="{active:activeTab==='account'}" (click)="activeTab='account'"><i
                            class="fas fa-user"></i> {{ 'form.general_informations' | translate }}</span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" [ngClass]="{active:activeTab==='product'}" (click)="activeTab='product'"><i
                            class="fas fa-folder"></i> {{ 'form.product_management' | translate }}</span>
                </li>
            </ul>
            <!-- Onglets -->

            <app-operator-account *ngIf="activeTab==='account'" [operator]="operator" [managers]="managers"
                                  (operatorChange)="save($event)"></app-operator-account>

            <app-operator-product *ngIf="activeTab==='product'" [operator]="operator"
                                  (operatorChange)="save($event)"></app-operator-product>
        </div>
    </div>
</div>
