import {Component, OnInit} from '@angular/core';
import {Operator} from '../../../../../models/operator/operator.model';
import {OperatorService} from '../../../../../services/operator/operator.service';
import {ModalService} from '../../../../../services/modal/modal.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-operator-add',
    templateUrl: './operator-add.component.html',
    styleUrls: ['./operator-add.component.css']
})
export class OperatorAddComponent implements OnInit {

    /**
     * Operator
     */
    operator: Operator = new Operator();

    /**
     * Loading
     */
    loading = false;

    /**
     * Managers
     */
    managers: Operator[] = [];

    constructor(
        private operatorService: OperatorService,
        private modalService: ModalService,
        private router: Router,
        private translationService: TranslateService
    ) {
    }

    ngOnInit() {
        this.loading = true;

        this.operatorService.getOperators({manager: true}).subscribe((managers: Operator[]) => {
            this.loading = false;
            this.managers = managers;
        });
    }

    save(operator: Operator) {
        this.loading = true;

        this.operatorService.updateOperator(operator).subscribe((id: number) => {
            this.modalService.openOk(
                this.translationService.instant('operator.saved_title'),
                this.translationService.instant('operator.saved_message')
            ).subscribe(() => {
                this.router.navigate(['/operator', id]).then();
            });
        }, (error) => {
            this.loading = false;
            this.modalService.openError(error).subscribe();
        });
    }
}
