import {Deserializable} from '../deserialize/deserializable.model';

export class Note implements Deserializable {
    id: number = null;
    roomId: number;
    type = 1;
    title = '';
    date: Date = null;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    setId(id: number): this {
        this.id = id;

        return this;
    }

    setType(type: number): this {
        this.type = type;

        return this;
    }

    setTitle(title: string): this {
        this.title = title;

        return this;
    }

    setDate(date: Date = new Date()): this {
        this.date = date;

        return this;
    }

    getId(): number {
        return this.id;
    }

    getType(): number {
        return this.type;
    }

    getTitle(): string {
        return this.title;
    }

    getDate(): Date {
        return this.date;
    }
}
