import {Deserializable} from '../deserialize/deserializable.model';

export class Statistic implements Deserializable {
    operator: any;
    subscribedCount = 0;
    freemiumCount = 0;
    moderationCount = 0;
    group: object = {};
    total = 0;
    lastHourTotalMessage = 0;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getOperator(): any {
        return this.operator;
    }

    getSubscribedCount(): number {
        return this.subscribedCount;
    }

    getFreemiumCount(): number {
        return this.freemiumCount;
    }

    getModerationCount(): number {
        return this.moderationCount;
    }

    getGroup(): {} {
        return this.group;
    }

    getTotal(): number {
        return this.total;
    }

    getLastHourTotalMessage(): number {
        return this.lastHourTotalMessage;
    }
}
