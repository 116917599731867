import {Component, Input} from '@angular/core';
import {ActionComponent} from '../../panel/animation/action/action.component';
import {MatDialog} from '@angular/material';
import {ActionService} from '../../../services/action/action.service';
import {User} from '../../../models/user/user';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
    selector: 'app-display-real-user-profile',
    templateUrl: './real-user-profile.component.html',
    styleUrls: ['./real-user-profile.component.css']
})
export class RealUserProfileComponent {
    @Input() currentUser: any;
    @Input() viewWithUser: any;

    /**
     * Display the buttons for a given message
     */
    displayModerationButtons = 0;

    constructor(
        private dialog: MatDialog,
        private actionService: ActionService,
        private confirmModal: ModalService,
        private translateService: TranslateService,
        private chatService: ChatService
    ) {}


    /**
     * Target action modal
     * @param zmUserProfile data from zmUser
     * @param display target name of the modal
     */
    actionUser(zmUserProfile, display) {

        const dialogRef = this.dialog.open(ActionComponent, {
            data: {zmUserProfile, display, withUser: this.viewWithUser},
            minWidth: '800px'
        });

        this.actionService.alertUser(zmUserProfile);
    }

    /**
     * Remove the user from the suspended user list
     * @param zmUser object
     */
    unsuspend(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unstop', {user: user.username}))) {
            this.chatService.reactiveUser(user, 'suspended').subscribe();
        }
    }

    /**
     * Remove the user from banned user list
     * @param zmUser object
     */
    unban(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unbannish', {user: user.username}))) {
            this.chatService.reactiveUser(user, 'banned').subscribe();
        }
    }

    /**
     * Remove the user from ghost list
     * @param zmUser object
     */
    unghost(user: User) {
        if (this.confirmModal.message(this.translateService.instant('chat.confirm_unghost'))) {
            this.chatService.reactiveUser(user, 'ghosted').subscribe();
        }
    }
}
