import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Operator} from '../../models/operator/operator.model';
import {SocketService} from '../socket/socket.service';
import {ApiResponse} from '../../api/response/api-response';
import {OperatorFilterInterface} from './operator-filter-interface';
import {Socket} from 'ngx-socket-io';

@Injectable({
    providedIn: 'root'
})
export class OperatorService {

    private connectedOperators: Operator[] = [];

    /**
     * @param socketService
     */
    constructor(
        private socket: Socket,
        private socketService: SocketService
    ) {
        this.initSocket();
    }

    /**
     * Init all socket event
     */
    initSocket() {
        this.socket.on('operators', (operators) => this.onOperators(operators));
    }

    /**
     * Receive the online operator list
     * @param operators
     * @private
     */
    private onOperators(operators: any[]) {
        this.connectedOperators = operators.map(o => new Operator().deserialize(o));
    }

    /**
     * Get connected operator list
     */
    getConnectedOperators(): Operator[] {
        return this.connectedOperators;
    }

    /**
     * Is operator online ?
     * @param operator
     */
    isOnline(operator: Operator): boolean {
        return this.connectedOperators.findIndex((o: Operator) => o.getId() === operator.getId()) !== -1;
    }

    /**
     * Get operator list
     */
    getOperators(filter?: OperatorFilterInterface): Observable<Operator[]> {
        return new Observable<Operator[]>((observer) => {
            this.socketService.sendApiRequest('get_operators', filter).subscribe((response: ApiResponse) => {
                if (response.success) {
                    observer.next(response.data.operators.map((operator: any) => new Operator().deserialize(operator)));
                } else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }

    /**
     * Get operator by id
     * @param id
     */
    getOperatorById(id: number): Observable<Operator> {
        return new Observable<Operator>((observer) => {
            this.getOperators({id}).subscribe((operators: Operator[]) => {
                observer.next(operators.length ? operators[0] : null);
            }, (error) => observer.error(error));
        });
    }

    /**
     * Update operator
     * @param operator
     */
    updateOperator(operator: Operator): Observable<number> {
        return new Observable<number>((observer) => {
            this.socketService.sendApiRequest('update_operator', {operator}).subscribe((response: ApiResponse) => {
                if (response.success) {
                    observer.next(response.data.id);
                } else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }

    /**
     * Delete operator
     * @param id
     */
    deleteOperator(id: number): Observable<void> {
        return new Observable<void>((observer) => {
            this.socketService.sendApiRequest('delete_operator', {id}).subscribe((response: ApiResponse) => {
                if (response.success) {
                    observer.next();
                } else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }

    /**
     * Supported languages
     */
    getLanguages(): string[] {
        return ['fr', 'es', 'it', 'pt', 'pt-br', 'de', 'en', 'en-us'];
    }

    /**
     * Check if username is available
     * @param operatorId
     * @param username
     */
    isUsernameAvailable(operatorId: number, username: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.socketService.sendApiRequest('check_operator_username', {
                id: operatorId,
                username
            }).subscribe((response: ApiResponse) => {
                if (response.success) {
                    observer.next(response.data.available);
                } else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
}
