import {Component, ElementRef, EventEmitter, ViewChild} from '@angular/core';
import {DynamicModerationTypeComponent} from '../dynamic-moderation-type.component';
import {AbstractModeration} from '../../../../../models/moderation/abstract-moderation';
import {NicknameModeration} from '../../../../../models/moderation/nickname-moderation';

@Component({
    templateUrl: './nickname.component.html',
    styleUrls: ['./nickname.component.css', '../moderation-type.component.css']
})
export class NicknameComponent implements DynamicModerationTypeComponent {

    /**
     * Moderation
     */
    moderation: NicknameModeration;

    /**
     * Edit mode
     */
    editMode = false;

    /**
     * Nickname
     */
    nickname = '';

    /**
     * Reject buttons
     */
    rejectButtons = [
        {
            action: 'rejected',
            value: 'ko',
            icon: 'fas fa-times'
        }
    ];

    @ViewChild('text', {static: false}) textRef: ElementRef;

    constructor() {
    }

    setModeration(moderation: AbstractModeration) {
        this.moderation = moderation as NicknameModeration;
        this.nickname = this.moderation.getNickname();
    }


    enterToEditMode() {
        this.editMode = true;
        setTimeout(() => {
            this.textRef.nativeElement.focus();
        }, 0);
    }

    exitFromEditMode() {
        this.editMode = false;
    }

    canUndo(): boolean {
        return this.nickname !== this.moderation.getNickname();
    }

    undo() {
        this.nickname = this.moderation.getNickname();
    }

    accept(): string[] {
        if (this.nickname !== this.moderation.getNickname()) {
            this.moderation.setModeratedNickname(this.nickname);
        }

        return [];
    }

    reject(action: string, reasons: string[]): string[] {
        return [];
    }

    getRejectButtons(): object[] {
        return this.rejectButtons;
    }

    setLoadingChanged(event: EventEmitter<boolean>) {
    }
}
