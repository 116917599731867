<div class="row calnote">
    <h5 class="font-weight-bold">
        <i [matTooltip]="'animation.note.subtitle' | translate" class="fa fa-info-circle float-left"></i>
        <i class="fa fa-sticky-note"></i> {{ 'title.note' | translate }}
    </h5>

    <div class="col-12">
        <form (ngSubmit)="onSubmit()" class="bg-dark p-2">
            <div class="row no-gutters">
                <div class="col-5 pr-1">
                    <div class="form-group text-left mb-0">
                        <select [(ngModel)]="currentNote.type" name="noteType" class="form-control" id="exampleFormControlSelect2">
                            <option [value]="noteType.id" *ngFor="let noteType of typeList">{{ 'animation.note.selector.option.' + noteType.name | translate }}</option>
                        </select>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group text-left mb-0">
                        <div class="input-group">
                            <input [(ngModel)]="currentNote.title" name="noteTitle" class="form-control"
                                   placeholder="{{ 'animation.note.write_note' | translate }}" type="text"/>

                            <span class="input-group-btn">
                                <button [matTooltip]="'button.save' | translate " class="btn btn-primary ml-1 text-white" type="submit">
                                    <i class="fa fa-save"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="col-12 mt-2 table-container">
        <table class="table table-dark table-hover">
            <thead>
            <tr>
                <th scope="col" style="width:100px;">{{ 'animation.note.list.type' | translate }}</th>
                <th scope="col">{{ 'animation.note.list.note' | translate }}</th>
                <th scope="col" style="width:120px;">{{ 'date' | translate }}</th>
                <th scope="col" style="width:80px;">{{ 'animation.note.list.action' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="!list || list.length === 0">
                <td colspan="4">{{ 'animation.note.list.none' | translate }}</td>
            </tr>
            <tr *ngFor="let note of list; let i = index;" [attr.data-index]="i">
                <td>{{ getTypeLabel(note.getType()) | translate }}</td>
                <td>{{ note.getTitle() }}</td>
                <td>{{ note.getDate() | date:'dd/MM/yy - HH:mm'}}</td>
                <td class="text-right">
                    <button (click)="edit(i)" class="btn btn-primary action text-white mr-2"
                            title="{{ 'button.edit' | translate }}"
                            data-toggle="modal" data-target="#editernote"><i class="fa fa-edit"></i>
                    </button>
                    <button (click)="delete(i)"
                            class="btn btn-primary action text-white" title="{{ 'button.delete' | translate }}"><i
                            class="fa fa-times-circle"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
