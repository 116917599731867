import {Deserializable} from '../deserialize/deserializable.model';
import {User} from '../user/user';
import {Message} from '../message/message';
import {Note} from '../note/note';

export class Room implements Deserializable {
    id: number = null;
    unreadMessage = 0;
    lastMessageId: number = null;
    lastMessage: string = null;
    lastMessageTime: Date = null;
    lastMessageUserId: number = null;
    messageId: number = null;
    messages: Message[] = [];
    user1: User = null;
    user2: User = null;
    loaded = false;
    typing = false;
    notes: Note[] = [];
    inactiveTimeout;
    readyToBeRemoved = false;
    time: Date = null;

    deserialize(input: any): this {
        // Assign input to our object BEFORE deserialize our cars to prevent already deserialized cars from being overwritten.
        // noinspection TypeScriptValidateTypes
        Object.assign(this, input);

        if (input.time) {
            this.time = new Date(input.time);
        }

        return this;
    }

    setId(id: number) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    setLastMessageId(lastMessageId: number) {
        this.lastMessageId = lastMessageId;
    }

    getLastMessageId(): number {
        return this.lastMessageId;
    }

    getLastMessageTime() {
        return this.lastMessageTime;
    }

    setLastMessageTime(lastMessageTime) {
        this.lastMessageTime = lastMessageTime;
    }

    setLastMessageUserId(id: number) {
        this.lastMessageUserId = id;
    }

    getLastMessageUserId(): number {
        return this.lastMessageUserId;
    }

    getUnreadMessage() {
        return this.unreadMessage;
    }

    setUnreadMessage(count) {
        this.unreadMessage = count;
    }

    addOneToUnreadMessage() {
        this.unreadMessage++;
    }

    resetUnreadMessage() {
        this.unreadMessage = 0;
    }

    setInactiveTimeout(callback) {
        this.removeInactiveTimeout();

        this.inactiveTimeout = setTimeout(() => {
            this.readyToBeRemoved = true;
            this.inactiveTimeout = null;
            callback();
        }, 10000);
    }

    removeInactiveTimeout() {
        this.readyToBeRemoved = false;

        if (!this.inactiveTimeout) {
            this.inactiveTimeout = null;
        }
    }

    getRoomId() {
        return this.id;
    }

    setRoomId(roomId) {
        this.id = roomId;
    }

    getLastMessage() {
        return this.lastMessage;
    }

    setLastMessage(lastMessage) {
        this.lastMessage = lastMessage;
    }

    getMessageId() {
        return this.messageId;
    }

    setMessageId(messageId) {
        this.messageId = messageId;
    }

    addMessage(message: Message) {
        const i = this.messages.findIndex((m: Message) => m.getId() === message.getId());
        if (i !== -1) {
            this.messages[i] = message;
        } else {
            this.messages.push(message);
        }
        this.inactiveTimeout = null;
    }

    getMessages() {
        return this.messages;
    }

    setUser1(user1: User) {
        this.user1 = user1;
    }

    getUser1(): User {
        return this.user1;
    }

    setUser2(user2: User) {
        this.user2 = user2;
    }

    getUser2(): User {
        return this.user2;
    }

    getMe(): User {
        if (this.user1 && this.user1.isFake()) {
            return this.user1;
        } else if (this.user2 && this.user2.isFake()) {
            return this.user2;
        }

        return null;
    }

    getOther(): User {
        if (this.user1 && this.user1.isFake()) {
            return this.user2;
        } else if (this.user2 && this.user2.isFake()) {
            return this.user1;
        }

        return null;
    }

    getReal(): User {
        if (this.user1 && !this.user1.isFake()) {
            return this.user1;
        } else if (this.user2 && !this.user2.isFake()) {
            return this.user2;
        }

        return null;
    }

    setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    setNotes(notes) {
        for (const data of notes) {
            const note = new Note();

            note.setId(data.id);
            note.setType(data.type);
            note.setTitle(data.title);
            note.setDate(data.date);

            this.notes.push(note);
        }
    }

    getNotes() {
        return this.notes;
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    setTyping(typing: boolean) {
        this.typing = typing;
    }

    isTyping(): boolean {
        return this.typing;
    }

    /**
     * Remove a message
     * @param messageId
     */
    removeMessage(messageId: number) {
        const i = this.messages.findIndex((message: Message) => message.id === messageId);
        if (i !== -1) {
            this.messages.splice(i, 1);
        }
    }

    isAnswered(): boolean {
        return this.getMe() && this.getMe().getId() === this.lastMessageUserId;
    }
}
