import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VersionService {
    constructor(http) {
        this.http = http;
        this.buildVersion = null;
        const build = require('../../../build.json');
        this.buildVersion = build.date;
    }
    getBuildDate() {
        return this.buildVersion;
    }
}
VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
