import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../api/response/api-response';
import {ProductGroup} from '../../models/product/product-group';
import {SocketService} from '../socket/socket.service';

@Injectable({
    providedIn: 'root'
})
export class ProductGroupService {

    constructor(
        private socketService: SocketService
    ) {
    }

    /**
     * Get product group list
     */
    getProductGroups(): Observable<ProductGroup[]> {
        return new Observable<ProductGroup[]>((observer) => {
            this.socketService.sendApiRequest('get_product_groups').subscribe((response: ApiResponse) => {
                if (response.success) {
                    observer.next(response.data.productGroups.map((productGroup: any) => new ProductGroup().deserialize(productGroup)));
                } else {
                    observer.error(response.error);
                }
            }, (error) => observer.error(error));
        });
    }
}
