import { AbstractModeration } from '../../models/moderation/abstract-moderation';
import { Observable } from 'rxjs';
import { User } from '../../models/user/user';
import { PresentationModeration } from '../../models/moderation/presentation-moderation';
import { NicknameModeration } from '../../models/moderation/nickname-moderation';
import { AlbumPhotoModeration } from '../../models/moderation/album-photo-moderation';
import { IdealPresentationModeration } from '../../models/moderation/ideal-presentation-moderation';
import { ReportModeration } from '../../models/moderation/report-moderation';
import { CoverPhotoModeration } from '../../models/moderation/cover-photo-moderation';
import { ProfilePhotoModeration } from '../../models/moderation/profile-photo-moderation';
import { Photo } from '../../models/photo/photo';
import * as i0 from "@angular/core";
export class ModerationService {
    constructor() {
        this.moderations = [];
        this.chatService = null;
    }
    static getBlockReasons() {
        return ModerationService.blockReasons;
    }
    /**
     * Prevent circular dependency
     * @param chatService
     */
    setChatService(chatService) {
        this.chatService = chatService;
    }
    /**
     * Parse moderations data
     * @param dataModerations
     */
    parseModerationsData(dataModerations) {
        for (const dataModeration of dataModerations) {
            if (this.moderations.findIndex((m) => m.getId() === dataModeration.id) !== -1) {
                continue;
            }
            const user = new User().deserialize(dataModeration.user);
            let moderation = null;
            switch (dataModeration.type) {
                /**
                 * Presentation
                 */
                case AbstractModeration.MODERATION_PRESENTATION:
                    moderation = new PresentationModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setPresentation(dataModeration.presentation);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Ideal Presentation
                 */
                case AbstractModeration.MODERATION_IDEAL_PRESENTATION:
                    moderation = new IdealPresentationModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setPresentation(dataModeration.presentation);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Nickname
                 */
                case AbstractModeration.MODERATION_NICKNAME:
                    moderation = new NicknameModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setNickname(dataModeration.nickname);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Profile photo
                 */
                case AbstractModeration.MODERATION_PROFILE_PHOTO:
                    const profilePhoto = new Photo().deserialize(dataModeration.photo);
                    moderation = new ProfilePhotoModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setPhoto(profilePhoto);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Album photo
                 */
                case AbstractModeration.MODERATION_ALBUM_PHOTO:
                    const albumPhoto = new Photo().deserialize(dataModeration.photo);
                    moderation = new AlbumPhotoModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setPhoto(albumPhoto);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Cover photo
                 */
                case AbstractModeration.MODERATION_COVER_PHOTO:
                    const coverPhoto = new Photo().deserialize(dataModeration.photo);
                    moderation = new CoverPhotoModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setPhoto(coverPhoto);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                /**
                 * Report
                 */
                case AbstractModeration.MODERATION_REPORT:
                    moderation = new ReportModeration();
                    moderation.setId(dataModeration.id);
                    moderation.setUser(user);
                    moderation.setFromUser((new User().deserialize(dataModeration.fromUser)));
                    moderation.setReasonId(dataModeration.reasonId);
                    moderation.setMessage(dataModeration.message);
                    moderation.setCreatedAt(new Date(dataModeration.createdAt));
                    this.addModeration(moderation);
                    break;
                default:
                    throw new Error('Unknown moderation type: ' + dataModeration.type);
            }
        }
    }
    addModeration(moderation) {
        this.moderations.push(moderation);
    }
    getModerations() {
        return this.moderations;
    }
    getRestrictionsBySegment(segmentId) {
        return ModerationService.restrictions[segmentId];
    }
    removeModeration(moderation) {
        const i = this.moderations.findIndex((m) => m.getId() === moderation.getId());
        if (i >= 0) {
            this.moderations.splice(i, 1);
        }
    }
    accept(moderation) {
        return this.chatService.updateModeration(moderation, 'accepted');
    }
    reject(moderation, action, reasons) {
        return this.chatService.updateModeration(moderation, action, reasons);
    }
    updateProfileGender(moderation, gender, searchedGender) {
        return this.chatService.updateProfileGender(moderation.getUser().getUid(), gender, searchedGender);
    }
    updatePhotoRating(moderation, rating) {
        return this.chatService.updatePhotoRating(moderation.getUser().getUid(), moderation.getUser().getPhotoId(), rating);
    }
    getAutologinUrl(moderation) {
        return this.chatService.getAutologinUrl(moderation);
    }
    ghost(moderation, reason, message) {
        this.chatService.ghostUser(moderation.getUser(), reason, message).subscribe((success) => {
            this.updateModerationUser(moderation.getUser());
        });
    }
    unghost(moderation) {
        this.chatService.reactiveUser(moderation.getUser(), 'ghosted').subscribe((success) => {
            this.updateModerationUser(moderation.getUser());
        });
    }
    /**
     * Copy the user to others moderations of the same user
     * @param user
     * @private
     */
    updateModerationUser(user) {
        for (const moderation of this.moderations) {
            if (moderation.getUser().getUid() === user.getUid()) {
                moderation.setUser((new User()).deserialize(user));
            }
        }
    }
    getBlockReasonId(value) {
        for (const i in ModerationService.blockReasons) {
            if (ModerationService.blockReasons[i] && ModerationService.blockReasons[i].value === value) {
                return ModerationService.blockReasons[i].id;
            }
        }
        throw new Error('The reject reason ' + value + ' does not exist in block reasons');
    }
    /**
     * Load photo
     * @param id
     */
    loadPhoto(id) {
        return new Observable((observer) => {
            this.chatService.loadPhoto(id, true).subscribe((response) => {
                if (response.success) {
                    observer.next(new Photo().deserialize(response.data));
                }
                else {
                    observer.error(response.error);
                }
            });
        });
    }
    /**
     * Save photo
     * @param id
     * @param base64
     */
    savePhoto(id, base64) {
        return this.chatService.savePhoto(id, base64);
    }
    /**
     * Reset
     */
    reset() {
        this.moderations = [];
    }
}
ModerationService.restrictions = {
    // Love
    1: {
        bigplan: false,
        sex: false,
        tits: false,
        nudity: false,
        sex_intention: false
    },
    // Casual
    2: {
        bigplan: false,
        sex: false,
        tits: false,
        nudity: true,
        sex_intention: true
    },
    // Sexy
    3: {
        bigplan: false,
        sex: true,
        tits: true,
        nudity: true,
        sex_intention: true
    },
};
ModerationService.blockReasons = [
    { id: 1, value: 'insult' },
    { id: 2, value: 'racist' },
    { id: 3, value: 'homophobic' },
    { id: 4, value: 'antisemite' },
    { id: 5, value: 'inappropriate' },
    { id: 7, value: 'grazer' },
    { id: 6, value: 'other' },
    { id: 8, value: 'underage' }
];
ModerationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModerationService_Factory() { return new ModerationService(); }, token: ModerationService, providedIn: "root" });
