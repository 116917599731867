import {Component} from '@angular/core';

@Component({
    selector: 'app-user-disconnection',
    templateUrl: './user-disconnection.component.html',
    styleUrls: ['./user-disconnection.component.css']
})

export class UserDisconnectionComponent {
}
