<div class="container-fluid main-section">
    <div class="row">
        <div class="col-12 chatroom">
            <div class="row" style="padding:0 5px;margin-top:10px;">
                <div class="col-12 p-0">
                    <div *ngIf="getModerations().length === 0" class="nomoderation">
                        <span>{{ 'moderation.nothing_to_moderate' | translate }}</span>
                    </div>
                    <app-moderation-item
                            *ngFor="let moderation of getModerations()" [moderation]="moderation"
                            (deleted)="removeModeration(moderation)" #moderationItemComponent></app-moderation-item>
                </div>
            </div>
        </div>
    </div>
</div>