import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    private buildVersion: Date = null;

    constructor(private http: HttpClient) {
        const build = require('../../../build.json');
        this.buildVersion = build.date;
    }

    getBuildDate(): Date {
        return this.buildVersion;
    }
}
