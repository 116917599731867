<ul class="connectes" (scroll)="onScrollRooms()">
    <li *ngFor="let room of rooms | sortBy: 'time':'asc' let i = index;"
        (click)="selectRoom(room)"
        [class.active]="isSelectedRoom(room)"
        class="position-relative real-users-container">

        <div class="utilisateur real-users d-flex align-items-center row no-gutters">
            <i class="fas fa-arrows-alt-h"></i>
            <div class="col h-100 d-flex align-items-center">
                <div class="users-img users-img-2">
                    <img [src]="room.user1 | userImage: '64x64'">
                    <div class="users-loading" *ngIf="!room.user1.loaded">
                        <i class="fa fa-4x fa-spin fa-spinner"></i>
                    </div>
                    <span [ngClass]="room.user1.isConnected() ? 'fa-stack bg-success': 'fa-stack bg-secondary'" class="fa-stack"></span>
                </div>
                <div class="users-detail text-truncate">
                    <p class="ml-2">{{room.user1.username| titlecase}}<i [ngClass]="room.user1.getGenderClass()" class="pl-2"></i></p>
                </div>
            </div>
            <div class="col h-100 d-flex align-items-center">
                <div class="users-img users-img-2">
                    <img [src]="room.user2 | userImage: '64x64'">
                    <div class="users-loading" *ngIf="!room.user2.loaded">
                        <i class="fa fa-4x fa-spin fa-spinner"></i>
                    </div>
                    <span [ngClass]="room.user2.isConnected() ? 'fa-stack bg-success': 'fa-stack bg-secondary'" class="fa-stack"></span>
                </div>
                <div class="users-detail text-truncate">
                    <p class="ml-2">{{room.user2.username| titlecase}}<i [ngClass]="room.user2.getGenderClass()" class="pl-2"></i></p>
                </div>
            </div>
            <div class="align-items-center d-flex h-100">
                <span [ngClass]="room.user2.getProduct().getSegment().getName().toLowerCase()" class="segment-name">
                    <span class="ml-0 mr-2 text-uppercase">{{ room.user2.getProduct().getSegment().getName() }}</span>
                </span>
            </div>
        </div>
    </li>

    <li class="text-center no-hover-bg" *ngIf="showNewRoomLoaded()">{{ writeNewRoomNb() }}</li>

    <li class="text-center" *ngIf="canInfiniteScroll() && !showNewRoomLoaded()">
        <i *ngIf="isLoadingRooms()" class="fa fa-2x fa-spin fa-spinner"></i>
        <span *ngIf="!isLoadingRooms() && hasMaxRooms() && canReceiveNotifications()">{{ 'chat.room_list.maximum' | translate }}</span>
        <span *ngIf="!canReceiveNotifications()">{{ 'chat.room_list.inactive' | translate }}</span>
        <span *ngIf="!isLoadingRooms() && rooms.length > 0 && !hasMaxRooms() && canReceiveNotifications()"
              (click)="moreConversations()"
              class="cursor-pointer">{{ 'chat.room_list.other' | translate }}</span>
    </li>
</ul>
