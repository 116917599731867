import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ElapsedTimeService} from '../../../../services/elapsed-time/elapsed-time.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-animation-elapsed-time',
    templateUrl: './elapsed-time.component.html',
    styleUrls: ['./elapsed-time.component.css']
})

export class ElapsedTimeComponent implements OnInit, OnDestroy {

    @Input()
    messageDate: string;

    private elapsedDateTime;
    private differenceTime;

    constructor(
        private elapsedTimeService: ElapsedTimeService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.elapsedDateTime = this.messageDate;

        this.refresh();

        this.elapsedTimeService.addTimer(this);
    }

    ngOnDestroy(): void {
        this.elapsedTimeService.removeTimer(this);
    }

    refresh() {
        this.differenceTime = Math.round(((+new Date().getTime()) - this.elapsedDateTime.getTime()) / 1000);
    }

    getTimeMessage() {
        if (this.differenceTime < 60) {
            return this.translateService.instant('elapsed_time.few');
            // return 'Il y a quelques instants';
        }

        const mins = Math.floor(this.differenceTime / 60);

        if (mins < 60) {
            return this.translateService.instant('elapsed_time.minute', {mins, count: mins});
            // return 'Il y a ' + mins + ' minute' + (mins > 1 ? 's' : '');
        }

        const hours = Math.floor(mins / 60);

        if (hours < 24) {
            return this.translateService.instant('elapsed_time.hour', {hours, count: hours});
            // return 'Il y a ' + hours + ' heure' + (hours > 1 ? 's' : '');
        }

        const days = Math.floor(hours / 24);

        return this.translateService.instant('elapsed_time.day', {days, count: days});
        // return 'Il y a ' + days + ' jour' + (days > 1 ? 's' : '');
    }
}
