/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emoji-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./emoji-list.component";
import * as i4 from "../../../services/chat/chat.service";
var styles_EmojiListComponent = [i0.styles];
var RenderType_EmojiListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmojiListComponent, data: {} });
export { RenderType_EmojiListComponent as RenderType_EmojiListComponent };
function View_EmojiListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.switchEmojiCategory(_v.context.$implicit.key);
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa fa-", _v.context.$implicit.value.class, ""); var currVal_1 = _ck(_v, 5, 0, (_v.context.$implicit.key == _co.emojiCategory)); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_EmojiListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "removeButtonStyle"], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.pushSmiley(_v.context.$implicit);
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.str, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getEmojiByBrowserSupport(_v.context.$implicit); _ck(_v, 2, 0, currVal_1); }); }
export function View_EmojiListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "liste-smileys-category"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EmojiListComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "liste-smileys"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmojiListComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.emojiList, _co.keepOriginalOrder)); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.getEmojis(); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getTitle(); _ck(_v, 5, 0, currVal_1); }); }
export function View_EmojiListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-emoji-list", [], null, null, null, View_EmojiListComponent_0, RenderType_EmojiListComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmojiListComponent, [i4.ChatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmojiListComponentNgFactory = i1.ɵccf("app-chat-emoji-list", i3.EmojiListComponent, View_EmojiListComponent_Host_0, { messageForm: "messageForm" }, {}, []);
export { EmojiListComponentNgFactory as EmojiListComponentNgFactory };
