/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./star-rating.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./star-rating.component";
var styles_StarRatingComponent = [i0.styles];
var RenderType_StarRatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StarRatingComponent, data: {} });
export { RenderType_StarRatingComponent as RenderType_StarRatingComponent };
function View_StarRatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, [[null, "mouseover"], [null, "mouseout"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.setOver(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseout" === en)) {
        var pd_1 = (_co.setOut() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setRating(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { checked: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-star"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.getChecked(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StarRatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "star-rating"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getRatings(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StarRatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-star-rating", [], null, null, null, View_StarRatingComponent_0, RenderType_StarRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.StarRatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StarRatingComponentNgFactory = i1.ɵccf("app-star-rating", i3.StarRatingComponent, View_StarRatingComponent_Host_0, { rating: "rating", count: "count" }, { ratingChanged: "ratingChanged" }, []);
export { StarRatingComponentNgFactory as StarRatingComponentNgFactory };
