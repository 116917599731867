/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./elapsed-time.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./elapsed-time.component";
import * as i3 from "../../../../services/elapsed-time/elapsed-time.service";
import * as i4 from "@ngx-translate/core";
var styles_ElapsedTimeComponent = [i0.styles];
var RenderType_ElapsedTimeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ElapsedTimeComponent, data: {} });
export { RenderType_ElapsedTimeComponent as RenderType_ElapsedTimeComponent };
export function View_ElapsedTimeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTimeMessage(); _ck(_v, 0, 0, currVal_0); }); }
export function View_ElapsedTimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-animation-elapsed-time", [], null, null, null, View_ElapsedTimeComponent_0, RenderType_ElapsedTimeComponent)), i1.ɵdid(1, 245760, null, 0, i2.ElapsedTimeComponent, [i3.ElapsedTimeService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElapsedTimeComponentNgFactory = i1.ɵccf("app-animation-elapsed-time", i2.ElapsedTimeComponent, View_ElapsedTimeComponent_Host_0, { messageDate: "messageDate" }, {}, []);
export { ElapsedTimeComponentNgFactory as ElapsedTimeComponentNgFactory };
