<span class="badge badge-light">{{ 'moderation.nickname' | translate }}</span>
<button class="btn btn-primary text-white undo" (click)="undo()" *ngIf="canUndo()"><i class="fas fa-undo"></i></button>
<div class="moderation-block">
    <span *ngIf="!editMode" (click)="enterToEditMode()">
        {{ nickname }}
    </span>
    <div *ngIf="editMode">
        <label>{{ 'moderation.nickname_edit' | translate }}</label>
        <input type="text" class="form-control w-100 texteditor" #text (blur)="exitFromEditMode()"
               [(ngModel)]="nickname">
    </div>
</div>
