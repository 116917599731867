import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-display-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
    @Input() currentUser: any;
    @Input() type: any;
}
