import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../models/user/user';
import {AgePipe} from './age.pipe';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe, TitleCasePipe} from '@angular/common';

@Pipe({
    name: 'profile'
})
export class ProfilePipe implements PipeTransform {

    constructor(
        private translator: TranslateService,
        private agePipe: AgePipe,
        private datePipe: DatePipe
    ) {
    }

    transform(value: any, ...args: any[]): any {

        if (value instanceof User) {
            const user: User = value;

            const infos = [];
            if (args.length) {
                const params = args[0];
                for (const arg of params) {
                    switch (arg) {
                        case 'city':
                            if (user.getCity()) {
                                const titleCasePipe = new TitleCasePipe();
                                infos.push(titleCasePipe.transform(user.getCity()));
                            }
                            break;
                        case 'country':
                            if (user.getCountry()) {
                                infos.push(user.getCountry());
                            }
                            break;
                        case 'age':
                            if (user.getBirthdate()) {
                                let age = this.agePipe.transform(user.getBirthdate());
                                age += ' ' + this.translator.instant('years_old');
                                infos.push(age);
                            }
                            break;
                        case 'ip':
                            if (user.getIp()) {
                                infos.push(user.getIp());
                            }
                            break;
                        case 'firstname':
                            if (user.getFirstname()) {
                                infos.push(user.getFirstname());
                            }
                            break;
                        case 'username':
                            if (user.getUsername()) {
                                infos.push(user.getUsername());
                            }
                            break;
                        case 'birthdate':
                            if (user.getBirthdate()) {
                                const date = this.datePipe.transform(user.getBirthdate(), 'dd/MM/yyyy');
                                infos.push(date);
                            }
                            break;
                        case 'registerdate':
                            if (user.getRegisterDate()) {
                                const date = this.datePipe.transform(user.getRegisterDate(), 'dd/MM/yyyy');
                                infos.push(date);
                            }
                            break;
                        case 'gender':
                            if (user.getGender()) {
                                if (user.getGender() === User.GENDER_MAN) {
                                    infos.push(this.translator.instant('male'));
                                } else {
                                    infos.push(this.translator.instant('female'));
                                }
                            }
                            break;
                        case 'subscriptionName':
                            if (user.getSubscription()) {
                                infos.push(this.translator.instant(user.getSubscription().name));
                            } else {
                                infos.push(this.translator.instant('unsubscribed'));
                            }
                            break;
                    }
                }

                return infos.join(', ');
            }
        }

        return '';
    }

}
