/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report.component.css.shim.ngstyle";
import * as i1 from "../moderation-type.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./report.component";
var styles_ReportComponent = [i0.styles, i1.styles];
var RenderType_ReportComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ReportComponent, data: {} });
export { RenderType_ReportComponent as RenderType_ReportComponent };
export function View_ReportComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "span", [["class", "badge badge-warning"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(3, 0, null, null, 11, "div", [["class", "moderation-block warning"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 5, "div", [["class", "report-info"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle text-warning"]], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, [" ", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, ["", ""])), (_l()(), i2.ɵeld(10, 0, null, null, 2, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i2.ɵted(11, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i2.ɵted(14, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("moderation.report")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 6, 0, i2.ɵnov(_v, 7).transform("moderation.signaled_by")); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.moderation.getFromUser().getUsername(); _ck(_v, 9, 0, currVal_2); var currVal_3 = i2.ɵunv(_v, 11, 0, i2.ɵnov(_v, 12).transform(_co.getReasonText())); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.moderation.getMessage(); _ck(_v, 14, 0, currVal_4); }); }
export function View_ReportComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ReportComponent_0, RenderType_ReportComponent)), i2.ɵdid(1, 49152, null, 0, i4.ReportComponent, [], null, null)], null, null); }
var ReportComponentNgFactory = i2.ɵccf("ng-component", i4.ReportComponent, View_ReportComponent_Host_0, {}, {}, []);
export { ReportComponentNgFactory as ReportComponentNgFactory };
