<div class="container-fluid main-section">
    <div class="btn-toolbar float-right">
        <div class="btn-group">
            <a [routerLink]="['/operator']" class="btn btn-primary text-white"><i class="fas fa-arrow-left"></i> {{ 'form.back' | translate }}</a>
        </div>
    </div>
    <h1 class="mt-4 mb-1">{{ 'form.add_user' | translate }}</h1>
    <app-loader [loading]="loading"></app-loader>
    <div class="row">
        <div class="col-12">
            <!-- Onglets -->
            <ul class="nav nav-tabs" id="onglets" role="tablist">
                <li class="nav-item">
                    <span class="nav-link active"><i class="fas fa-user"></i> {{ 'form.general_informations' | translate }}</span>
                </li>
            </ul>
            <!-- Onglets -->

            <app-operator-account [operator]="operator" [managers]="managers"
                                  (operatorChange)="save($event)"></app-operator-account>
        </div>
    </div>
</div>
