<div class="form-check-inline" #container>
    <button type="button" class="btn btn-dropdown-select collapsed pr-5" (click)="opened=!opened"
            [attr.data-target]="'#dropdownselect-' + uniqueId" aria-expanded="false">
        <ng-content></ng-content>
    </button>
    <div [id]="'dropdownselect-' + uniqueId" class="filter-panel mb-4 collapse w-100 dropdown-select"
         [ngClass]="{show:opened}">
        <div class="form-check" *ngFor="let option of options; let index = index">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [id]="'dropdownselect-' + uniqueId + '-' + index"
                       [(ngModel)]="option.checked" (change)="check()">
                <label class="custom-control-label"
                       [attr.for]="'dropdownselect-' + uniqueId + '-' + index">{{ option.label }}</label>
            </div>
        </div>
    </div>
</div>
