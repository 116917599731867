/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "content-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fas fa-3x"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-3x"; var currVal_1 = _co.data.icon.class; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["class", "fas"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "fas"; var currVal_1 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [], [[8, "className", 0], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dialogRef.close(i1.ɵnov(_v, 1).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_3); var currVal_4 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.className, ""); var currVal_1 = (i1.ɵnov(_v, 1).ariaLabel || null); var currVal_2 = i1.ɵnov(_v, 1).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_5); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h1", [["class", "title-left mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "fa fa-times-circle modal-close"], ["mat-dialog-close", "close"]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "content-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "mat-dialog-actions", [["class", "action-button mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_4 = "close"; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.data.icon; _ck(_v, 9, 0, currVal_5); var currVal_7 = _co.data.buttons; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 5).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 5).type; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_6 = _co.data.message; _ck(_v, 11, 0, currVal_6); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalComponent, [[2, i3.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i4.ModalComponent, View_ModalComponent_Host_0, { dataSource: "dataSource" }, {}, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
