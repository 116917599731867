/**
 * Subscription
 */
export class Subscription {
    id: number = null;
    name: string = null;
    dateBegin: Date = null;
    dateEnd: Date = null;
    subscribedDateEnd: Date = null;

    deserialize(data: any): this {
        Object.assign(this, data);

        if (data && data.dateBegin) {
            this.dateBegin = new Date(data.dateBegin);
        }

        if (data && data.dateEnd) {
            this.dateEnd = new Date(data.dateEnd);
        }

        if (data && data.subscribedDateEnd) {
            this.subscribedDateEnd = new Date(data.subscribedDateEnd);
        }

        return this;
    }

    isSubscribed(): boolean {
        return this.subscribedDateEnd && this.subscribedDateEnd >= new Date();
    }
}
