import {Component, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {ModerationService} from '../../../services/moderation/moderation.service';
import {AbstractModeration} from '../../../models/moderation/abstract-moderation';
import {ModerationItemComponent} from './moderation-item/moderation-item.component';

@Component({
    selector: 'app-moderation',
    templateUrl: './moderation.component.html',
    styleUrls: ['./moderation.component.css']
})
export class ModerationComponent implements OnInit, OnDestroy {

    @ViewChildren('moderationItemComponent') moderationItemComponents: ModerationItemComponent[];

    private timer = null;

    constructor(
        private moderationService: ModerationService
    ) {
    }

    ngOnInit(): void {
        this.timer = setInterval(() => {
            for (const moderationItemComponent of this.moderationItemComponents) {
                moderationItemComponent.updateElapsedTime();
            }
        }, 1000);
    }

    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    getModerations(): AbstractModeration[] {
        return this.moderationService.getModerations();
    }

    removeModeration(moderation: AbstractModeration) {
        this.moderationService.removeModeration(moderation);
    }
}
