import {AbstractModeration} from './abstract-moderation';
import {Photo} from '../photo/photo';

export class ProfilePhotoModeration extends AbstractModeration {
    /**
     * Photo
     * @private
     */
    private photo: Photo = null;

    constructor() {
        super(AbstractModeration.MODERATION_PROFILE_PHOTO);
    }

    setPhoto(photo: Photo) {
        this.photo = photo;
    }

    getPhoto(): Photo {
        return this.photo;
    }

    getData(): any {
        if (this.photo) {
            return {
                rating: this.photo.getRating(),
                base64: this.photo.getBase64()
            };
        }

        return null;
    }

    getReasons(): string[] {
        return [
            'no_person',
            'underage',
            'other_person',
            'text',
            'private_contact',
            'bad_quality',
            'big_plan',
            'bad_framing',
            'impersonation',
            'pet'
        ];
    }
}
