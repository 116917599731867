/**
 * API Response
 */
export class ApiResponse {
    success: boolean = null;
    error: any = null;
    data: any;

    deserialize(data: any): this {
        Object.assign(this, data);

        return this;
    }
}
