import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {UserManagementService} from '../../services/user/user-management.service';
import {ChatService} from '../../services/chat/chat.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {VersionService} from '../../services/version/version.service';
import {StorageService} from '../../services/storage/storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    /**
     * Login form
     */
    loginForm: FormGroup;

    /**
     * Display spinner
     */
    loading = false;

    /**
     * Check if the form is submited
     */
    submitted = false;

    /**
     * Return url after the login
     */
    returnUrl: string;

    /**
     * @param formBuilder FormBuilder
     * @param route to get the current route
     * @param router of angular router
     * @param userManagementService user management service to get connected user
     * @param chatService ChatService
     * @param toastrService ToastrService
     * @param translateService
     * @param versionService VersionService
     * @param storageService StorageService
     */
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userManagementService: UserManagementService,
        private chatService: ChatService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private versionService: VersionService,
        private storageService: StorageService
    ) {
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    ngOnInit() {
        if (this.userManagementService.getCurrentUser()) {
            this.router.navigate(['/']).then();
            return;
        }

        // Create the login form
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: []
        });

        const username = this.storageService.get('username');
        const password = this.storageService.get('password');

        if (username && password) {
            if (this.storageService.get('connected')) {
                // Display the spinner
                this.loading = true;

                // Make the login and connected the user
                // Connect to the chat
                this.chatService.login(username, password).subscribe(() => {
                    // Route to the right url
                    this.router.navigate([this.returnUrl]);
                    this.loading = false;
                }, error => {
                    this.storageService.delete('connected');
                    this.toastrService.error(error);
                    this.loading = false;
                });
            } else {
                this.loginForm.patchValue({
                    username,
                    password,
                    rememberMe: true
                });
            }
        }

        // We set the right route depending on the queryParams passed by auth.guard
        if (this.route.snapshot.queryParams.returnUrl) {
            this.returnUrl = this.route.snapshot.queryParams.returnUrl;
        } else {
            // get return url from route parameters or default to '/'
            this.returnUrl = '/';
        }
    }

    onSubmit() {
        // Form is submited
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        // Remember me
        if (this.loginForm.get('rememberMe').value) {
            this.storageService.set('username', this.loginForm.get('username').value);
            this.storageService.set('password', this.loginForm.get('password').value);
        } else {
            this.storageService.delete('username');
            this.storageService.delete('password');
        }

        // Display the spinner
        this.loading = true;

        // Make the login and connected the user
        // Connect to the chat
        this.chatService.login(this.f.username.value, this.f.password.value).subscribe(() => {
            // Route to the right url
            this.storageService.set('connected', true);
            this.router.navigate([this.returnUrl]);
            this.loading = false;
        }, error => {
            this.toastrService.error(error);
            this.loading = false;
        });
    }

    isSocketConnected() {
        return this.chatService.isSocketConnected();
    }

    getChatVersion() {
        return ChatService.CHAT_VERSION.version;
    }

    getVersionDate() {
        return this.versionService.getBuildDate();
    }

    useLanguage(language: string) {
        this.translateService.use(language);
    }

    getTranslateService(): TranslateService {
        return this.translateService;
    }
}
