<span class="badge badge-light">{{ 'moderation.presentation' | translate }}</span>
<button class="btn btn-primary text-white undo" (click)="undo()" *ngIf="canUndo()"><i class="fas fa-undo"></i></button>
<div class="moderation-block">
    <span *ngIf="!editMode" (click)="enterToEditMode()">
        {{ presentation }}
    </span>
    <div *ngIf="editMode">
        <label>{{ 'moderation.presentation_edit' | translate }}</label>
        <textarea class="form-control w-100 texteditor" #textarea (blur)="exitFromEditMode()"
                  [(ngModel)]="presentation"></textarea>
    </div>
</div>
