import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './services/authguard/auth.guard';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/panel/home/home.component';
import {AnimationComponent} from './components/panel/animation/animation.component';
import {ModerationComponent} from './components/panel/moderation/moderation.component';
import {ForgottenPasswordComponent} from './components/login/forgotten-password/forgotten-password.component';
import {OperatorListComponent} from './components/panel/admin/operator/operator-list.component';
import {OperatorEditComponent} from './components/panel/admin/operator/operator-edit/operator-edit.component';
import {OperatorAddComponent} from './components/panel/admin/operator/operator-add/operator-add.component';
import {DispatchBoardComponent} from './components/panel/dispatch-board/dispatch-board.component';

const routes: Routes = [
    {
        path: '', component: HomeComponent, canActivate: [AuthGuard]
    },
    {
        path: 'animation', component: AnimationComponent, canActivate: [AuthGuard]
    },
    {
        path: 'moderation', component: ModerationComponent, canActivate: [AuthGuard]
    },
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'forgotten-password', component: ForgottenPasswordComponent
    },
    {
        path: 'operator', component: OperatorListComponent, canActivate: [AuthGuard]
    },
    {
        path: 'operator/add', component: OperatorAddComponent, canActivate: [AuthGuard]
    },
    {
        path: 'operator/:id', component: OperatorEditComponent, canActivate: [AuthGuard]
    },
    {
        path: 'dispatch-board', component: DispatchBoardComponent, canActivate: [AuthGuard]
    },
    // otherwise redirect to home
    {
        path: '**', redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
