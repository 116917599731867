import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Socket} from 'ngx-socket-io';
import {ModalService} from '../modal/modal.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    calendarsObservable = [];
    calendars: any = [];

    /**
     *
     * @param socket Socket
     * @param modalService ModalService
     * @param translate
     */
    public constructor(
        private socket: Socket,
        private modalService: ModalService,
        private translate: TranslateService
    ) {
        // Init first data
        this.initSocket();
    }

    initSocket() {
        this.socket.on('fake_profile_calendar', (datas) => this.onFakeProfileCalendar(datas));
    }

    /**
     * Socket Event Private Message
     */
    private onFakeProfileCalendar(datas) {
        this.calendars = [];

        for (const calendar of datas.calendarList) {
            this.calendars.push(calendar);
        }

        this.switchToUser(datas.zmUserId, datas.fakeProfileId);
    }

    /**
     * Swith user
     *
     * @param zmUserId inside the discussion
     * @param fakeProfilId inside the discussion
     */
    switchToUser(zmUserId, fakeProfilId) {
        this.calendarsObservable.splice(0);
        this.permutCalendar(fakeProfilId, zmUserId);
    }

    /**
     * Get the calendar retated to the right coupe fakeProfil / zmUserProfil
     * @param fakeProfilId linked to the calendar
     * @param zmUserProfilId linked to the calendar
     */
    permutCalendar(fakeProfilId, zmUserProfilId) {
        this.calendars.forEach((item) => {
            this.calendarsObservable.push(item);
        });
    }

    /**
     * Update calendar event
     *
     * @param calendar values
     * @param index of the choosen element
     */
    updateCalendarEvent(calendar, index) {
        // update calendar event :
        if (typeof this.calendarsObservable[index] !== 'undefined') {
            const updatedCalendar = this.calendarsObservable[index];

            updatedCalendar.alert = calendar.alert === true ? 1 : 0;
            updatedCalendar.date = calendar.date;
            updatedCalendar.repeat = calendar.repeat;
            updatedCalendar.title = calendar.title;

            this.socket.emit('update_calendar_event', updatedCalendar);

            this.calendarsObservable[index] = updatedCalendar;
        }
    }

    /**
     * Add calendar event
     *
     * @param calendarEvent values
     * @param fakeProfilId linked to the calendar event
     * @param zmUserProfilId linked to the calendar event
     */
    addCalendarEvent(calendarEvent, fakeProfilId, zmUserProfilId) {
        calendarEvent.fakeProfileId = fakeProfilId;
        calendarEvent.zmUserId = zmUserProfilId;

        this.socket.emit('add_calendar_event', calendarEvent);

        this.calendarsObservable.push(calendarEvent);
    }

    /**
     * Get Calendar Event
     *
     * @param fakeProfilId linked to the calendar event
     * @param zmUserProfilId linked to the calendar event
     */
    getCalendarEvent(fakeProfilId, zmUserProfilId) {
        const calendarObservable = new Observable(observer => {
            observer.next(this.calendarsObservable);
        });

        return calendarObservable;
    }

    /**
     * Delete calendar event
     *
     * @param index of the element
     * @param eventId of the calendar
     */
    deleteCalendarEvent(index, eventId) {
        if (this.modalService.message(this.translate.instant('confirm_delete_event'))) {

            this.socket.emit('remove_calendar_event', eventId);

            this.calendarsObservable.splice(index, 1);
        }
    }

    /**
     * @param zmUserId any
     * @param fakeProfileId any
     */
    onChange(zmUserId, fakeProfileId) {
        this.socket.emit('retrieve_fake_profile_events', {
            fakeProfileId,
            userId: zmUserId
        });
    }
}
