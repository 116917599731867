<div class="tab-content">
    <app-loader [loading]="loading"></app-loader>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row" formArrayName="productGroups">
            <div class="col-lg-12">
                <label class="font-weight-bold">{{ 'form.products' | translate }}</label>
                <div *ngFor="let productGroup of productGroups; let i = index" class="form-check">
                    <div class="custom-control custom-checkbox">
                        <input [formControlName]="i" type="checkbox" class="custom-control-input"
                               [id]="'product-group-' + productGroup.getId()">
                        <label class="custom-control-label"
                               [attr.for]="'product-group-' + productGroup.getId()">{{ productGroup.getName() }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-right mt-2">
            <button class="btn btn-primary p-3 text-white" type="submit">{{ 'form.save' | translate }}</button>
        </div>
    </form>
</div>