import * as i0 from "@angular/core";
export class UserAlertService {
    constructor() {
        this.alerts = [];
    }
    /* Unused for now, see chat-server project
    constructor(private notificationService: NotificationService, private socket: Socket) {
        this.initSocket();
    }

    private initSocket() {
        this.socket.on('alert_notification', alerts => this.onAlertNotification(alerts));
    }

    private onAlertNotification(dataAlerts) {
        for (const dataAlert of dataAlerts) {
            const alert = new Alert().deserialize(dataAlert);

            if (!this.alerts.find(a => a.id === alert.id)) {
                this.alerts.push(alert);
                if (!alert.isManaged()) {
                    this.notificationService.add(1, 'alert');
                }
            }
        }
    }

    setAlertToManaged(alert: Alert) {
        if (!alert.isManaged()) {
            alert.setManaged(true);

            this.socket.emit('manage_alert', {
                eventId: alert.eventId,
                alertId: alert.id
            });
        }
    }
    */
    /**
     * Get observable message
     */
    getUserAlerts() {
        return this.alerts;
    }
}
UserAlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAlertService_Factory() { return new UserAlertService(); }, token: UserAlertService, providedIn: "root" });
