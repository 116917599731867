import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ModerationService} from '../../../../../services/moderation/moderation.service';

@Component({
    selector: 'app-ghost',
    templateUrl: './ghost.component.html',
    styleUrls: ['./ghost.component.css']
})
export class GhostComponent implements OnInit {

    /**
     * Ghost reason
     */
    reason: string = null;

    /**
     * Ghost message
     */
    message: '';

    constructor(
        private dialogRef: MatDialogRef<GhostComponent>
    ) {
    }

    ngOnInit() {
    }

    canGhost(): boolean {
        return !!(this.reason && this.message);
    }

    ghost() {
        this.dialogRef.close({
            reason: this.reason,
            message: this.message
        });
    }

    getBlockReasons(): object[] {
        return ModerationService.getBlockReasons();
    }
}
