import * as i0 from "@angular/core";
export class StorageService {
    constructor() {
    }
    /**
     * Set a cookie
     * @param name string
     * @param value string
     */
    set(name, value) {
        if (typeof (Storage) !== 'undefined') {
            window.localStorage.setItem(name, value);
        }
        else {
            const d = new Date();
            d.setTime(d.getTime() + 3600 * 24 * 1000);
            document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';path=/';
        }
    }
    /**
     * Get a cookie
     * @param name string
     */
    get(name) {
        if (typeof (Storage) !== 'undefined') {
            return window.localStorage.getItem(name);
        }
        else {
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                const nameValue = cookie.trim().split('=');
                if (name === nameValue[0]) {
                    if (nameValue.length > 1) {
                        return nameValue[1];
                    }
                    else {
                        return null;
                    }
                }
            }
            return null;
        }
    }
    /**
     * Delete a cookie
     * @param name string
     */
    delete(name) {
        if (typeof (Storage) !== 'undefined') {
            localStorage.removeItem(name);
        }
        else {
            const d = new Date();
            d.setTime(0);
            document.cookie = name + '=;expires=' + d.toUTCString() + ';path=/';
        }
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
