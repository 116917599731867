<span class="badge badge-light">{{ 'moderation.album_photo' | translate }}</span>
<div class="moderation-block">
    <div class="photo-mod">
        <img [src]="getPhotoSrc()" alt="">
        <a class="btn btn-primary modif-photo"
           (click)="cropPhoto()">{{ 'moderation.crop' | translate }}</a>
    </div>
    <div class="photo-check">
        <a [href]="getGoogleImageUrl()" target="_blank" class="text-white">
            {{ 'moderation.check_google_image_link' | translate }}
        </a>
    </div>
    <div class="photo-rating">
        {{ 'moderation.photo_rating' | translate }} :
        <app-star-rating [count]="5" [rating]="getRating()" (ratingChanged)="setRating($event)"></app-star-rating>
    </div>
</div>
